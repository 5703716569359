import React from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import { navColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: navColor,

    display: 'flex',
    alignItems: 'center',

    padding: theme.spacing(),
  },
}))

const Subheading = ({ children }) => {
  const classes = useStyles()
  return (
    <Hidden xsDown>
      <Grid className={classes.container} container>
        {children}
      </Grid>
    </Hidden>
  )
}

export default Subheading
