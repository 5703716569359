import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'mobx-react'
import { SnackbarProvider } from 'notistack'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { esTheme } from './constants'

import {
  AssignTaskStore,
  CreateAgendaStore,
  CreateGroupGoalStore,
  CreateGroupStore,
  CreateMeetingStore,
  CreateStafferStore,
  CreateStudentCommentStore,
  CreateStudentNoteStore,
  CreateStudentStore,
  CreateTaskStore,
  ForgotPasswordStore,
  GroupGoalsStore,
  GroupNormsStore,
  GroupStore,
  GroupTasksStore,
  HeaderStore,
  LoginStore,
  ManageAgendaTypesStore,
  ManageStaffStore,
  ManageStudentsStore,
  ManageTeamsStore,
  MeetingAgendaStore,
  MeetingNotesStore,
  MeetingsStore,
  MeetingTasksStore,
  MyAccountStore,
  StudentCommentsStore,
  StudentGroupsStore,
  StudentNotesStore,
  TaskNotesStore,
  TaskStore
} from './stores'

const stores = {
  AssignTaskStore,
  CreateAgendaStore,
  CreateGroupGoalStore,
  CreateGroupStore,
  CreateMeetingStore,
  CreateStafferStore,
  CreateStudentCommentStore,
  CreateStudentNoteStore,
  CreateStudentStore,
  CreateTaskStore,
  ForgotPasswordStore,
  GroupGoalsStore,
  GroupNormsStore,
  GroupStore,
  GroupTasksStore,
  HeaderStore,
  LoginStore,
  ManageAgendaTypesStore,
  ManageStaffStore,
  ManageStudentsStore,
  ManageTeamsStore,
  MeetingAgendaStore,
  MeetingNotesStore,
  MeetingsStore,
  MeetingTasksStore,
  MyAccountStore,
  StudentCommentsStore,
  StudentGroupsStore,
  StudentNotesStore,
  TaskNotesStore,
  TaskStore
}

ReactDOM.render(
  <Provider {...stores}>
    <MuiThemeProvider theme={esTheme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        maxSnack={5}>
        <App />
      </SnackbarProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
