import { action, observable } from 'mobx'
import { getDataApi, postDataApi } from '../services/api'
import { navigate } from '@reach/router'
import moment from 'moment'

import {
  removeNotification,
  createNotificationObject,
  setupMenuItems,
  sortArrayByLastNameFirstName,
} from '../helpers'
import { urls } from '../constants'

class Store {
  @observable createTaskNotifications = []
  @observable dateAssigned = null
  @observable dateCreated = null
  @observable dueDate = moment()
  @observable goals = []
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable selectedGoal = ''
  @observable selectedTeamMember = ''
  @observable teamMembers = []
  @observable todo = ''

  @observable agendaItemId
  @observable meetingId
  @observable taskId = '0'
  @observable teamId

  getData(agendaItemId, meetingId, taskId, teamId) {
    this.agendaItemId = agendaItemId
    this.meetingId = meetingId
    this.taskId = taskId
    this.teamId = teamId

    if (taskId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetTaskDetailsResponse,
        url: `${urls.GET_TASK_BY_TASK_ID}${taskId}`,
      })
    }

    getDataApi({
      handleSuccess: this.handleGetGoalsResponse,
      url: `${urls.GET_GOALS_BY_TEAM_ID}${teamId}`,
    })

    getDataApi({
      handleSuccess: this.handleGetTeamResponse,
      url: `${urls.GET_TEAMS_WITH_USERS_BY_ID}${teamId}`,
    })
  }

  save = (form) => {
    this.isSavingData = true

    const postObject = {
      AgendaItemId: this.agendaItemId,
      GoalId: form.SelectedGoal,
      Id: this.taskId,
      Title: form.Title,
      DateAssigned: this.dateAssigned,
      DateCreated: this.dateCreated,
      DueDate: moment(form.DueDate).format('YYYY-MM-DD'),
      AssignedToUserId: form.AssignedTo,
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_TASK_FROM_AGENDA_ITEM,
    })
  }

  @action handleGetGoalsResponse = (data) => {
    let array = setupMenuItems({
      data,
      descriptionField: 'Title',
      valueField: 'Id',
    })

    this.goals = array
  }

  @action handleGetTeamResponse = (data) => {
    const sortedArray = sortArrayByLastNameFirstName(data.TeamMembers)

    sortedArray.forEach((item) => {
      item.DisplayName = item.LastName + ', ' + item.FirstName
    })

    let teamMembers = setupMenuItems({
      data: sortedArray,
      descriptionField: 'DisplayName',
      valueField: 'UserId',
    })

    this.teamMembers = teamMembers
    this.isLoadingData = false
  }

  @action handleGetTaskDetailsResponse = (data) => {
    this.agendaItemId = data.AgendaItemId
    this.dateAssigned = data.DateAssigned
    this.dateCreated = data.DateCreated
    this.dueDate = data.DueDate
    this.selectedGoal = data.GoalId
    this.selectedTeamMember = data.AssignedToUserId
    this.todo = data.Title
  }

  @action handleSaveResponse = (data) => {
    this.createTaskNotifications.push(
      createNotificationObject({
        key: data,
        message: 'A new task has been created.',
        variant: 'success',
      })
    )

    this.dateAssigned = null
    this.dateCreated = null
    this.dueDate = moment()
    this.selectedGoal = ''
    this.selectedTeamMember = ''
    this.todo = ''

    this.isSavingData = false
    navigate(`/meetingNotes/${this.agendaItemId}/${this.meetingId}`)
  }

  @action removeSnackbar = (keyValue) => {
    this.createTaskNotifications = removeNotification({
      array: this.createTaskNotifications,
      keyValue,
    })
  }
}

export default new Store()
