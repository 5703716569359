import React from 'react'
import { makeStyles } from '@material-ui/core'
import InternalLink from './InternalLink'
import StudentInfo from './StudentInfo'

const useStyles = makeStyles(theme => ({
  studentDetails: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      marginBottom: theme.spacing(3)
    }
  }
}))

const StudentInfoWithLink = ({
  firstName,
  fullName,
  graduationYear,
  lastName,
  linkLabel,
  to
}) => {
  const classes = useStyles()
  return (
    <div className={classes.studentDetails}>
      <StudentInfo
        firstName={firstName}
        fullName={fullName}
        graduationYear={graduationYear}
        lastName={lastName}
      />
      <InternalLink label={linkLabel} to={to} />
    </div>
  )
}

export default StudentInfoWithLink
