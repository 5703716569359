import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { ListOfStudents, Subheading } from '../StudentGroups'
import { Loading, SectionTitle } from '../common'

@inject('HeaderStore', 'StudentGroupsStore')
@observer
class StudentGroups extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Student Groups')
    this.props.StudentGroupsStore.getData(this.props.groupId)
  }

  render() {
    const {
      isDeletingData,
      isLoadingData,
      removeStudent,
      students,
      teamName
    } = this.props.StudentGroupsStore

    if (isDeletingData)
      return <Loading dataTestId='deleting-component' text='Removing Student' />
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Student Groups' />
      )

    return (
      <Fragment>
        <SectionTitle text={teamName} />
        <Subheading />
        <ListOfStudents
          data={students}
          groupId={this.props.groupId}
          removeStudent={removeStudent}
        />
      </Fragment>
    )
  }
}

export default StudentGroups
