import React from 'react'
//import { InlineTimePicker } from 'material-ui-pickers'
import { TimePicker } from '@material-ui/pickers'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  timePicker: {
    width: 250,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})

const EsTimePicker = ({ classes, label, onChange, value }) => {
  return (
    <TimePicker
      className={classes.timePicker}
      label={label}
      onChange={onChange}
      value={value}
    />
  )
}

export default withStyles(styles)(EsTimePicker)
