import React, { Fragment } from 'react'
import { Grid, Hidden, Icon, Typography, makeStyles } from '@material-ui/core'

import {
  DeleteModal,
  ListItemText,
  MenuButton,
  MenuLink,
  Popover,
} from '../common'
import { subheadingColor, userTypeId } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${subheadingColor}`,
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
    },
  },
}))

const ListItem = ({ details, deleteItem }) => {
  const classes = useStyles()
  const {
    Active,
    EmailAddress,
    Id,
    IsAdmin,
    FirstName,
    LastName,
    StudentUserId,
    UserTypeId,
  } = details

  return (
    <Grid
      data-testid='user-item'
      alignItems='center'
      className={classes.root}
      container
      spacing={2}>
      <Grid item sm={1}>
        {Active ? (
          <Fragment>
            {IsAdmin ? (
              <Popover dataTestId='active-admin' content='Active Admin'>
                <Icon
                  className={['fas fa-user-plus', classes.icon].join(' ')}
                />
              </Popover>
            ) : (
              <Popover dataTestId='active' content='Active'>
                <Icon className={['fas fa-user', classes.icon].join(' ')} />
              </Popover>
            )}
          </Fragment>
        ) : (
          <Popover dataTestId='non-active' content='Not Active'>
            <Icon className={['fas fa-user-slash', classes.icon].join(' ')} />
          </Popover>
        )}
      </Grid>
      <Grid className={classes.item} item xs={12} sm={4}>
        <Hidden smUp>
          <Typography>Name: </Typography>
        </Hidden>
        <ListItemText dataTestId='user-name'>
          {LastName}, {FirstName}
        </ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={5}>
        <Hidden smUp>
          <Typography>Email: </Typography>
        </Hidden>
        <ListItemText dataTestId='email-address'>{EmailAddress}</ListItemText>
      </Grid>
      <Grid item xs={12} sm={2}>
        <MenuButton>
          <MenuLink
            label='Edit'
            to={
              UserTypeId === userTypeId.Teacher
                ? `/createStaffer/${Id}`
                : `/createStudent/${StudentUserId}`
            }
          />
          <DeleteModal
            confirmDeleteCallback={() => deleteItem(Id ? Id : StudentUserId)}
            confirmDeleteLabel='Delete User'
            confirmDeleteMessage={`Are you sure you want to delete this user's account?`}
            itemName={`${FirstName} ${LastName}`}
            menuItemText='Delete'
            useMenuItem
          />
        </MenuButton>
      </Grid>
    </Grid>
  )
}

export default ListItem
