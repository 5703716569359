import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import { ListOfUsers, Subheading } from '../ManageUsers'
import { LinkButton, Loading } from '../common'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  }
})

@inject('HeaderStore', 'ManageStaffStore')
@observer
class ManageStaff extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Manage Staff')
    this.props.ManageStaffStore.getData()
  }

  render() {
    const { classes } = this.props
    const {
      deleteItem,
      isDeletingData,
      isLoadingData,
      users
    } = this.props.ManageStaffStore

    if (isDeletingData)
      return (
        <Loading dataTestId='delete-component' text='Deleting Staff Member' />
      )
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Staff Info' />
      )

    return (
      <Fragment>
        <div
          data-testid='create-staffer-container'
          className={classes.buttonContainer}>
          <LinkButton label='Create Staffer' to='/createStaffer/0' />
        </div>
        <Subheading />
        <ListOfUsers data={users} deleteItem={deleteItem} />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ManageStaff)
