import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Button, TextArea } from '../common'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: theme.spacing()
  }
}))

const CreateComment = ({ handleInputChange, newComment, save }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TextArea
        name='newComment'
        onChange={handleInputChange}
        placeholder='Add Comment'
        value={newComment}
      />
      <div className={classes.buttonContainer}>
        <Button label='Save Comment' onClick={save} />
      </div>
    </div>
  )
}

export default CreateComment
