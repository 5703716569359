import { action, computed, observable } from 'mobx'
import { login, postDataApi } from '../services/api'
import { urls } from '../constants'
import { navigate } from '@reach/router'

class LoginStore {
  @observable displayForgotPasswordForm = false
  @observable email = ''
  @observable errorMessage = ''
  @observable isLoggingOn = false
  @observable isSendingEmail = false
  @observable password = ''

  @observable hasBeenAuthenticated = localStorage.getItem(
    'enriching-teachers:has-been-authenticated'
  )

  @computed get userIsAuthenticated() {
    if (!this.hasBeenAuthenticated) {
      return false
    } else {
      return true
    }
  }

  handleLogin = () => {
    this.isLoggingOn = true

    const loginObject = {
      EmailAddress: this.email,
      Password: this.password,
    }

    login({
      onSuccess: this.handleLoginResponse,
      loginObject,
    })
  }

  handleForgotPassword = () => {
    this.isSendingEmail = true

    const postObject = {
      EmailAddress: this.email,
    }
    postDataApi({
      handleSuccess: this.handleForgotPasswordResponse,
      postObject,
      url: urls.USER_FORGOT_PASSWORD,
    })
  }

  @action handleForgotPasswordResponse = (data) => {
    this.errorMessage =
      'An email has been sent with instructions to reset your password.'

    this.toggleDisplayForgotPasswordForm()
    this.isSendingEmail = false
  }

  @action handleInputChange = (e) => {
    this[e.target.name] = e.target.value
  }

  @action handleLoginResponse = (data) => {
    localStorage.clear()
    this.errorMessage = ''

    const loginResponseIds = {
      valid: 1,
      lockedOut: 2,
      invalid: 3,
      badUserName: 4,
    }

    if (data === loginResponseIds.lockedOut) {
      this.errorMessage =
        'This user account has been locked out.  Please contact an admin to reinstate this account.'
    } else if (
      data === loginResponseIds.invalid ||
      data === loginResponseIds.badUserName
    ) {
      this.errorMessage = 'You have entered an invalid username or password.'
    } else {
      this.email = ''
      this.password = ''

      localStorage.setItem('enriching-teachers:has-been-authenticated', true)

      // Setting a ls variable as backup
      localStorage.setItem('IsAnyTeamLead', false)

      this.hasBeenAuthenticated = true

      navigate('/')
    }

    this.isLoggingOn = false
  }

  @action handleBadTokenLogout = () => {
    this.handleLogout()
    this.errorMessage =
      'Your logged in session appears to have timed out. Please login again to get started.'
  }

  @action handleLogout = () => {
    localStorage.clear()
    this.hasBeenAuthenticated = false
  }

  @action toggleDisplayForgotPasswordForm = () => {
    this.displayForgotPasswordForm = !this.displayForgotPasswordForm
  }
}

export default new LoginStore()
