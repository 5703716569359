import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import { secondaryColor } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: secondaryColor,
    color: '#FFF',

    maxWidth: 250,

    marginTop: theme.spacing(),

    '&:hover': {
      backgroundColor: '#6abf69',
      color: '#000',
    },
  },
}))

const SubmitButton = ({ label }) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      data-testid='submit-button'
      type='submit'>
      {label}
    </Button>
  )
}

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
}

export default SubmitButton
