import React from 'react'
import { Link } from '@reach/router'
import { Button, withStyles } from '@material-ui/core'
import { secondaryColor } from '../../constants'

const styles = () => ({
  button: {
    color: '#FFF',
    backgroundColor: secondaryColor,
    fontSize: 13,

    //maxWidth: 200,

    paddingTop: 4,
    paddingRight: 10,
    paddingBottom: 4,
    paddingLeft: 10,

    '&:hover': {
      backgroundColor: '#6abf69',
      color: '#000',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
  },
})

const LinkButton = ({
  classes,
  dataTestId,
  label,
  onClick,
  to,
  overwriteClass,
}) => {
  return (
    <Link className={classes.link} to={to}>
      <Button
        data-testid={dataTestId}
        className={[classes.button, overwriteClass].join(' ')}
        onClick={onClick}
        variant='contained'>
        {label}
      </Button>
    </Link>
  )
}

export default withStyles(styles)(LinkButton)
