import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, withStyles } from '@material-ui/core'
import { SectionContainer, SubheadingItemText, LinkButton } from '../common'
import TaskAssignmentItem from './TaskAssignmentItem'

const styles = () => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
})

@inject('TaskStore', 'HeaderStore')
@observer
class MyTaskAssignments extends Component {
  state = {
    sortByAssignedDate: true,
    sortByAssociatedGoal: false,
    sortByGroup: false,
    sortByTaskStatus: false,
    sortByTaskTitle: false,
  }

  componentDidMount() {
    this.props.TaskStore.getData()
  }

  handleSortBy = (stateValue, sortByValue) => {
    this.setState(
      {
        sortByAssignedDate: false,
        sortByAssociatedGoal: false,
        sortByGroup: false,
        sortByTaskStatus: false,
        sortByTaskTitle: false,
        [stateValue]: !this.state[stateValue],
      },
      () =>
        this.props.TaskStore.sortTaskAssignments(
          sortByValue,
          this.state[stateValue]
        )
    )
  }

  render() {
    const {
      isLoadingData,
      markTaskAsCompleted,
      markTaskAsInProgress,
      taskAssignments,
      deleteTask
    } = this.props.TaskStore

    const SubheadingItems = () => {
      return (
        <Fragment>
          <Grid item sm={2}>
            <SubheadingItemText
              onClick={() => this.handleSortBy('sortByTaskTitle', 'TaskTitle')}
              sortable>
              To Do Item
            </SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText
              onClick={() =>
                this.handleSortBy('sortByAssignedDate', 'AssignedDate')
              }
              sortable>
              Assigned
            </SubheadingItemText>
          </Grid>
          <Grid item sm={3}>
            <SubheadingItemText
              onClick={() => this.handleSortBy('sortByGroup', 'TeamName')}
              sortable>
              Team
            </SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText
              onClick={() =>
                this.handleSortBy('sortByTaskStatus', 'TaskStatus')
              }
              sortable>
              Status
            </SubheadingItemText>
          </Grid>
          <Grid item sm={1}>
            <SubheadingItemText
              onClick={() =>
                this.handleSortBy('sortByAssociatedGoal', 'AssociatedGoal')
              }
              sortable>
              Goal
            </SubheadingItemText>
          </Grid>
        </Fragment>
      )
    }

    return (
      <>
        <div className={this.props.classes.buttonContainer}>
          <LinkButton
            label='Create New Task'
            dataTestId='create-new-task'
            to={`/assignTask/0/${this.props.HeaderStore.loggedInUserId}/0`}
          />
        </div>
        <SectionContainer
          data={taskAssignments}
          isLoadingData={isLoadingData}
          ListItem={TaskAssignmentItem}
          loadingText='Loading Task Assignments'
          markTaskAsCompleted={markTaskAsCompleted}
          markTaskAsInProgress={markTaskAsInProgress}
          deleteTask={deleteTask}
          noDataMessage='There are no Task Assignments.'
          sectionTitle='My Task Assignments'
          SubheadingItems={SubheadingItems}
        />
      </>
    )
  }
}

export default withStyles(styles)(MyTaskAssignments)
