import React from 'react'
import { Router } from '@reach/router'

import { ForgotPasswordScreen, LoginScreen } from '../screens'

const MainUnauthenticated = () => {
  return (
    <main>
      <Router>
        <LoginScreen default path='/' />
        <ForgotPasswordScreen path='/forgotPassword/:token' />
      </Router>
    </main>
  )
}

export default MainUnauthenticated
