import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Loading } from '../common'
import { CreateComment } from '../CreateStudentComment'

@inject('CreateStudentCommentStore', 'HeaderStore')
@observer
class CreateStudentComment extends Component {
  componentDidMount() {
    this.props.CreateStudentCommentStore.getData(
      this.props.commentId,
      this.props.groupId,
      this.props.noteId
    )
    this.props.HeaderStore.handleTitleChange('Create Student Comment')
  }
  render() {
    const {
      handleInputChange,
      isLoadingData,
      isSavingData,
      newComment,
      save
    } = this.props.CreateStudentCommentStore

    if (isLoadingData)
      return (
        <Loading
          dataTestId='loading-component'
          text='Loading Student Comment'
        />
      )
    if (isSavingData)
      return (
        <Loading dataTestId='saving-component' text='Saving Student Comment' />
      )

    return (
      <CreateComment
        handleInputChange={handleInputChange}
        newComment={newComment}
        save={save}
      />
    )
  }
}

export default CreateStudentComment
