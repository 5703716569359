import React, { useState } from 'react'
import { Button, Drawer, MenuItem } from '@material-ui/core'

const EsDrawer = ({ anchor, content, label, onClick, useButton }) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    if (onClick) {
      onClick()
    }

    setState({
      ...state,
      [anchor]: open,
    })
  }

  return (
    <>
      {useButton ? (
        <Button onClick={toggleDrawer(anchor, true)}>{label}</Button>
      ) : (
        <MenuItem onClick={toggleDrawer(anchor, true)}>{label}</MenuItem>
      )}

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}>
        {content}
      </Drawer>
    </>
  )
}

export default EsDrawer
