import React from 'react'
import { Chip, Grid, withStyles } from '@material-ui/core'
import {
  DeleteModal,
  ListItemText,
  Menu,
  MenuLink,
  UserAvatar,
} from '../common'
import { primaryColor, subheadingColor } from '../../constants'
import { getFirstNameLastNameInitials } from '../../helpers'

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    borderBottom: `1px solid ${subheadingColor}`,
    padding: theme.spacing(),
  },
  chip: {
    fontSize: 11,
    height: 22,
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  menu: {
    display: 'flex',
  },
  teamMember: {
    display: 'flex',
    alignItems: 'center',
  },
})

const TeamMemberItem = ({
  classes,
  deleteTeamMember,
  email,
  index,
  isLoggedInUserAdminOrTeamLead,
  isTeamLead,
  firstName,
  lastName,
  teamId,
  teamLeadCount,
  userId,
  userTeamId,
}) => {
  return (
    <Grid className={classes.container} container>
      <Grid item sm={1} className={classes.item}>
        <UserAvatar
          index={index}
          name={getFirstNameLastNameInitials(firstName, lastName)}
        />
      </Grid>
      <Grid item sm={3} className={`${classes.item} ${classes.teamMember}`}>
        <ListItemText>{`${firstName} ${lastName}`}</ListItemText>
        <Menu className={classes.menu}>
          <MenuLink
            label='Assign Task'
            to={`/assignTask/${teamId}/${userId}/0`}
          />
          {isLoggedInUserAdminOrTeamLead && (
            <DeleteModal
              confirmDeleteCallback={() => deleteTeamMember(userTeamId, teamId)}
              confirmDeleteLabel='Remove from Team'
              confirmDeleteMessage='Are you sure you want to remove this user from team?'
              itemName={`${firstName} ${lastName}`}
              menuItemText='Remove From Team'
              useMenuItem
            />
          )}
        </Menu>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.item}>
        {isTeamLead && (
          <Chip
            label={teamLeadCount > 1 ? 'Co-Team Lead' : 'Team Lead'}
            className={classes.chip}
            variant='outlined'
          />
        )}
      </Grid>
      <Grid item xs={12} sm={2} className={classes.item}>
        <a href={`mailto: ${email}`}>
          <ListItemText>{email}</ListItemText>
        </a>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TeamMemberItem)
