import React, { Fragment } from 'react'
import StudentItem from './StudentItem'
import { EmptyArrayMessage } from '../common'

const ListOfStudents = ({ data, groupId, removeStudent }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map((item, index) => (
            <StudentItem
              key={index}
              emailAddress={item.EmailAddress}
              firstName={item.FirstName}
              graduationYear={item.GraduationYear}
              groupId={groupId}
              index={index}
              lastCommaFirst={item.LastCommaFirst}
              lastName={item.LastName}
              removeStudent={removeStudent}
              studentUserId={item.StudentUserId}
              userTeamId={item.UserTeamId}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no students to display.' />
      )}
    </Fragment>
  )
}

export default ListOfStudents
