import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi, postDataApi } from '../services/api'

import { sortArrayByFieldName } from '../helpers/compare'
import { urls } from '../constants'
import { removeNotification, createNotificationObject } from '../helpers'

class Store {
  @observable isLoadingData = true
  @observable taskAssignments = []
  @observable taskAssignmentNotifications = []

  getData() {
    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: urls.GET_DASHBOARD_TASKS_BY_ASSIGNED_USER
    })
  }

  @action handleGetDataResponse = data => {
    this.taskAssignments = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'AssignedDate'
    })

    this.isLoadingData = false
  }

  @action markTaskAsCompleted = taskId => {
    postDataApi({
      handleSuccess: this.handleMarkTaskAsCompletedResponse,
      url: `${urls.MARK_TASK_AS_COMPLETED}${taskId}`
    })
  }

  @action handleMarkTaskAsCompletedResponse = data => {
    const filtered = this.taskAssignments.filter(x => x.TaskId !== data)
    this.taskAssignments = filtered

    const notification = createNotificationObject({
      key: data,
      message: 'Task has been marked as completed',
      variant: 'success'
    })

    this.taskAssignmentNotifications.push(notification)
  }

  @action markTaskAsInProgress = taskId => {
    postDataApi({
      handleSuccess: this.handleMarkTaskAsInProgressResponse,
      url: `${urls.MARK_TASK_AS_IN_PROGRESS}${taskId}`
    })
  }

  @action handleMarkTaskAsInProgressResponse = data => {
    const updatedTaskList = this.taskAssignments.map(p =>
      p.TaskId === data
        ? { ...p, TaskStatusId: 4, TaskStatus: 'In Progress' }
        : p
    )

    this.taskAssignments = updatedTaskList

    const notification = createNotificationObject({
      key: data,
      message: 'Task has been marked as in progress',
      variant: 'success'
    })

    this.taskAssignmentNotifications.push(notification)
  }

  @action removeSnackbar = keyValue => {
    this.taskAssignmentNotifications = removeNotification({
      array: this.taskAssignmentNotifications,
      keyValue
    })
  }

  @action sortTaskAssignments = (sortByValue, asc) => {
    let array = this.taskAssignments.slice()
    sortArrayByFieldName({ array, ascValue: asc, field: sortByValue })

    this.taskAssignments = array
  }

  @action deleteTask = itemId => {
    this.isLoadingData = true

    deleteDataApi({
      handleSuccess: this.deleteTaskResponse,
      itemId,
      url: `${urls.DELETE_TASK_BY_TASK_ID}${itemId}`
    })
  }

  @action deleteTaskResponse = (taskId) => {
    this.taskAssignments = this.taskAssignments.filter((task) => task.TaskId !== taskId);

    this.taskAssignmentNotifications.push(
      createNotificationObject({
        key: taskId,
        message: 'Task has been deleted',
        variant: 'success',
      })
    )
    this.isLoadingData = false
  }
}

export default new Store()
