import React, { forwardRef } from 'react'
import { makeStyles, MenuItem } from '@material-ui/core'
import { Link } from '@reach/router'

const useStyles = makeStyles(theme => ({
  link: {
    color: '#000',
    textDecoration: 'none'
  }
}))

const MenuLink = forwardRef((props, ref) => {
  const { label, to } = props

  const classes = useStyles()

  return (
    <Link className={classes.link} to={to}>
      <MenuItem>{label}</MenuItem>
    </Link>
  )
})

export default MenuLink
