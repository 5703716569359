import React from 'react'
import { Grid } from '@material-ui/core'
import { Subheading, SubheadingItemText } from '../common'

const StudentSubheading = () => {
  return (
    <Subheading>
      <Grid item sm={3}>
        <SubheadingItemText>Student Name</SubheadingItemText>
      </Grid>
      <Grid item sm={4}>
        <SubheadingItemText>Email</SubheadingItemText>
      </Grid>
      <Grid item sm={2}>
        <SubheadingItemText>Graduation Year</SubheadingItemText>
      </Grid>
    </Subheading>
  )
}

export default StudentSubheading
