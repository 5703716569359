import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import { ListOfUsers, Subheading } from '../ManageUsers'
import { LinkButton, Loading } from '../common'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  }
})

@inject('HeaderStore', 'ManageStudentsStore')
@observer
class ManageStudents extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Manage Students')
    this.props.ManageStudentsStore.getData()
  }

  render() {
    const { classes } = this.props
    const {
      deleteItem,
      isDeletingData,
      isLoadingData,
      users
    } = this.props.ManageStudentsStore

    if (isDeletingData)
      return <Loading dataTestId='delete-component' text='Deleting Student' />
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Student Info' />
      )

    return (
      <Fragment>
        <div
          data-testid='create-student-container'
          className={classes.buttonContainer}>
          <LinkButton label='Create Student' to='/createStudent/0' />
        </div>
        <Subheading />
        <ListOfUsers data={users} deleteItem={deleteItem} />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ManageStudents)
