import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  sortable: {
    cursor: 'pointer',
    fontSize: 13,
    textDecoration: 'underline',
    textTransform: 'uppercase'
  },
  typography: {
    fontSize: 13,
    textTransform: 'uppercase'
  }
})

const SubheadingItemText = ({ children, onClick, sortable }) => {
  const classes = useStyles()
  return (
    <Typography
      className={sortable ? classes.sortable : classes.typography}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

export default SubheadingItemText
