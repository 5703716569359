import { action, observable } from 'mobx'
import { getDataApi, postDataApi } from '../services/api'

import { sortBy } from '../helpers/compare'
import { urls } from '../constants'

class GroupTasksStore {
  @observable isLoadingData = false
  @observable completedTasks = []
  @observable tasks = []
  @observable goalStatusId = 0
  @observable reverseSort = true

  @action getCompletedTasks = (goalId) => {
    getDataApi({
      handleSuccess: this.handleGetCompletedTasksResponse,
      url: `${urls.GET_TASKS_COMPLETED_BY_GOAL_ID}${goalId}`,
    })
  }

  @action getInProgressTasks = (goalId) => {
    getDataApi({
      handleSuccess: this.handleGetInProgressTasksResponse,
      url: `${urls.GET_TASKS_REMAINING_BY_GOAL_ID}${goalId}`,
    })
  }

  @action getGoalStatus = (goalId) => {
    getDataApi({
      handleSuccess: this.handleGetGoalStatusResponse,
      url: `${urls.GET_GOAL_STATUS}${goalId}`,
    })
  }

  markGroupGoal = (taskId) => {
    postDataApi({
      handleSuccess: this.handleMarkGroupGoalResponse,
      url: `${urls.MARK_TASK_AS_COMPLETED}${taskId}`,
    })
  }

  @action handleGetCompletedTasksResponse = (data) => {
    data.sort(sortBy('TaskAssignedDate', false))
    this.completedTasks = data
  }

  @action handleGetInProgressTasksResponse = (data) => {
    data.sort(sortBy('TaskAssignedDate', true))
    this.tasks = data
  }

  @action handleGetGoalStatusResponse = (data) => {
    this.goalStatusId = data
  }

  @action markGroupGoalAsInProgress = (taskId) => {
    postDataApi({
      handleSuccess: this.handleMarkGroupGoalAsInProgressResponse,
      url: `${urls.MARK_TASK_AS_IN_PROGRESS}${taskId}`,
    })
  }

  @action sortByValue = (sortByValue) => {
    this.reverseSort = !this.reverseSort

    const sortable = this.tasks.slice()
    sortable.sort(sortBy(sortByValue, this.reverseSort))

    this.tasks = sortable
  }

  @action handleMarkGroupGoalResponse = (taskId) => {
    this.tasks = this.tasks.filter((task) => task.TaskId !== taskId)
  }

  @action handleMarkGroupGoalAsInProgressResponse = (taskId) => {
    this.completedTasks = this.completedTasks.filter(
      (task) => task.TaskId !== taskId
    )
  }
}

export default new GroupTasksStore()
