import { action, observable } from 'mobx'
import { getDataApi, postDataApi } from '../services/api'
import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'
import { navigate } from '@reach/router'

class CreateStudentCommentStore {
  @observable isLoadingData = true
  @observable isSavingData = false

  @observable createStudentCommentNotifications = []

  @observable commentId = 0
  @observable groupId = 0
  @observable newComment = ''
  @observable userId = ''
  @observable userNoteId = 0

  getData = (commentId, groupId, noteId) => {
    this.isLoadingData = true
    this.commentId = parseInt(commentId)
    this.groupId = groupId
    this.userNoteId = parseInt(noteId)

    if (commentId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetDataResponse,
        url: `${urls.GET_USER_NOTE_COMMENT_BY_ID}${this.commentId}`
      })
    } else {
      this.isLoadingData = false
    }
  }

  save = () => {
    this.isSavingData = true

    const postObject = {
      Comment: this.newComment,
      Id: this.commentId,
      UserNoteId: this.userNoteId
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_USER_NOTE_COMMENT
    })
  }

  @action handleGetDataResponse = data => {
    this.newComment = data.Comment
    this.isLoadingData = false
  }

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value
  }

  @action handleSaveResponse = data => {
    const notification = createNotificationObject({
      key: data,
      message: 'A new comment has been created.',
      variant: 'success'
    })

    this.createStudentCommentNotifications.push(notification)
    this.newComment = ''

    this.isSavingData = false
    navigate(`/studentComments/${this.groupId}/${this.userNoteId}`)
  }

  @action removeSnackbar = keyValue => {
    this.createStudentCommentNotifications = removeNotification({
      array: this.createStudentCommentNotifications,
      keyValue
    })
  }
}

export default new CreateStudentCommentStore()
