import { action, observable } from 'mobx'
import { postDataApi } from '../services/api'
import { urls } from '../constants'

class ForgotPasswordStore {
  @observable newPasswordSaved = false

  resetPassword = (form, token) => {
    const postObject = {
      NewPassword: form.Password,
      Token: token
    }

    postDataApi({
      handleSuccess: this.handleResetPasswordresponse,
      postObject,
      url: urls.SAVE_USER_PASSWORD
    })
  }

  @action handleResetPasswordresponse = data => {
    this.newPasswordSaved = true
  }
}

export default new ForgotPasswordStore()
