import React, { Fragment } from 'react'
import {
  Grid,
  Hidden,
  Tab,
  Tabs,
  Typography,
  makeStyles
} from '@material-ui/core'
import { SearchField, SectionTitle } from '../common'
import AddUsersVL from './AddUsersVL'
import { subheadingColor } from '../../constants'

const useStyles = makeStyles(theme => ({
  groupTitleSection: {
    display: 'flex',
    alignItems: 'center'
  },
  showAll: {
    fontSize: 14,
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  showAllFilterContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.only('xs')]: {
      backgroundColor: subheadingColor,
      width: '100%',
      padding: theme.spacing()
    }
  },
  tabs: {
    marginBottom: theme.spacing()
  }
}))

const AddUsersToTeam = ({
  addAllUsersToGroupByUserTypeId,
  addUserToGroup,
  clearFilter,
  filterValue,
  handleInputChange,
  handleTabChange,
  partnerList,
  stafferList,
  studentList,
  selectedTab
}) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid container className={classes.groupTitleSection}>
        <Grid item xs={12} lg={5}>
          <Hidden smDown>
            <SectionTitle text='Add Users To Team' />
          </Hidden>
        </Grid>
        <div className={classes.showAllFilterContainer}>
          <Grid item xs={3} sm={2}>
            <Typography className={classes.showAll} onClick={clearFilter}>
              Show All
            </Typography>
          </Grid>
          <Grid item xs={9} sm={2}>
            <SearchField
              name='filterValue'
              onChange={handleInputChange}
              placeholder='Filter Users'
              value={filterValue}
            />
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={handleTabChange}>
          <Tab label={`Staffers (${stafferList.length})`} />
          <Tab label={`Students (${studentList.length})`} />
          <Tab label={`Partners (${partnerList.length})`} />
        </Tabs>
      </Grid>
      {selectedTab === 0 && (
        <AddUsersVL
          addAllUsersToGroupByUserTypeId={addAllUsersToGroupByUserTypeId}
          addUserToGroup={addUserToGroup}
          data={stafferList}
          noDataText='There are no staffers to display.'
          subheadingText='Staffers'
          userTypeId={1}
        />
      )}
      {selectedTab === 1 && (
        <AddUsersVL
          addAllUsersToGroupByUserTypeId={addAllUsersToGroupByUserTypeId}
          addUserToGroup={addUserToGroup}
          data={studentList}
          noDataText='There are no students to display.'
          subheadingText='Students'
          userTypeId={2}
        />
      )}
      {selectedTab === 2 && (
        <AddUsersVL
          addAllUsersToGroupByUserTypeId={addAllUsersToGroupByUserTypeId}
          addUserToGroup={addUserToGroup}
          data={partnerList}
          noDataText='There are no partners to display.'
          subheadingText='Partners'
          userTypeId={3}
        />
      )}
    </Fragment>
  )
}

export default AddUsersToTeam
