import { action, observable } from 'mobx'

import { urls } from '../constants'
import {
  removeNotification,
  createNotificationObject,
  sortArrayByLastNameFirstName,
} from '../helpers'
import { getDataApi, postDataApi, deleteDataApi } from '../services/api'

class MeetingAgendaStore {
  @observable agendaId = '00000000-0000-0000-0000-000000000000'
  @observable agendaItems = []
  @observable agendaTitle = ''
  @observable allowedToAddItems = false
  @observable groupNorms = []
  @observable hasAgendaPosted = false
  @observable hasPostedData = false
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable isPostingAgenda = false
  @observable meetingAgendaNotifications = []
  @observable meetingEndTime
  @observable meetingLocation = ''
  @observable meetingStartTime
  @observable meetingZoomJoinLink = ''
  @observable noteTakerName = ''
  @observable students = []
  @observable teamId = ''
  @observable usersInMeeting = []

  @observable isLoadingGroupNorms = true
  @observable isLoadingStudents = true

  deleteItem = (itemId) => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteResponse,
      itemId,
      url: `${urls.DELETE_AGENDA_ITEM_BY_AGENDA_ITEM_ID}${itemId}`,
    })
  }

  getData(meetingId) {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_COMPLETE_AGENDA_MEETING_AGENDA_ITEMS_BY_MEETING_ID}${meetingId}`,
    })
  }

  getNorms = () => {
    this.isLoadingGroupNorms = true

    getDataApi({
      handleSuccess: (data) => {
        this.groupNorms = data.Norms
        this.isLoadingGroupNorms = false
      },
      url: `${urls.GET_TEAM_NORMS_BY_TEAM_ID}${this.teamId}`,
    })
  }

  getStudents = () => {
    this.isLoadingStudents = true

    getDataApi({
      handleSuccess: (data) => {
        this.students = sortArrayByLastNameFirstName(data)
        this.isLoadingStudents = false
      },
      url: `${urls.GET_STUDENTS_BY_TEAM_ID}${this.teamId}`,
    })
  }

  postAgenda = () => {
    this.isPostingAgenda = true

    postDataApi({
      handleSuccess: this.handlePostAgendaResponse,
      url: `${urls.POST_MEETING_AGENDA}${this.agendaId}`,
    })
  }

  @action handleDeleteResponse = (id) => {
    this.agendaItems.replace(this.agendaItems.filter((item) => item.Id !== id))

    this.meetingAgendaNotifications.push(
      createNotificationObject({
        key: id,
        message: 'Agenda item has been deleted.',
        variant: 'success',
      })
    )

    this.isDeletingData = false
  }

  @action handleGetDataResponse = (data) => {
    this.agendaId = data.AgendaId
    this.agendaItems = data.AgendaItems
    this.agendaTitle = data.AgendaTitle
    this.allowedToAddItems = data.AllowedToAddItems
    this.hasAgendaPosted = data.HasAgendaPosted
    this.isLoadingData = false
    this.meetingEndTime = data.MeetingEndTime
    this.meetingLocation = data.MeetingLocation
    this.meetingStartTime = data.MeetingStartTime
    this.meetingZoomJoinLink = data.MeetingZoomJoinLink
    this.noteTakerName = data.NoteTakerName
    this.teamId = data.TeamId
  }

  @action handlePostAgendaResponse = (data) => {
    this.usersInMeeting = data.UsersInMeeting

    this.hasPostedData = true
    this.isPostingAgenda = false
  }

  @action moveItem = (result) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    let array = this.agendaItems.slice()
    const [removed] = array.splice(sourceIndex, 1)
    array.splice(destinationIndex, 0, removed)

    this.agendaItems = array
  }

  @action removeSnackbar = (keyValue) => {
    this.meetingAgendaNotifications = removeNotification({
      array: this.meetingAgendaNotifications,
      keyValue,
    })
  }

  @action resetHasPostedData = () => {
    this.hasPostedData = false
  }

  @action saveAgendaItemsAfterReorder = () => {
    this.isSavingData = true

    let array = []
    this.agendaItems.forEach((item, index) =>
      array.push({
        NewDisplayOrder: index,
        AgendaItemId: item.Id,
      })
    )

    postDataApi({
      handleSuccess: this.handleSaveAgendaItemsAfterReorderReponse,
      postObject: { ReorderItems: array },
      url: `${urls.SAVE_AGENDA_ITEMS_REORDER}`,
    })
  }

  @action handleSaveAgendaItemsAfterReorderReponse = (data) => {
    this.meetingAgendaNotifications.push(
      createNotificationObject({
        key: data,
        message: 'Agenda Items have been reordered',
        variant: 'success',
      })
    )

    this.isSavingData = false
  }
}

export default new MeetingAgendaStore()
