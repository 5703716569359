import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs } from '@material-ui/core'
import { Completed, InProgress } from '../MeetingTasks'

@inject('HeaderStore')
@observer
class MeetingsTasks extends Component {
  state = {
    selectedTab: 0
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Meeting Tasks')
  }

  handleTabChange = (e, value) => {
    this.setState({ selectedTab: value })
  }

  render() {
    const { meetingId } = this.props
    const { selectedTab } = this.state

    return (
      <div>
        <Tabs value={selectedTab} onChange={this.handleTabChange}>
          <Tab label='Active' />
          <Tab label='Completed' />
        </Tabs>
        {selectedTab === 0 && <InProgress meetingId={meetingId} />}
        {selectedTab === 1 && <Completed meetingId={meetingId} />}
      </div>
    )
  }
}

export default MeetingsTasks
