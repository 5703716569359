import React from 'react'
import { Link } from '@reach/router'
import { CircularProgress, Typography, makeStyles } from '@material-ui/core'

import { Drawer, InternalLink, UserAvatar } from '../common'
import { primaryColor } from '../../constants'
import { getFirstNameLastNameInitials } from '../../helpers'

const MODAL_WIDTH = 250

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    width: MODAL_WIDTH,
    padding: theme.spacing(),
  },
  drawerTitle: {
    color: primaryColor,
    borderBottom: '1px solid #d9d9d9',
    padding: theme.spacing(),
  },
  editLinkContainer: {
    padding: theme.spacing(),
  },
  groupNormItem: {
    padding: theme.spacing(),
    fontWeight: 300,
  },
  modalLoadingContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: MODAL_WIDTH,
  },
  noDataMessage: {
    padding: theme.spacing(),
  },
  studentItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '&:visited': {
      color: '#0000EE',
    },
  },
}))

const AgendaModal = ({
  allowedToAddItems,
  getNorms,
  getStudents,
  groupNorms,
  isLoadingGroupNorms,
  isLoadingStudents,
  students,
  teamId,
}) => {
  const classes = useStyles()

  const renderModalContent = ({
    data,
    linkLabel,
    linkTo,
    ListItem,
    loading,
    loadingMessage,
    modalTitle,
    noDataMessage,
  }) => {
    if (loading) {
      return (
        <div className={classes.modalLoadingContainer}>
          <CircularProgress style={{ color: primaryColor, marginBottom: 8 }} />
          <Typography>{loadingMessage}</Typography>
        </div>
      )
    }

    return (
      <div className={classes.drawerContainer}>
        <Typography className={classes.drawerTitle}>{modalTitle}</Typography>
        {allowedToAddItems && (
          <div className={classes.editLinkContainer}>
            <InternalLink italicize label={linkLabel} to={linkTo} />
          </div>
        )}
        {data.length === 0 ? (
          <Typography className={classes.noDataMessage}>
            {noDataMessage}
          </Typography>
        ) : (
          <>
            {data.map((item, index) => {
              return <ListItem index={index} {...item} />
            })}
          </>
        )}
      </div>
    )
  }

  const StudentItem = ({
    index,
    FirstName,
    LastCommaFirst,
    LastName,
    StudentUserId,
  }) => {
    const classes = useStyles()
    return (
      <Link
        className={classes.studentItem}
        key={StudentUserId}
        to={`/studentNotes/${teamId}/${StudentUserId}`}>
        <UserAvatar
          index={index}
          name={getFirstNameLastNameInitials(FirstName, LastName)}
        />
        <Typography className={classes.groupNormItem} variant='subtitle2'>
          {LastCommaFirst}
        </Typography>
      </Link>
    )
  }

  const TeamNormItem = ({ Id, Norm }) => {
    const classes = useStyles()
    return (
      <Typography
        className={classes.groupNormItem}
        key={Id}
        variant='subtitle2'>
        {Norm}
      </Typography>
    )
  }

  return (
    <div>
      <Drawer
        anchor='right'
        content={renderModalContent({
          data: students,
          linkLabel: 'Edit Students',
          linkTo: `/createTeam/${teamId}`,
          ListItem: StudentItem,
          loading: isLoadingStudents,
          loadingMessage: 'Loading Students',
          modalTitle: 'Students',
          noDataMessage: 'There are no students in this team',
        })}
        label='Students'
        onClick={() => getStudents()}
      />
      <Drawer
        anchor='right'
        content={renderModalContent({
          data: groupNorms,
          linkLabel: 'Edit Norms',
          linkTo: `/groupNorms/${teamId}`,
          ListItem: TeamNormItem,
          loading: isLoadingGroupNorms,
          loadingMessage: 'Loading Team Norms',
          modalTitle: 'Team Norms',
          noDataMessage: 'There are no Norms for this team',
        })}
        label='Team Norms'
        onClick={() => getNorms()}
      />
    </div>
  )
}

export default AgendaModal
