import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import MeetingItem from '../Meetings/MeetingItem'
import { Loading, SectionContainer, SubheadingItemText } from '../common'

@inject('MeetingsStore')
@observer
class HistoricalMeetings extends React.Component {
  state = {
    sortByMeetingName: true,
    sortByGroupName: false
  }

  componentDidMount() {
    this.props.MeetingsStore.getHistoricalMeetings()
  }

  handleSortBy = (stateValue, sortByValue) => {
    this.setState(
      {
        sortByMeetingName: false,
        sortByGroupName: false,
        [stateValue]: !this.state[stateValue]
      },
      () =>
        this.props.MeetingsStore.sortUpcomingMeetings(
          sortByValue,
          this.state[stateValue]
        )
    )
  }

  render() {
    const {
      deleteMeeting,
      historicalMeetings,
      isDeletingData,
      isLoadingData
    } = this.props.MeetingsStore

    const SubheadingItems = () => {
      return (
        <Fragment>
          <Grid item sm={2}>
            <SubheadingItemText
              data-test='sortByMeetingName-component'
              onClick={() =>
                this.handleSortBy('sortByMeetingName', 'MeetingName')
              }
              sortable>
              Meeting Name
            </SubheadingItemText>
          </Grid>
          <Grid item sm={2} lg={1}>
            <SubheadingItemText>Date</SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText>Time</SubheadingItemText>
          </Grid>
          <Grid item sm={3}>
            <SubheadingItemText
              data-test='sortByGroup-component'
              onClick={() =>
                this.handleSortBy('sortByGroupName', 'FlattenedTeams')
              }
              sortable>
              Team
            </SubheadingItemText>
          </Grid>
          <Grid item sm={1}>
            <SubheadingItemText>Location</SubheadingItemText>
          </Grid>
        </Fragment>
      )
    }

    if (isDeletingData) return <Loading text='Deleting Historical Meeting' />

    return (
      <SectionContainer
        data={historicalMeetings}
        data-test='component-container'
        deleteItem={deleteMeeting}
        isLoadingData={isLoadingData}
        ListItem={MeetingItem}
        loadingText='Loading Historical Meeting Details'
        noDataMessage='There are no Historical Meetings.'
        sectionTitle='Historical Meetings'
        SubheadingItems={SubheadingItems}
      />
    )
  }
}

export default HistoricalMeetings
