import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'
import {
  createNotificationObject,
  removeNotification,
  sortArrayByLastNameFirstName
} from '../helpers'
import { urls } from '../constants'

class ManageStaffStore {
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable manageStafferNotifications = []
  @observable users = []

  getData = () => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: urls.GET_ALL_STAFFERS
    })
  }

  deleteItem = id => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteItemResponse,
      itemId: id,
      url: `${urls.DELETE_USER_BY_USER_ID}${id}`
    })
  }

  @action handleDeleteItemResponse = id => {
    this.users = this.users.filter(item => item.Id !== id)

    const notification = createNotificationObject({
      key: id,
      message: 'Staffer has been deleted.',
      variant: 'success'
    })

    this.manageStafferNotifications.push(notification)
    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.users = sortArrayByLastNameFirstName(data)
    this.isLoadingData = false
  }

  @action removeSnackbar = keyValue => {
    this.manageStaffersNotifications = removeNotification({
      array: this.manageStaffersNotifications,
      keyValue
    })
  }
}

export default new ManageStaffStore()
