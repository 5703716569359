import React from 'react'
import { Link } from '@reach/router'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { flexRow, navIcon, navText } from '../../constants'

const styles = theme => ({
  icon: {
    ...navIcon(theme)
  },
  link: {
    ...flexRow,

    textDecoration: 'none'
  },
  text: {
    ...navText
  }
})

const NavLink = ({ classes, label, onClick, Icon, to }) => (
  <Link className={classes.link} to={to}>
    <ListItem button key={label} onClick={onClick} style={{ paddingLeft: 0 }}>
      <ListItemIcon className={classes.icon}>{Icon}</ListItemIcon>
      <ListItemText
        classes={{ primary: classes.text }}
        primary={label}
        style={{ paddingLeft: 0 }}
      />
    </ListItem>
  </Link>
)

export default withStyles(styles)(NavLink)
