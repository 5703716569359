import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { TaskList } from '../Tasks'

@inject('GroupTasksStore')
@observer
class InProgress extends Component {
  componentDidMount() {
    this.props.GroupTasksStore.getInProgressTasks(this.props.goalId)
  }
  render() {
    const { markGroupGoal, tasks } = this.props.GroupTasksStore
    return <TaskList data={tasks} markTask={markGroupGoal} />
  }
}

export default InProgress
