import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import { LinkButton, Loading } from '../common'
import { ListOfGoals } from '../TeamGoals'

const styles = (theme) => ({
  createGoalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',

    paddingBottom: theme.spacing(2),
  },
})

@inject('HeaderStore', 'GroupGoalsStore')
@observer
class GroupGoals extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Team Goals')
    this.props.GroupGoalsStore.getData()
  }
  render() {
    const { classes } = this.props
    const { isAdmin, isAnyTeamLead } = this.props.HeaderStore
    const {
      deleteGoal,
      markGoalAsCompleted,
      isDeletingData,
      isLoading,
      listOfGoals,
    } = this.props.GroupGoalsStore

    const lsIsAnyTeamLead = localStorage.getItem('IsAnyTeamLead')

    if (isDeletingData) {
      return <Loading text='Deleting Team Goal' />
    }
    if (isLoading) {
      return (
        <Loading dataTestId='loading-component' text='Loading Team Goals' />
      )
    }

    const renderCreateGoalButton = () => {
      if (isAdmin || isAnyTeamLead || lsIsAnyTeamLead === 'true') {
        return (
          <div className={classes.createGoalButtonContainer}>
            <LinkButton
              dataTestId='create-goal-link'
              label='Create A Goal'
              to='/createGoal/0'
            />
          </div>
        )
      }
    }

    return (
      <div data-testid='container'>
        {renderCreateGoalButton()}
        <ListOfGoals
          data={listOfGoals}
          deleteGoal={deleteGoal}
          markGoalAsCompleted={markGoalAsCompleted}
        />
      </div>
    )
  }
}

export default withStyles(styles)(GroupGoals)
