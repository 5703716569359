import React from 'react'
import { CircularProgress, Typography, withStyles } from '@material-ui/core'
import { primaryColor } from '../../constants'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  progress: {
    color: primaryColor
  },
  typography: {
    marginTop: theme.spacing(2)
  }
})

const LoadingSm = ({ classes, dataTestId, text }) => {
  return (
    <div className={classes.container} data-testid={dataTestId}>
      <CircularProgress className={classes.progress} size={80} />
      <Typography className={classes.typography} variant='h4' align='center'>
        {text}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(LoadingSm)
