import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { CreateAgendaItem, ListOfAgendaItems } from '../ManageAgendaItems'
import { Loading } from '../common'

@inject('HeaderStore', 'ManageAgendaTypesStore')
@observer
class ManageAgendaTypes extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Manage Agenda Types')
    this.props.ManageAgendaTypesStore.getData()
  }

  render() {
    const {
      agendaItems,
      deleteItem,
      editItem,
      isDeletingData,
      isLoadingData,
      isSavingData,
      itemName,
      itemLetterAbbreviation,
      save,
      sortAgendaItems
    } = this.props.ManageAgendaTypesStore

    if (isDeletingData)
      return (
        <Loading dataTestId='deleting-component' text='Deleting Agenda Type' />
      )
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Agenda Types' />
      )
    if (isSavingData) return <Loading text='Saving Agenda Type' />

    return (
      <div>
        <CreateAgendaItem
          itemName={itemName}
          itemLetterAbbreviation={itemLetterAbbreviation}
          save={save}
        />
        <ListOfAgendaItems
          data={agendaItems}
          deleteItem={deleteItem}
          editItem={editItem}
          sortAgendaItems={sortAgendaItems}
        />
      </div>
    )
  }
}

export default ManageAgendaTypes
