import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Grid } from '@material-ui/core'
import {
  DatePickerFormField,
  SelectFormField,
  TextFormField,
  SubmitButton,
  SectionTitle,
  Loading,
} from '../common'

const schema = yup.object({
  dateDue: yup.date(),
  selectedAgendaItem: yup.string().nullable(),
  selectedGoal: yup.string(),
  selectedMeeting: yup.string().nullable(),
  todoItem: yup.string().required('A title must be provided for the todo item'),
})

@inject('AssignTaskStore', 'HeaderStore')
@observer
class AssignTask extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Assign Task')
    this.props.AssignTaskStore.getData(
      this.props.teamId,
      this.props.userId,
      this.props.goalId
    )
  }

  render() {
    const {
      agendaItems,
      dateDue,
      getAgendaItems,
      getGoalItems,
      getMeetingItemsByUserAndGoal,
      goals,
      isLoadingData,
      isSavingData,
      meetings,
      users,
      save,
      selectedAgendaItem,
      selectedGoal,
      selectedMeeting,
      selectedUser,
      todoItem,
    } = this.props.AssignTaskStore

    if (isLoadingData) return <Loading text='Loading Task Info' />
    if (isSavingData) return <Loading text='Saving Task' />

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          dateDue,
          selectedAgendaItem,
          selectedGoal,
          selectedMeeting,
          todoItem,
        }}
        onSubmit={(values) => save(values)}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={schema}>
        {(
          // passing in the formProps to manually set the value
          // for the selectedMeeting, so that I can make an API
          // call as a callback
          formProps
        ) => (
          <Form>
            <Grid container direction='column'>
              {this.props.goalId !== '0' ? (
                <>
                  <Field
                    fieldValue='selectedUser'
                    label='Assigned To'
                    menuItems={users}
                    name='selectedUser'
                    onChange={(e) => {
                      formProps.setFieldValue('selectedUser', e.target.value)
                      getMeetingItemsByUserAndGoal(
                        e.target.value,
                        this.props.goalId
                      )
                    }}
                    component={SelectFormField}
                  />
                </>
              ) : (
                <>
                  <SectionTitle text={`Assign To: ${selectedUser}`} />
                </>
              )}

              <Field
                label='Todo Item'
                name='todoItem'
                component={TextFormField}
              />
              <Field
                fieldValue='selectedMeeting'
                label='Meetings'
                menuItems={meetings}
                name='selectedMeeting'
                onChange={(e) => {
                  console.log(e.target.value)
                  formProps.setFieldValue('selectedMeeting', e.target.value)
                  getAgendaItems(e.target.value)
                  if (this.props.goalId === '0') {
                    getGoalItems(e.target.value)
                  }
                }}
                component={SelectFormField}
              />
              <Field
                label='Agenda Items'
                menuItems={agendaItems}
                name='selectedAgendaItem'
                component={SelectFormField}
              />
              {this.props.goalId !== '0' ? (
                <>
                  <Field
                    label='Goal'
                    name='selectedGoal'
                    disabled={true}
                    component={TextFormField}
                  />
                </>
              ) : (
                <>
                  <Field
                    label='Goal'
                    menuItems={goals}
                    name='selectedGoal'
                    component={SelectFormField}
                  />
                </>
              )}

              <Field
                fieldValue='dateDue'
                label='Date Due'
                name='dateDue'
                component={DatePickerFormField}
              />
              <div style={{ paddingTop: 16 }}>
                <SubmitButton label='Save' />
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  }
}

export default AssignTask
