import React, { Fragment } from 'react'
import EmptyArrayMessage from './EmptyArrayMessage'

const PaginatedList = ({
  data,
  deleteItem,
  ListItem,
  noDataMessage,
  page,
  rowsPerPage,
  ...rest
}) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <ListItem
                key={index}
                details={item}
                deleteItem={deleteItem}
                {...rest}
              />
            ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text={noDataMessage} />
      )}
    </Fragment>
  )
}

export default PaginatedList
