import React, { Fragment } from 'react'
import { EmptyArrayMessage } from '../common'
import CommentItem from './CommentItem'

const ListOfComments = ({ data, deleteStudentComment, groupId, noteId }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map(item => (
            <CommentItem
              key={item.Id}
              comment={item.Comment}
              dateCommented={item.DateCommented}
              deleteStudentComment={deleteStudentComment}
              groupId={groupId}
              id={item.Id}
              noteId={noteId}
              postedByFInitialLastName={item.PostedByFInitialLastName}
              postedByFirstName={item.PostedByFirstName}
              postedByLastName={item.PostedByLastName}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no comments to display' />
      )}
    </Fragment>
  )
}

export default ListOfComments
