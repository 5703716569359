import React, { Component, Fragment } from 'react'
import { Redirect, Router } from '@reach/router'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import { headerHeight, headerHeightXs } from '../../constants'

import {
  AdminScreen,
  AssignTaskScreen,
  CreateAgendaItemScreen,
  CreateDistrictGoalScreen,
  CreateGoalScreen,
  CreateMeetingNotesTask,
  CreateMeetingScreen,
  CreateSchoolGoalScreen,
  CreateStafferScreen,
  CreateStudentNoteScreen,
  CreateStudentCommentScreen,
  CreateStudentScreen,
  CreateTeamScreen,
  DashboardScreen,
  DistrictGoalsScreen,
  EditTaskScreen,
  GroupNormsScreen,
  ImpersonateScreen,
  TeamTasksScreen,
  HelpScreen,
  LoginScreen,
  ManageAgendaTypesScreen,
  ManageStaffScreen,
  ManageStudentsScreen,
  ManageTeamsScreen,
  ManageDistrictGoalsScreen,
  ManageSchoolGoalsScreen,
  MeetingAgendaScreen,
  MeetingNotesScreen,
  MeetingsScreen,
  MeetingTasksScreen,
  MyAccountScreen,
  MyTeamsScreen,
  SchoolGoalsScreen,
  StudentCommentsScreen,
  StudentGroupsScreen,
  StudentNotesScreen,
  SuperAdminScreen,
  TaskNotesScreen,
  TeamGoalsScreen,
  ZoomIntegrationResponse,
} from '../screens'

const styles = (theme) => ({
  content: {
    top: headerHeight,
    position: 'relative',

    flexGrow: 1,

    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(3),

    height: 'calc(100vh - 81px)',

    [theme.breakpoints.only('xs')]: {
      top: headerHeightXs,
      paddingTop: theme.spacing(),
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
})

@inject('HeaderStore')
@observer
class Main extends Component {
  render() {
    const { classes } = this.props
    const { isAdmin } = this.props.HeaderStore
    const { isSuperAdmin } = this.props.HeaderStore

    const AdminRoute = ({ Component, ...rest }) => {
      return (
        <Fragment>
          {isAdmin ? (
            <Component {...rest} />
          ) : (
            <Redirect from='' to='/' noThrow />
          )}
        </Fragment>
      )
    }

    const SuperAdminRoute = ({ Component, ...rest }) => {
      return (
        <Fragment>
          {isSuperAdmin ? (
            <Component {...rest} />
          ) : (
            <Redirect from='' to='/' noThrow />
          )}
        </Fragment>
      )
    }

    return (
      <main className={classes.content}>
        <Router>
          <AdminRoute Component={AdminScreen} path='/admin' />
          <AdminRoute
            Component={ManageAgendaTypesScreen}
            path='/manageAgendaTypes'
          />
          <AdminRoute Component={ManageStaffScreen} path='/manageStaff' />
          <AdminRoute Component={ManageStudentsScreen} path='/manageStudents' />
          <AdminRoute Component={ManageTeamsScreen} path='/manageTeams' />
          <AdminRoute
            Component={ManageDistrictGoalsScreen}
            path='/manageDistrictGoals'
          />
          <AdminRoute
            Component={ManageSchoolGoalsScreen}
            path='/manageSchoolGoals'
          />

          <SuperAdminRoute Component={SuperAdminScreen} path='/superAdmin' />
          <SuperAdminRoute
            Component={ImpersonateScreen}
            path='/impersonate/:userId/:locationId'
          />

          {/* All logged in users */}
          <AssignTaskScreen path='/assignTask/:teamId/:userId/:goalId' />
          <CreateAgendaItemScreen path='/createAgendaItem/:agendaId/:meetingId/:agendaItemId' />
          <CreateDistrictGoalScreen path='/createDistrictGoal/:goalId' />
          <CreateGoalScreen path='/createGoal/:goalId' />
          <CreateSchoolGoalScreen path='/createSchoolGoal/:goalId' />
          <CreateTeamScreen path='/createTeam/:groupId' />
          <CreateMeetingNotesTask path='/createMeetingsNotes/:meetingId/:teamId/:agendaItemId/:taskId' />
          <CreateMeetingScreen path='/createMeeting/:meetingId' />
          <CreateStafferScreen path='/createStaffer/:staffId' />
          <CreateStudentCommentScreen path='/createStudentComment/:groupId/:noteId/:commentId' />
          <CreateStudentNoteScreen path='/createStudentNote/:groupId/:studentId/:noteId' />
          <CreateStudentScreen path='/createStudent/:studentId' />
          <DashboardScreen path='/' />
          <DistrictGoalsScreen path='/districtGoals' />
          <EditTaskScreen path='/editTask/:taskId' />
          <GroupNormsScreen path='/groupNorms/:teamId' />
          <HelpScreen path='/help' />
          <LoginScreen path='/login' />
          <MeetingAgendaScreen path='/meetingAgenda/:meetingId' />
          <MeetingNotesScreen path='/meetingNotes/:agendaId/:meetingId' />
          <MeetingsScreen path='/meetings' />
          <MeetingTasksScreen path='/meetingTasks/:meetingId' />
          <MyAccountScreen path='/myAccount' />
          <MyTeamsScreen path='/myTeams' />
          <SchoolGoalsScreen path='/schoolGoals' />
          <StudentCommentsScreen path='/studentComments/:groupId/:noteId' />
          <StudentGroupsScreen path='/studentGroups/:groupId' />
          <StudentNotesScreen path='/studentNotes/:groupId/:userId' />
          <SuperAdminScreen path='/superAdmin' />
          <TaskNotesScreen path='/taskNotes/:taskId' />
          <TeamGoalsScreen path='/teamGoals' />
          <TeamTasksScreen path='/teamTasks/:goalId' />
          <ZoomIntegrationResponse path='/zoomIntegrationResponse/:zoomResponse' />
        </Router>
      </main>
    )
  }
}

export default withStyles(styles)(Main)
