import React from 'react'
import { Hidden, IconButton, TextField, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    //padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',

    width: 250,

    [theme.breakpoints.up('sm')]: {
      width: 300
    }
  },
  input: {
    marginLeft: theme.spacing(),
    flex: 1,

    '&::placeholder': {
      fontStyle: 'italic'
    }
  },
  iconButton: {
    padding: 10
  }
}))

const SearchField = ({ name, onChange, placeholder, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        InputProps={{ classes: { input: classes['input'] } }}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      <Hidden xsDown>
        <IconButton className={classes.iconButton} aria-label='Search'>
          <SearchIcon />
        </IconButton>
      </Hidden>
    </div>
  )
}

export default SearchField
