import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi, postDataApi } from '../services/api'

import { removeNotification, createNotificationObject } from '../helpers'
import { urls } from '../constants'

class GroupNormsStore {
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable isSavingData = false

  @observable displayOrder = 0
  @observable norm = ''
  @observable normId = 0
  @observable norms = []
  @observable teamId = ''
  @observable teamName = ''
  @observable teamNormsTitle = 'Team Norms'
  @observable allowedToEdit = false

  @observable groupNormsNotifications = []

  @action handleSaveNormsAfterReorderReponse = (data) => {
    this.groupNormsNotifications.push(
      createNotificationObject({
        key: data,
        message: 'Norms have been reordered',
        variant: 'success',
      })
    )

    this.isSavingData = false
  }

  deleteGroupNorm = (normId) => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.removeNormFromArray,
      itemId: normId,
      url: `${urls.DELETE_TEAM_NORM}${normId}`,
    })
  }

  getGroupNormsByTeamId = (teamId) => {
    this.isLoadingData = true

    this.teamId = teamId

    getDataApi({
      handleSuccess: this.getDataResponse,
      url: `${urls.GET_TEAM_NORMS_BY_TEAM_ID}${teamId}`,
    })
  }

  @action saveNorm = () => {
    this.isSavingData = true

    const displayOrder =
      this.displayOrder === 0 ? this.norms.length : this.displayOrder
    this.displayOrder = displayOrder

    const postObject = {
      DisplayOrder: displayOrder,
      Id: this.normId,
      TeamId: this.teamId,
      Norm: this.norm,
    }

    postDataApi({
      handleSuccess: this.handeSaveNormResponse,
      postObject,
      url: urls.SAVE_TEAM_NORM,
    })
  }

  saveNormTitle = () => {
    const postObject = {
      Id: this.teamId,
      NormTitle: this.teamNormsTitle,
    }

    postDataApi({
      handleSuccess: this.handleSaveNormTitleResponse,
      postObject,
      url: urls.SAVE_TEAM_NORM_TITLE,
    })
  }

  @action editNorm = (id) => {
    const item = this.norms.find((item) => item.Id === id)

    this.displayOrder = item.DisplayOrder
    this.norm = item.Norm
    this.normId = item.Id
  }

  @action getDataResponse = (data) => {
    this.norms = data.Norms
    this.teamId = data.TeamId
    this.teamName = data.TeamName
    this.allowedToEdit = data.AllowedToEdit
    if (data.TeamNormsTitle) {
      this.teamNormsTitle = data.TeamNormsTitle
    } else {
      this.teamNormsTitle = 'Team Norms'
    }

    this.isLoadingData = false
  }

  @action handleInputChange = (e) => {
    this[e.target.name] = e.target.value
  }

  @action handeSaveNormResponse = (data) => {
    this.removeNormFromArray(data)

    this.norms.push({
      DisplayOrder: this.displayOrder,
      Id: data,
      IdOfUserMakingChange: '00000000-0000-0000-0000-000000000000',
      Norm: this.norm,
      TeamId: this.teamId,
    })

    this.displayOrder = null
    this.norm = ''
    this.normId = 0
    this.isSavingData = false

    this.groupNormsNotifications.push({
      key: data,
      message: 'Team Norm has been saved.',
      options: {
        variant: 'success',
      },
    })
  }

  @action handleSaveNormTitleResponse = (data) => {
    this.groupNormsNotifications.push({
      key: data,
      message: 'Team Norm Title has been saved.',
      options: {
        variant: 'success',
      },
    })
  }

  @action moveItem = (result) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    let array = this.norms.slice()
    const [removed] = array.splice(sourceIndex, 1)
    array.splice(destinationIndex, 0, removed)

    this.norms = array
  }

  @action saveNormsAfterReorder = () => {
    this.isSavingData = true

    let array = []
    this.norms.forEach((norm, index) =>
      array.push({
        NewDisplayOrder: index,
        TeamNormId: norm.Id,
      })
    )

    postDataApi({
      handleSuccess: this.handleSaveNormsAfterReorderReponse,
      postObject: { ReorderItems: array },
      url: `${urls.SAVE_TEAM_NORMS_REORDER}`,
    })
  }

  @action removeNormFromArray = (id) => {
    this.norms = this.norms.filter((item) => item.Id !== id)

    this.groupNormsNotifications.push({
      key: id,
      message: 'Team Norm has been deleted.',
      options: {
        variant: 'success',
      },
    })

    this.isDeletingData = false
  }

  @action removeSnackbar = (keyValue) => {
    this.groupNormsNotifications = removeNotification({
      array: this.groupNormsNotifications,
      keyValue,
    })
  }
}

export default new GroupNormsStore()
