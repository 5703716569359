import React, { Fragment } from 'react'
import ListItem from './ListItem'
import { EmptyArrayMessage } from '../common'

const ListOfUsers = ({ data, deleteItem, emptyArrayText }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map(item => (
            <ListItem
              key={item.Id ? item.Id : item.StudentUserId}
              deleteItem={deleteItem}
              details={item}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text={emptyArrayText} />
      )}
    </Fragment>
  )
}

export default ListOfUsers
