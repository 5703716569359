import { action, observable } from 'mobx'
import { getDataApi, deleteDataApi, postDataApi } from '../services/api'
import { urls } from '../constants'

class Store {
  @observable isDeletingData = false
  @observable isLoading = true
  @observable listOfGoals = []

  deleteGoal = goalId => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteGoalResponse,
      itemId: goalId,
      url: `${urls.DELETE_GOAL_BY_GOAL_ID}${goalId}`
    })
  }

  markGoalAsCompleted = goalId => {
    this.isLoading = true
    postDataApi({
      handleSuccess: this.handleMarkGoalAsCompletedResponse,
      url: `${urls.MARK_GOAL_AS_COMPLETED}${goalId}`
    })
  }

  @action getData = () => {
    this.isLoading = true

    getDataApi({
      handleSuccess: this.handleGetGroupGoalsResponse,
      url: urls.GET_MY_TEAM_GOALS
    })
  }

  @action handleDeleteGoalResponse = goalId => {
    this.listOfGoals = this.listOfGoals.filter(item => item.GoalId !== goalId)
    this.isDeletingData = false
  }

  @action handleGetGroupGoalsResponse = data => {
    this.listOfGoals = data
    this.isLoading = false
  }

  @action handleMarkGoalAsCompletedResponse = goalId => {
    const index = this.listOfGoals.findIndex(g => g.GoalId === goalId)
    this.listOfGoals[index].AllowedToMarkGoalAsCompleted = false
    this.isLoading = false
  }
}

export default new Store()
