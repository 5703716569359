import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { TaskList } from '../Tasks'

@inject('GroupTasksStore')
@observer
class CompletedTasks extends Component {
  componentDidMount() {
    this.props.GroupTasksStore.getCompletedTasks(this.props.goalId)
  }
  render() {
    const {
      completedTasks,
      markGroupGoalAsInProgress,
    } = this.props.GroupTasksStore
    return (
      <TaskList data={completedTasks} markTask={markGroupGoalAsInProgress} />
    )
  }
}

export default CompletedTasks
