import React from 'react'
import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core'

import { DeleteModal, MenuButton, UserAvatar } from '../../common'
import { primaryColor } from '../../../constants'
import { getLocalDateFromUTC } from '../../../helpers'

const useStyles = makeStyles((theme) => ({
  detail: {
    display: 'flex',
  },
  details: {
    borderBottom: '1px solid #d9d9d9',
    paddingBottom: theme.spacing(),
  },
  detailTitle: {
    color: primaryColor,
    textTransform: 'uppercase',
  },
  root: {
    marginTop: theme.spacing(2),
  },
}))

const NotesItem = ({
  comment,
  commentDate,
  createdBy,
  createdByFirstName,
  createdByLastName,
  deleteItem,
  edit,
  id,
  index,
}) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Grid
        item
        sm={1}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <UserAvatar
          index={index}
          name={createdByFirstName.charAt(0) + createdByLastName.charAt(0)}
        />
      </Grid>
      <Grid item sm={11}>
        <Grid container>
          <Grid item sm={12}>
            <Grid className={classes.details} container>
              <Grid className={classes.detail} item sm={4}>
                <Typography className={classes.detailTitle}>
                  Posted By:
                </Typography>
                <Typography>&nbsp;{createdBy}</Typography>
              </Grid>
              <Grid className={classes.detail} item sm={6}>
                <Typography className={classes.detailTitle}>
                  Posted On:
                </Typography>
                <Typography>
                  &nbsp;{getLocalDateFromUTC(commentDate)}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <MenuButton>
                  <MenuItem onClick={() => edit(id)}>Edit</MenuItem>
                  <DeleteModal
                    confirmDeleteCallback={() => deleteItem(id)}
                    confirmDeleteLabel='Delete Note'
                    confirmDeleteMessage='Are you sure you want to delete this note?'
                    itemName={`Task Note posted by: ${createdBy} on ${getLocalDateFromUTC(
                      commentDate
                    )}`}
                    menuItemText='Delete'
                    useMenuItem
                  />
                </MenuButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Typography
              dangerouslySetInnerHTML={{ __html: comment }}></Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotesItem
