import React, { Fragment } from 'react'
import TeamItem from './TeamItem'
import { EmptyArrayMessage } from '../common'

const List = ({ data, deleteTeam, deleteTeamMember, getTeamDetails }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map(item => (
            <TeamItem
              key={item.Id}
              deleteTeam={deleteTeam}
              deleteTeamMember={deleteTeamMember}
              getTeamDetails={getTeamDetails}
              id={item.Id}
              name={item.Name}
              usersInTeam={item.UsersInTeam}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no teams to display' />
      )}
    </Fragment>
  )
}

export default List
