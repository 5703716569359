import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: 250,
    fontFamily: 'Roboto'
  }
})

const TextAreaFormField = ({
  dataTestId,
  field,
  form,
  placeholder,
  type,
  ...props
}) => {
  const classes = useStyles()

  return (
    <TextareaAutosize
      className={classes.root}
      data-testid={dataTestId}
      rows={10}
      rowsMax={20}
      placeholder={placeholder}
      {...field}
      {...props}
    />
  )
}

export default TextAreaFormField
