import { action, observable } from 'mobx'
import { getDataApi, deleteDataApi } from '../services/api'
import { urls } from '../constants'
import { sortArrayByFieldName } from '../helpers/compare'
import { createNotificationObject, removeNotification } from '../helpers'

class MeetingsStore {
  @observable historicalMeetings = []
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable meetingAgenda = {}
  @observable teamMembers = []
  @observable upcomingMeetings = []
  @observable upcomingMeetingsNotifications = []

  deleteMeeting = (meetingId) => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteMeetingResponse,
      itemId: meetingId,
      url: `${urls.DELETE_MEETING_BY_MEETING_ID}${meetingId}`,
    })
  }

  @action getHistoricalMeetings = () => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetHistoricalMeetingsResponse,
      url: urls.GET_LIST_OF_HISTORICAL_MEETINGS,
    })
  }

  getUpcomingMeetings() {
    getDataApi({
      handleSuccess: this.handleUpcomingMeetingsResponse,
      url: urls.GET_MY_UPCOMING_MEETINGS,
    })
  }

  @action handleDeleteMeetingResponse = (meetingId) => {
    this.upcomingMeetings = this.upcomingMeetings.filter(
      (item) => item.MeetingId !== meetingId
    )

    this.upcomingMeetingsNotifications.push(
      createNotificationObject({
        key: meetingId,
        message: 'Meeting has been deleted',
        variant: 'success',
      })
    )

    this.isDeletingData = false
  }

  @action handleGetHistoricalMeetingsResponse = (data) => {
    this.historicalMeetings = data

    this.isLoadingData = false
  }

  @action handleUpcomingMeetingsResponse = (data) => {
    this.upcomingMeetings = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'StartDateTime',
    })
    this.isLoadingData = false
  }

  @action removeSnackbar = (keyValue) => {
    this.upcomingMeetingsNotifications = removeNotification({
      array: this.upcomingMeetingsNotifications,
      keyValue,
    })
  }

  @action sortUpcomingMeetings = (field, ascValue) => {
    let array = this.upcomingMeetings.slice()
    sortArrayByFieldName({ array, ascValue, field })

    this.upcomingMeetings = array
  }
}

export default new MeetingsStore()
