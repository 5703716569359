import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { withStyles } from '@material-ui/core'

import './Editor.css'

const styles = () => ({
  container: {
    marginBottom: 10,
  },
})

const Editor = ({ classes, data, onChange }) => {
  return (
    <div className={classes.container}>
      <ReactQuill
        className='test'
        value={data}
        onChange={onChange}
        bounds={'.quill'}
        placeholder='Write something...'></ReactQuill>
    </div>
  )
}

export default withStyles(styles)(Editor)
