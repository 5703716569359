import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  datePicker: {
    width: 250,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}))

const EsDatePicker = ({ label, onChange, value }) => {
  const classes = useStyles()
  return (
    <DatePicker
      className={classes.datePicker}
      label={label}
      onChange={onChange}
      value={value}
    />
  )
}

export default EsDatePicker
