import React, { Fragment } from 'react'
import TeamMemberItem from './TeamMemberItem'

const TeamMembersList = ({
  data,
  deleteTeamMember,
  index,
  isLoggedInUserAdminOrTeamLead,
  teamId,
}) => {
  const getTeamLeadCount = () => {
    let count = 0
    data.forEach((member) => member.IsTeamLead && count++)

    return count
  }
  return (
    <Fragment>
      {data.map((member, memberIndex) => (
        <TeamMemberItem
          key={member.UserId}
          deleteTeamMember={deleteTeamMember}
          email={member.EmailAddress}
          isSelected={member.IsSelected}
          isTeamLead={member.IsTeamLead}
          firstName={member.FirstName}
          lastName={member.LastName}
          index={memberIndex + 1}
          isLoggedInUserAdminOrTeamLead={isLoggedInUserAdminOrTeamLead}
          teamId={teamId}
          teamIndex={index}
          teamLeadCount={getTeamLeadCount()}
          userId={member.UserId}
          userTeamId={member.UserTeamId}
        />
      ))}
    </Fragment>
  )
}

export default TeamMembersList
