import React, { Fragment } from 'react'
import { AutoSizer, List } from 'react-virtualized'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Checkbox, EmptyArrayMessage, Subheading, UserAvatar } from '../common'
import { getFirstNameLastNameInitials } from '../../helpers'

const rowHeight = 50

const useStyles = makeStyles(theme => ({
  autosizerWrapper: {
    [theme.breakpoints.only('xs')]: {
      height: '100vh'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
      height: 'calc(100vh - 450px)'
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 400px)'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing()
  }
}))

const VirtualizedList = ({
  addAllUsersToGroupByUserTypeId,
  addUserToGroup,
  data,
  noDataText,
  subheadingText,
  userTypeId
}) => {
  const classes = useStyles()

  const renderRow = ({ index, style }) => {
    const id = data[index].Id
    const firstName = data[index].FirstName
    const lastName = data[index].LastName
    return (
      <Grid container className={classes.row} key={id} style={style}>
        <Checkbox onChange={() => addUserToGroup(id)} />
        <UserAvatar
          index={index}
          name={getFirstNameLastNameInitials(firstName, lastName)}
        />
        <Typography>
          {lastName}, {firstName}
        </Typography>
      </Grid>
    )
  }

  return (
    <div>
      {data.length > 0 ? (
        <Fragment>
          <Subheading>
            <Grid item sm={1}>
              <Checkbox
                onChange={() => addAllUsersToGroupByUserTypeId(userTypeId)}
              />
            </Grid>
            <Grid item sm={4}>
              <Typography>{subheadingText}</Typography>
            </Grid>
          </Subheading>
          <div className={classes.autosizerWrapper}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <List
                    width={width}
                    height={height}
                    rowHeight={rowHeight}
                    rowRenderer={renderRow}
                    rowCount={data.length}
                    overscanRowCount={5}
                  />
                )
              }}
            </AutoSizer>
          </div>
        </Fragment>
      ) : (
        <EmptyArrayMessage text={noDataText} />
      )}
    </div>
  )
}

export default VirtualizedList
