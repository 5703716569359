import React, { Fragment } from 'react'
import { EmptyArrayMessage } from '../common'
import TaskItem from './TaskItem'

const List = ({ data, markTask }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map(item => (
            <TaskItem key={item.TaskId} details={item} markTask={markTask} />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no tasks to display.' />
      )}
    </Fragment>
  )
}

export default List
