import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('HeaderStore')
@observer
class Help extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Help')
  }

  render() {
    return <div>Help</div>
  }
}

export default Help
