import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { MenuItem, withStyles } from '@material-ui/core'
import { Button, Loading, Menu, SectionTitle, TextField } from '../common'
import { ListOfGroupNorms } from '../GroupNorms'

const styles = (theme) => ({
  teamInfo: {
    display: 'flex',
    alignItems: 'center',
  },
})

@inject('HeaderStore', 'GroupNormsStore')
@observer
class MyGroups extends Component {
  state = {
    displayTitleEditor: false,
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Team Norms')
    this.props.GroupNormsStore.getGroupNormsByTeamId(this.props.teamId)
  }

  handleSaveClick = () => {
    this.toggleDisplayTitleEditor()

    this.props.GroupNormsStore.saveNormTitle()
  }

  toggleDisplayTitleEditor = () => {
    this.setState({
      displayTitleEditor: !this.state.displayTitleEditor,
    })
  }

  onDragEnd = (result) => {
    const { destination, source } = result

    if (!destination) {
      // dropped outside the list
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      //item hasn't moved
      return
    }

    const moveNorm = async () => {
      this.props.GroupNormsStore.moveItem(result)
    }

    moveNorm().then(() => this.props.GroupNormsStore.saveNormsAfterReorder())
  }

  render() {
    const { classes } = this.props
    const {
      allowedToEdit,
      deleteGroupNorm,
      editNorm,
      handleInputChange,
      isDeletingData,
      isLoadingData,
      isSavingData,
      norm,
      norms,
      saveNorm,
      teamNormsTitle,
    } = this.props.GroupNormsStore

    if (isDeletingData)
      return (
        <Loading dataTestId='deleting-component' text='Deleting Team Norm' />
      )
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Team Norms' />
      )
    if (isSavingData)
      return <Loading dataTestId='saving-component' text='Saving New Norm' />

    return (
      <>
        {allowedToEdit ? (
          <div data-testid='component-container'>
            <span className={classes.teamInfo}>
              {this.state.displayTitleEditor ? (
                <Fragment>
                  <TextField
                    dataTestId='team-norm-title-input'
                    label='Team Norm Title'
                    name='teamNormsTitle'
                    onChange={handleInputChange}
                    value={teamNormsTitle}
                  />
                  <Button
                    dataTestId='save-norm-title-button'
                    label='Save Norm Title'
                    onClick={() => this.handleSaveClick()}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <SectionTitle text={teamNormsTitle} />
                  <Menu>
                    <MenuItem onClick={this.toggleDisplayTitleEditor}>
                      Edit Norm Name
                    </MenuItem>
                  </Menu>
                </Fragment>
              )}
            </span>
            <ListOfGroupNorms
              data={norms}
              deleteGroupNorm={deleteGroupNorm}
              editNorm={editNorm}
              onDragEnd={this.onDragEnd}
              allowedToEdit={allowedToEdit}
            />
            <div className={classes.teamInfo}>
              <TextField
                dataTestId='add-norm-input'
                label='Add Norm'
                name='norm'
                onChange={handleInputChange}
                value={norm}
              />
              <Button
                dataTestId='save-norm-button'
                label='Save Norm'
                onClick={saveNorm}
              />
            </div>
          </div>
        ) : (
          <div data-testid='component-container'>
            <SectionTitle text={teamNormsTitle} />
            <ListOfGroupNorms
              data={norms}
              deleteGroupNorm={deleteGroupNorm}
              editNorm={editNorm}
              onDragEnd={this.onDragEnd}
              allowedToEdit={allowedToEdit}
            />
          </div>
        )}
      </>
    )
  }
}

export default withStyles(styles)(MyGroups)
