import React, { Component } from 'react'
import { TablePagination, withStyles } from '@material-ui/core'

import PaginatedList from './PaginatedList'
import Paper from './Paper'
import SectionTitle from './SectionTitle'

import LoadingSm from './LoadingSm'
import Subheading from './Subheading'

const XS_FONT_SIZE = 12

const styles = theme => ({
  sectionTitle: { marginTop: theme.spacing(), marginLeft: theme.spacing() },
  caption: {
    [theme.breakpoints.only('xs')]: {
      fontSize: XS_FONT_SIZE
    }
  },
  input: {
    [theme.breakpoints.only('xs')]: {
      fontSize: XS_FONT_SIZE
    }
  }
})

class SectionContainer extends Component {
  state = {
    page: 0,
    rowsPerPage: 10
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    this.setState({
      rowsPerPage: +e.target.value,
      page: 0
    })
  }

  render() {
    const {
      classes,
      data,
      deleteItem,
      isLoadingData,
      loadingText,
      ListItem,
      noDataMessage,
      sectionTitle,
      SubheadingItems,
      ...rest
    } = this.props
    const { page, rowsPerPage } = this.state

    return (
      <Paper data-test='component-container'>
        <div className={classes.sectionTitle}>
          <SectionTitle text={sectionTitle} />
        </div>
        <div>
          <Subheading>
            <SubheadingItems />
          </Subheading>
          <div>
            {isLoadingData ? (
              <LoadingSm text={loadingText} />
            ) : (
              <PaginatedList
                data={data}
                deleteItem={deleteItem}
                page={page}
                ListItem={ListItem}
                noDataMessage={noDataMessage}
                rowsPerPage={rowsPerPage}
                {...rest}
              />
            )}
          </div>
        </div>
        <TablePagination
          classes={{
            caption: classes.caption,
            input: classes.input
          }}
          rowsPerPageOptions={[10, 20, 30]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(SectionContainer)
