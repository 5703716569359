import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Grid } from '@material-ui/core'
import { SubmitButton, TextFormField } from '../common'

const schema = yup.object({
  itemLetterAbbreviation: yup
    .string()
    .required('Item letter abbreviation is required')
    .max(50, 'Item abbreviation cannot exceed 50 characters'),
  itemName: yup
    .string()
    .required('Item name is required')
    .max(50, 'Item name cannot exceed 50 characters')
})

const CreateAgendaItem = ({ itemName, itemLetterAbbreviation, save }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        itemName,
        itemLetterAbbreviation
      }}
      onSubmit={values => save(values)}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={schema}>
      <Form>
        <Grid container direction='column'>
          <Field
            dataTestId='agenda-item-name'
            label='Item Name'
            name='itemName'
            component={TextFormField}
          />
          <Field
            dataTestId='agenda-item-letter-abbreviation'
            label='Item Letter Abbreviation'
            name='itemLetterAbbreviation'
            component={TextFormField}
          />
          <SubmitButton label='Save' />
        </Grid>
      </Form>
    </Formik>
  )
}

export default CreateAgendaItem
