import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 300,

    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const DatePickerFormField = ({
  dataTestId,
  fieldValue,
  label,
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  const classes = useStyles()

  return (
    <DatePicker
      className={classes.datePicker}
      inputProps={{ 'data-testid': `${dataTestId}` }}
      name={name}
      label={label}
      onChange={(value) => {
        setFieldValue(fieldValue, value)
      }}
      value={value}
    />
  )
}

export default DatePickerFormField
