import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Menu, MenuLink, DeleteModal } from '../common'

const SchoolGoalItem = ({ deleteSchoolGoal, goalId, goalName }) => {
  return (
    <Grid data-testid='manage-school-goal' container key={goalId}>
      <Grid item>
        <Typography>{goalName}</Typography>
      </Grid>
      <Grid item>
        <Menu>
          <DeleteModal
            confirmDeleteCallback={() => deleteSchoolGoal(goalId)}
            confirmDeleteLabel='Delete School Goal'
            confirmDeleteMessage='Are you sure you want to delete this school goal?'
            itemName={goalName}
            menuItemText='Delete School Goal'
            useMenuItem
          />
          <MenuLink
            label='Edit School Goal'
            to={`/createSchoolGoal/${goalId}`}
          />
        </Menu>
      </Grid>
    </Grid>
  )
}

export default SchoolGoalItem
