import React from 'react'
import { EmptyArrayMessage } from '../common'
import DistrictGoalItem from './DistrictGoalItem'

const ListOfDistrictGoals = ({ data, deleteDistrictGoal }) => {
  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map(item => (
            <DistrictGoalItem
              key={item.GoalId}
              deleteDistrictGoal={deleteDistrictGoal}
              goalId={item.GoalId}
              goalName={item.GoalName}
            />
          ))}
        </>
      ) : (
        <EmptyArrayMessage text='There are no district goals to display' />
      )}
    </>
  )
}
export default ListOfDistrictGoals
