import { action, computed, observable } from 'mobx'
import { navigate } from '@reach/router'
import {
  createNotificationObject,
  removeNotification,
  sortArrayByLastNameFirstName,
} from '../helpers'
import { getDataApi, postDataApi } from '../services/api'
import { urls } from '../constants'

import { getLoggedInUserIdFromLS } from '../helpers'

class Store {
  @observable createGroupNotifications = []
  @observable filterValue = ''
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable teamId = ''
  @observable teamMembers = []
  @observable teamName = ''
  @observable users = []

  getData(groupId) {
    this.isLoadingData = true

    this.teamId = groupId

    getDataApi({
      handleSuccess: this.handleGetStaffersResponse,
      url: urls.GET_ALL_USERS,
    })

    if (groupId !== '0') {
      // Use seperate API call to pull existing group data
      getDataApi({
        handleSuccess: this.handleGetTeamResponse,
        url: `${urls.GET_TEAMS_WITH_USERS_BY_ID}${groupId}`,
      })
    }
  }

  saveGroup = () => {
    this.isSavingData = true

    let array = []

    this.teamMembers.forEach((user) =>
      array.push({
        UserId: user.UserId,
        UserRoles: user.IsTeamLead
          ? [
              {
                Id: user.UserRoles.length > 0 ? user.UserRoles[0].Id : 0,
                RoleId: 1,
              },
            ]
          : [{ Id: user.UserRoles.length > 0 ? user.UserRoles[0].Id : 0 }],
        UserTeamId: user.UserTeamId,
      })
    )

    const postObject = {
      TeamId: this.teamId === '0' ? null : this.teamId,
      TeamName: this.teamName,
      UsersInTeam: array,
    }

    postDataApi({
      handleSuccess: this.handleSaveGroupResponse,
      postObject,
      url: urls.CREATE_TEAM_WITH_USERS,
    })
  }

  @computed get partnerList() {
    return this.filteredStaffers.filter((item) => item.UserTypeId === 3)
  }

  @computed get stafferList() {
    return this.filteredStaffers.filter((item) => item.UserTypeId === 1)
  }

  @computed get studentList() {
    return this.filteredStaffers.filter((item) => item.UserTypeId === 2)
  }

  @computed get partnersInTeam() {
    return this.teamMembers.filter((item) => item.UserTypeId === 3)
  }

  @computed get staffersInTeam() {
    return this.teamMembers.filter((item) => item.UserTypeId === 1)
  }

  @computed get studentsInTeam() {
    return this.teamMembers.filter((item) => item.UserTypeId === 2)
  }

  @computed get filteredStaffers() {
    if (this.filterValue === '') {
      return this.usersNotInTeam
    }

    let lowerCaseFilter = this.filterValue.toLowerCase()

    return this.usersNotInTeam.filter((p) => {
      return (
        p.FirstName.toLowerCase().includes(lowerCaseFilter) ||
        p.LastName.toLowerCase().includes(lowerCaseFilter)
      )
    })
  }

  @computed get usersNotInTeam() {
    let teamMemberIds = []

    this.teamMembers.forEach((teamMember) =>
      teamMemberIds.push(teamMember.UserId)
    )

    const filtered = this.users.filter(function (item) {
      return teamMemberIds.indexOf(item.Id) === -1
    })

    return filtered
  }

  @action addAllUsersToGroupByUserTypeId = (userTypeId) => {
    this.filteredStaffers.forEach((user) => {
      user.UserTypeId === userTypeId &&
        this.teamMembers.push({
          EmailAddress: user.EmailAddress,
          FirstName: user.FirstName,
          IsTeamLead: false,
          LastName: user.LastName,
          UserId: user.Id,
          UserRoles: [],
          UserTeamId: this.teamId,
          UserTypeId: user.UserTypeId,
        })
    })
  }

  @action addUserToGroup = (userId) => {
    const user = this.users.find((item) => item.Id === userId)

    this.teamMembers.push({
      EmailAddress: user.EmailAddress,
      FirstName: user.FirstName,
      IsTeamLead: false,
      LastName: user.LastName,
      UserId: user.Id,
      UserRoles: [],
      UserTeamId: this.teamId,
      UserTypeId: user.UserTypeId,
    })
  }

  @action clearFilter = () => {
    this.filterValue = ''
  }

  @action handleGetStaffersResponse = (data) => {
    this.users = sortArrayByLastNameFirstName(data)

    if (this.teamId === '0') {
      const USER_ID = localStorage.getItem('LoggedInUserId')
      const loggedOnUser = this.teamMembers.find(
        (member) => member.UserId === USER_ID
      )

      if (loggedOnUser === undefined) {
        this.addUserToGroup(USER_ID)
        this.handleTeamLeadChange(USER_ID, { target: { checked: true } })
      }
    }

    this.isLoadingData = false
  }

  @action handleGetTeamResponse = (data) => {
    this.teamId = data.TeamId

    this.teamMembers = data.TeamMembers
    this.teamName = data.TeamName

    this.isLoadingData = false
  }

  @action handleInputChange = (e) => {
    this[e.target.name] = e.target.value
  }

  @action handleSaveGroupResponse = (data) => {
    // If the logged in user is a team lead of the
    this.teamMembers.forEach((member) => {
      if (member.UserId === getLoggedInUserIdFromLS()) {
        if (member.IsTeamLead) {
          console.log(member)
          localStorage.setItem('IsAnyTeamLead', true)
        }
      }
    })

    const notificationObject = createNotificationObject({
      key: data,
      message: 'A new team has been created.',
      variant: 'success',
    })

    this.createGroupNotifications.push(notificationObject)

    this.teamName = ''
    this.removeAllStaffersFromTeam()

    this.isSavingData = false

    navigate('/myTeams')
  }

  @action handleTeamLeadChange = (userId, e) => {
    let array = this.teamMembers.slice()
    const user = array.find((item) => item.UserId === userId)

    user.IsTeamLead = e.target.checked

    this.teamMembers = array
  }

  @action removeAllStaffersFromTeam = () => {
    const ids = []

    this.teamMembers.forEach((item) => {
      ids.push(item.UserId)
    })

    ids.forEach((id) => this.removeStafferFromTeam(id))
  }

  @action removeSnackbar = (keyValue) => {
    this.createGroupNotifications = removeNotification({
      array: this.createGroupNotifications,
      keyValue,
    })
  }

  @action removeStafferFromTeam = (userId) => {
    this.teamMembers.replace(
      this.teamMembers.filter((item) => item.UserId !== userId)
    )
  }

  @action toggleIsLoading = () => {
    this.isLoadingData = !this.isLoadingData
  }
}

export default new Store()
