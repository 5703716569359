import React from 'react'
import { Icon } from '@material-ui/core'
import { FormatListBulleted, FormatListNumbered } from '@material-ui/icons'

import StyleButton from './StyleButton'

const H1Option = () => {
  return <Icon style={{ fontSize: 22 }}>H1</Icon>
}

const BLOCK_TYPES = [
  { label: <H1Option />, style: 'header-one' },
  { label: <FormatListBulleted />, style: 'unordered-list-item' },
  { label: <FormatListNumbered />, style: 'ordered-list-item' },
]

const BlockStyleControls = (props) => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  return (
    <div className='RichEditor-controls'>
      {BLOCK_TYPES.map((type, i) => (
        <StyleButton
          key={i}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

export default BlockStyleControls
