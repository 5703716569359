import React, { useEffect, useState } from 'react' //Component,
import { inject, observer } from 'mobx-react'
import { Typography, makeStyles } from '@material-ui/core'

import { Button, Drawer, Loading, SectionTitle, SelectField } from '../common'
import { setupMenuItems } from '../../helpers'

import { CreateMeetingForm } from '../CreateMeeting'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  moveButton: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  title: {},
}))

const Test = inject(
  'HeaderStore',
  'CreateMeetingStore',
  'MeetingsStore'
)(
  observer((props) => {
    const [state, setState] = useState({
      createNewMeeting: false,
      selectedMeetingId: '',
    })

    const classes = useStyles()

    const {
      endTime,
      getNoteTakerInfo,
      groups,
      isSavingData,
      meetingDate,
      meetingName,
      roomLocation,
      selectedGroup,
      selectedNoteTaker,
      startTime,
      teamMembers,
    } = props.CreateMeetingStore

    const { upcomingMeetings } = props.MeetingsStore

    useEffect(() => {
      props.CreateMeetingStore.getData('0')
      props.MeetingsStore.getUpcomingMeetings()
    }, [])

    const displayCreateNewMeeting = () => {
      setState({ ...state, createNewMeeting: true })
    }

    const saveExistingMeetingWithAgendaItems = () => {
      props.CreateMeetingStore.saveExistingMeetingWithAgendaItems(
        state.selectedMeetingId,
        props.agendaItemsToMove
      )
    }

    const saveNewMeetingWithAgendaItems = (formValues) => {
      props.CreateMeetingStore.saveMeetingWithAgendaItems(
        formValues,
        props.agendaItemsToMove
      )
    }

    const updateSelectedMeeting = (e) => {
      setState({ ...state, selectedMeetingId: e.target.value })
    }

    if (isSavingData) return <Loading text='Creating New Meeting' />

    const renderDrawerContent = () => {
      return (
        <div className={classes.container}>
          <SectionTitle text='Move Agenda Items' />
          {state.createNewMeeting ? (
            <CreateMeetingForm
              endTime={endTime}
              getNoteTakerInfo={getNoteTakerInfo}
              groups={groups}
              handleSubmit={saveNewMeetingWithAgendaItems}
              meetingDate={meetingDate}
              meetingName={meetingName}
              roomLocation={roomLocation}
              selectedGroup={selectedGroup}
              selectedNoteTaker={selectedNoteTaker}
              startTime={startTime}
              teamMembers={teamMembers}
            />
          ) : (
            <div>
              <div className={classes.selectContainer}>
                <SelectField
                  label='Select Upcoming Meeting'
                  menuItems={setupMenuItems({
                    data: upcomingMeetings,
                    descriptionField: 'MeetingName',
                    valueField: 'MeetingId',
                  })}
                  onChange={updateSelectedMeeting}
                  selectedValue={state.selectedMeetingId}
                />
                <Button
                  disabled={state.selectedMeetingId === ''}
                  label='Move Agenda Items'
                  onClick={saveExistingMeetingWithAgendaItems}
                  overwriteClass={classes.moveButton}
                />
              </div>
              <Typography className={classes.text}>OR</Typography>
              <Button
                label='Create New Meeting'
                onClick={displayCreateNewMeeting}
              />
            </div>
          )}
        </div>
      )
    }

    return (
      <div>
        <Drawer
          anchor='right'
          content={renderDrawerContent()}
          label='Move Agenda Items'
          useButton
        />
      </div>
    )
  })
)

export default Test
