import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { withStyles } from '@material-ui/core'
import {
  CheckboxFormField,
  SubmitButton,
  TextFormField,
  Loading,
} from '../common'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  hiddenTextfield: {
    marginTop: 0,
  },
}

@inject('HeaderStore', 'CreateStafferStore')
@observer
class CreateStaffer extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create Staffer')
    this.props.CreateStafferStore.getData(this.props.staffId)
  }

  render() {
    const { classes } = this.props
    const {
      emailAddress,
      firstName,
      isActive,
      isAdmin,
      isLoadingData,
      isSavingData,
      lastName,
      save,
      staffId,
      validateEmailAddress,
      validateStaffId,
    } = this.props.CreateStafferStore

    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Staff Info' />
      )
    if (isSavingData)
      return <Loading dataTestId='saving-component' text='Saving Staff Info' />

    const schema = yup.object({
      Active: yup.bool(),
      Admin: yup.bool(),
      ConfirmPassword: yup.string().when('GrantWebAccess', {
        is: true,
        then: yup
          .string()
          .required('Confirm Password is required for granting web access')
          .oneOf([yup.ref('Password'), null], 'Passwords must match'),
        otherwise: yup.string().min(0),
      }),
      EmailAccountInformation: yup.boolean(),
      EmailAddress: yup
        .string()
        .required('Email Address is required')
        .email('Email Address must be an email address')
        .test('unique-email', 'Email must be unique', async (value) => {
          const response = await validateEmailAddress(value)
          return response
        }),
      FirstName: yup
        .string()
        .required('First Name is required')
        .min(2, 'First Name must be at least 2 characters'),
      GrantWebAccess: yup.boolean(),
      LastName: yup
        .string()
        .required('Last Name is required')
        .min(2, 'Last Name must be at least 2 characters'),
      Password: yup.string().when('GrantWebAccess', {
        is: true,
        then: yup
          .string()
          .required('Password is required for granting web access')
          .min(6, 'Password must be at least 6 characters in length'),
        otherwise: yup.string().min(0),
      }),
      StaffId: yup
        .number()
        .required('Staff Id is required')
        .min(1)
        .test(
          'unique-staff-id',
          'This Staff Id is associated with another user',
          async (value) => {
            const response = await validateStaffId(value)
            return response
          }
        ),
    })

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Active: isActive,
          Admin: isAdmin,
          ConfirmPassword: '',
          EmailAccountInformation: false,
          EmailAddress: emailAddress,
          FirstName: firstName,
          GrantWebAccess: false,
          LastName: lastName,
          Password: '',
          StaffId: staffId,
        }}
        onSubmit={(values) => save(values)}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={schema}>
        {(
          formProps //passing in the formProps to show/hide fields
        ) => (
          <Form className={classes.root} data-testid='create-staffer-form'>
            <Field
              dataTestId='first-name-input'
              label='First Name'
              name='FirstName'
              component={TextFormField}
            />
            <Field
              dataTestId='last-name-input'
              label='Last Name'
              name='LastName'
              component={TextFormField}
            />
            <Field
              dataTestId='email-address-input'
              label='Email Address'
              name='EmailAddress'
              component={TextFormField}
            />
            <Field
              dataTestId='staff-id-input'
              label='Staff Id'
              name='StaffId'
              component={TextFormField}
            />
            <Field
              dataTestId='admin-checkbox'
              label='Admin'
              name='Admin'
              component={CheckboxFormField}
            />
            <Field
              dataTestId='grant-web-access-checkbox'
              label='Grant Web Access'
              name='GrantWebAccess'
              component={CheckboxFormField}
            />
            {formProps.values.GrantWebAccess && (
              <Fragment>
                <Field
                  className={classes.hiddenTextfield}
                  dataTestId='password-input'
                  label='Password'
                  name='Password'
                  type='password'
                  component={TextFormField}
                />
                <Field
                  className={classes.hiddenTextfield}
                  dataTestId='confirm-password-input'
                  label='Confirm Password'
                  name='ConfirmPassword'
                  type='password'
                  component={TextFormField}
                />
                <Field
                  dataTestId='email-account-checkbox'
                  label='Email Account Information'
                  name='EmailAccountInformation'
                  component={CheckboxFormField}
                />
              </Fragment>
            )}
            <Field
              dataTestId='active-checkbox'
              label='Active'
              name='Active'
              component={CheckboxFormField}
            />
            <SubmitButton label='Save' />
          </Form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(CreateStaffer)
