import React from 'react'
import { TimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  timePicker: {
    width: 250,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})

const EsTimePicker = ({
  classes,
  fieldValue,
  form: { setFieldValue },
  field: { value },
  label,
  props
}) => {
  return (
    <div>
      <TimePicker
        className={classes.timePicker}
        label={label}
        onChange={value => {
          setFieldValue(fieldValue, value)
        }}
        value={value}
        {...props}
      />
    </div>
  )
}

export default withStyles(styles)(EsTimePicker)
