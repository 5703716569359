export const setupMenuItems = ({ data, descriptionField, valueField }) => {
  let array = []
  /* array.push({
    description: '(select)',
    value: -1
  }) */

  data.forEach(item =>
    array.push({
      description: item[descriptionField],
      value: item[valueField]
    })
  )

  return array
}
