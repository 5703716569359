import React, { useState, useEffect, useContext } from 'react'
import { ListOfGoals } from '../TeamGoals'
import axios from 'axios'
import { ROOT_URL } from '../../services/api'
import { HeaderContext } from '../layout/Container'

const SchoolGoals = () => {
  const { changeHeaderTitleViaContext } = useContext(HeaderContext)

  const [listOfGoals, setListOfGoals] = useState([])

  useEffect(() => {
    changeHeaderTitleViaContext('School Goals')

    axios
      .get(`${ROOT_URL}goal/getSchoolGoals`, { withCredentials: true })
      .then((response) => setListOfGoals(response.data))
      .catch((error) => console.log(error))
  }, [])

  return (
    <div data-testid='container'>
      <ListOfGoals data={listOfGoals} />
    </div>
  )
}

export default SchoolGoals
