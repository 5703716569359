import { action, observable } from 'mobx'
import moment from 'moment'
import { navigate } from '@reach/router'
import { getDataApi, postDataApi } from '../services/api'
import {
  createNotificationObject,
  removeNotification,
  setupMenuItems,
} from '../helpers'

import { urls } from '../constants'

class Store {
  @observable createGroupGoalNotifications = []
  @observable formattedGroups = []
  @observable goalDescription = ''
  @observable goalName = ''
  @observable goalTeamId = null
  @observable goalTeamGoalId = null
  @observable groups = []
  @observable goalId = '0'
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable selectedGroup = ''
  @observable targetDate = moment()

  getData(goalId) {
    this.isLoadingData = true

    if (goalId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetGoalInfoResponse,
        url: `${urls.GET_GOAL_BY_GOAL_ID}${goalId}`,
      })
    }

    getDataApi({
      handleSuccess: this.handleGetGroupsResponse,
      url: urls.GET_TEAM_FOR_LOGGED_IN_USER,
    })
  }

  @action save = (formValues) => {
    this.isSavingData = true
    const group = this.groups.find((x) => x.Id === formValues.selectedGroup)

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject: {
        Description: formValues.goalDescription,
        GoalTeam: {
          GoalId: this.goalTeamGoalId,
          Id: this.goalTeamId,
          TeamId: group.Id,
        },
        Id: this.goalId,
        TargetDate: moment(formValues.targetDate).format('YYYY-MM-DD'),
        Title: formValues.goalName,
      },
      url: urls.CREATE_GOAL,
    })
  }

  @action handleGetGoalInfoResponse = (data) => {
    this.goalId = data.Id
    this.goalDescription = data.Description
    this.goalName = data.Title
    this.goalTeamId = data.GoalTeam.Id
    this.goalTeamGoalId = data.GoalTeam.GoalId
    this.selectedGroup = data.GoalTeam.TeamId
    this.targetDate = data.TargetDate

    this.isLoadingData = false
  }

  @action handleGetGroupsResponse = (data) => {
    let array = setupMenuItems({
      data,
      descriptionField: 'Name',
      valueField: 'Id',
    })

    this.groups = data
    this.formattedGroups = array
    this.isLoadingData = false
  }

  @action handleSaveResponse = (data) => {
    this.goalId = '0'
    this.goalDescription = ''
    this.goalName = ''
    this.goalTeamId = null
    this.goalTeamGoalId = null
    this.selectedGroup = ''
    this.targetDate = moment()

    const notificationObject = createNotificationObject({
      key: data,
      message: 'A new group goal has been saved.',
      variant: 'success',
    })

    this.createGroupGoalNotifications.push(notificationObject)
    this.isSavingData = false
    navigate('/teamGoals')
  }

  @action removeSnackbar = (keyValue) => {
    this.createGroupGoalNotifications = removeNotification({
      array: this.createGroupGoalNotifications,
      keyValue,
    })
  }
}

export default new Store()
