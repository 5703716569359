export function compareAsc(a, b) {
  if (a.Subject < b.Subject) return -1
  return 0
}

export function compareDesc(a, b) {
  if (b.Subject < a.Subject) return -1
  return 0
}

export function sortBy(field, reverse, primer) {
  const key = primer
    ? function(x) {
        return primer(x[field])
      }
    : function(x) {
        return x[field]
      }

  reverse = !reverse ? 1 : -1

  return function(a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
  }
}

export const sortArrayByFieldName = ({ array, ascValue, field }) => {
  //constructor for objects that enable
  //language sensitive string comparison
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  if (ascValue) {
    return array.sort((a, b) => collator.compare(a[field], b[field]))
  } else {
    return array.sort((a, b) => collator.compare(b[field], a[field]))
  }
}

export const sortArrayByLastNameFirstName = array => {
  return array.sort(function(a, b) {
    if (a.LastName < b.LastName) {
      return -1
    }
    if (a.LastName > b.LastName) {
      return 1
    }
    if (a.FirstName < b.FirstName) {
      return -1
    }
    // if (a.FirstName > b.FirstName)
    return 1
  })
}
