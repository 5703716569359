import React, { Fragment } from 'react'
// import { Link } from '@reach/router'
import { Grid, MenuItem } from '@material-ui/core'
// import { Grid, MenuItem, makeStyles } from '@material-ui/core'
import { DeleteModal, Menu, SectionTitle, MenuLink } from '../common'
import ListOfTeamMembers from './ListOfTeamMembers'

// const useStyles = makeStyles({
//   link: {
//     textDecoration: 'none'
//   }
// })

// const MenuLink = ({ label, to }) => {
//   const classes = useStyles()
//   return (
//     <Link className={classes.link} to={to}>
//       <MenuItem>{label}</MenuItem>
//     </Link>
//   )
// }

const TeamItem = ({
  deleteTeam,
  deleteTeamMember,
  getTeamDetails,
  id,
  name,
  usersInTeam
}) => {
  return (
    <Fragment>
      <Grid container alignItems='center'>
        <SectionTitle text={name} />
        <Menu>
          <DeleteModal
            confirmDeleteCallback={() => deleteTeam(id)}
            confirmDeleteLabel='Delete Team'
            confirmDeleteMessage='Are you sure you want to delete this entire team?'
            itemName={name}
            menuItemText='Delete Team'
            useMenuItem
          />
          <MenuLink label='Edit Team' to={`/createTeam/${id}`} />
          <MenuLink label='Team Norms' to={`/groupNorms/${id}`} />
          <MenuLink label='View Students' to={`/studentGroups/${id}`} />
          <MenuItem onClick={() => getTeamDetails(id)}>View Team</MenuItem>
        </Menu>
      </Grid>
      <ListOfTeamMembers
        data={usersInTeam}
        deleteTeamMember={deleteTeamMember}
      />
    </Fragment>
  )
}

export default TeamItem
