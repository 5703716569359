import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import {
  DetailedPost,
  LinkButton,
  Loading,
  StudentInfoWithLink,
} from '../common'
import { ListOfComments } from '../CreateStudentComment'

const styles = {
  list: {
    marginLeft: 50,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

@inject('HeaderStore', 'StudentCommentsStore')
@observer
class StudentComments extends Component {
  componentDidMount() {
    this.props.StudentCommentsStore.getData(this.props.noteId)
    this.props.HeaderStore.handleTitleChange('Student Comments')
  }

  render() {
    const { classes } = this.props
    const {
      comments,
      deleteStudentComment,
      firstName,
      fullName,
      graduationYear,
      isDeletingData,
      isLoadingData,
      lastName,
      postedBy,
      postedOn,
      studentNote,
      userId,
    } = this.props.StudentCommentsStore

    if (isDeletingData) return <Loading text='Deleting Comment' />

    if (isLoadingData)
      return (
        <Loading
          dataTestId='loading-component'
          text='Loading Student Comments'
        />
      )

    return (
      <Fragment>
        <div className={classes.buttonContainer}>
          <LinkButton
            label='Add Student Comment'
            to={`/createStudentComment/${this.props.groupId}/${this.props.noteId}/0`}
          />
        </div>

        <StudentInfoWithLink
          firstName={firstName}
          fullName={fullName}
          graduationYear={graduationYear}
          lastName={lastName}
          linkLabel='Back to Student Notes'
          to={`/studentNotes/${this.props.groupId}/${userId}`}
        />
        <DetailedPost
          dangerouslySetInnerHtml
          postContent={studentNote}
          postedBy={postedBy}
          postedOn={postedOn}
        />
        <div className={classes.list}>
          <ListOfComments
            data={comments}
            deleteStudentComment={deleteStudentComment}
            groupId={this.props.groupId}
            noteId={this.props.noteId}
          />
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(StudentComments)
