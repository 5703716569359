import React, { Fragment } from 'react'
import TeamMemberItem from './TeamMemberItem'
import TeamSubheading from './TeamSubheading'

const ListOfTeamMembers = ({ data, deleteTeamMember }) => {
  if (!data) return null

  return (
    <Fragment>
      <TeamSubheading />
      {data.map((item, index) => (
        <TeamMemberItem
          key={item.Id}
          deleteTeamMember={deleteTeamMember}
          email={item.EmailAddress}
          index={index}
          isTeamLead={item.IsTeamLead}
          firstName={item.FirstName}
          lastName={item.LastName}
          userTeamId={item.Id}
        />
      ))}
    </Fragment>
  )
}

export default ListOfTeamMembers
