import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, withStyles } from '@material-ui/core'

import { LinkButton } from '../common'

import HistoricalMeetings from '../HistoricalMeetings/HistoricalMeetings'
import { UpcomingMeetings } from '../UpcomingMeetings'

const styles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',

    marginBottom: theme.spacing(),
  },
  tabContent: {
    marginTop: theme.spacing(),
  },
})

@inject('HeaderStore')
@observer
class Meetings extends Component {
  state = {
    selectedTabId: 0,
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Meetings')
  }

  handleTabChange = (e, value) => {
    this.setState({ selectedTabId: value })
  }

  render() {
    const { classes } = this.props
    const { isAdmin, isAnyTeamLead } = this.props.HeaderStore
    const { selectedTabId } = this.state

    const lsIsAnyTeamLead = localStorage.getItem('IsAnyTeamLead')

    const renderCreateNewMeetingButton = () => {
      if (isAdmin || isAnyTeamLead || lsIsAnyTeamLead === 'true') {
        return (
          <div className={classes.buttonContainer}>
            <LinkButton to='/createMeeting/0' label='Create New Meeting' />
          </div>
        )
      }
    }

    return (
      <div>
        {renderCreateNewMeetingButton()}
        <Tabs value={selectedTabId} onChange={this.handleTabChange}>
          <Tab label='Upcoming Meetings' />
          <Tab label='Historical Meetings' />
        </Tabs>
        {selectedTabId === 0 && (
          <div className={classes.tabContent}>
            <UpcomingMeetings />
          </div>
        )}
        {selectedTabId === 1 && (
          <div className={classes.tabContent}>
            <HistoricalMeetings />
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Meetings)
