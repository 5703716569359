import React from 'react'
import { Hidden } from '@material-ui/core'
import ListItemText from './ListItemText'

const HiddenLabel = ({ children }) => {
  return (
    <Hidden smUp>
      <ListItemText>{children}&nbsp;</ListItemText>
    </Hidden>
  )
}

export default HiddenLabel
