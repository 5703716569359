import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { InternalLink } from '../common'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2)
  }
}))

const InfoContainer = ({ description, linkLabel, to }) => {
  const classes = useStyles()
  return (
    <div className={classes.section} data-testid='info-container'>
      <InternalLink label={linkLabel} to={to} />
      <Typography data-testid='info-description'>{description}</Typography>
    </div>
  )
}

export default InfoContainer
