import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi, postDataApi } from '../services/api'
import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'

class TaskNotesStore {
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable isSavingData = false

  @observable assignedDate = ''
  @observable taskId = ''
  @observable taskNote = ''
  @observable taskNoteId = 0
  @observable taskNotes = ''
  @observable taskNotesNotifications = []
  @observable taskTitle = ''
  @observable teamName = ''

  deleteItem = taskNoteId => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteItemResponse,
      itemId: taskNoteId,
      url: `${urls.DELETE_TASK_NOTE}${taskNoteId}`
    })
  }

  getData = taskId => {
    this.taskId = taskId

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_TASK_NOTES_BY_TASK_ID}${taskId}`
    })
  }

  save = taskId => {
    this.isSavingData = true

    const postObject = {
      Id: this.taskNoteId,
      TaskId: this.taskId,
      Comment: this.taskNote
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_TASK_NOTE
    })
  }

  @action edit = taskNoteId => {
    this.taskNoteId = taskNoteId
    const note = this.taskNotes.find(item => item.Id === taskNoteId)
    this.taskNote = note.Comment
  }

  @action handleDeleteItemResponse = taskNoteId => {
    this.taskNotes = this.taskNotes.filter(item => item.Id !== taskNoteId)

    const notificationObject = createNotificationObject({
      key: taskNoteId,
      message: 'Task note has been deleted.',
      variant: 'success'
    })

    this.taskNotesNotifications.push(notificationObject)

    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.assignedDate = data.TaskAssignedDate
    this.taskId = data.TaskId
    this.taskNotes = data.TaskNotes
    this.taskTitle = data.TaskTitle
    this.teamName = data.TeamName

    this.isLoadingData = false
  }

  @action handleSaveResponse = data => {
    const notificationObject = createNotificationObject({
      key: data,
      message: 'Task notes have been saved.',
      variant: 'success'
    })

    this.taskNotesNotifications.push(notificationObject)
    this.taskNote = ''
    this.taskNoteId = 0

    this.getData(this.taskId)
    this.isSavingData = false
  }

  @action handleTaskNoteChange = value => {
    this.taskNote = value
  }

  @action removeSnackbar = keyValue => {
    this.taskNotesNotifications = removeNotification({
      array: this.taskNotesNotifications,
      keyValue
    })
  }
}

export default new TaskNotesStore()
