import React, { useEffect } from 'react'
import axios from 'axios'

import { ROOT_URL } from '../../services/api'

const Impersonate = (props) => {
  const { locationId, userId } = props

  useEffect(() => {
    axios
      .get(`${ROOT_URL}impersonate/${userId}/${locationId}`, {
        withCredentials: true,
      })
      .then((response) => console.log(response))
      .catch((error) => {
        console.log(error, 'error')
        console.log(error.response, 'errorResponse')
      })
  }, [])

  return <div>Impersonate</div>
}

export default Impersonate
