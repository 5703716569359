import { action, observable } from 'mobx'
import { navigate } from '@reach/router'
import {
  getDataApi,
  postDataApi,
  validateEmailAddressIsUnique,
  validateStaffIdIsUnique,
} from '../services/api'
import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'

class CreateStafferStore {
  @observable createStafferNotifications = []
  @observable emailAddress = ''
  @observable firstName = ''
  @observable id = '0'
  @observable isActive = false
  @observable isAdmin = false
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable lastName = ''
  @observable staffId = ''

  getData(staffId) {
    this.isLoadingData = true

    if (staffId !== '0') {
      this.id = staffId

      getDataApi({
        handleSuccess: this.handleGetDataResponse,
        url: `${urls.GET_USER_BY_USER_ID}${staffId}`,
      })
    } else {
      this.emailAddress = ''
      this.firstName = ''
      this.id = '0'
      this.isActive = false
      this.isAdmin = false
      this.lastName = ''
      this.staffId = ''

      this.isLoadingData = false
    }
  }

  validateEmailAddress = async (emailAddress) => {
    // no input
    if (emailAddress === undefined) {
      return false
    }

    // user has edited staffId and then changed back to original id
    if (emailAddress.toUpperCase() === this.emailAddress.toUpperCase()) {
      // staffId matches existings record is 'is unique'
      return true
    }

    // otherwise this is a brand new staffId that has info and we need to validate
    const response = await validateEmailAddressIsUnique(emailAddress)
    return response
  }

  validateStaffId = async (staffId) => {
    // no input
    if (staffId === undefined) {
      return false
    }

    // user has edited staffId and then changed back to original id
    if (staffId === parseInt(this.staffId)) {
      // staffId matches existings record is 'is unique'
      return true
    }

    // otherwise this is a brand new staffId that has info and we need to validate
    const response = await validateStaffIdIsUnique(staffId)
    return response
  }

  save = (form) => {
    this.isSavingData = true

    //copy values
    this.emailAddress = form.EmailAddress
    this.firstName = form.FirstName
    this.isActive = form.isActive
    this.isAdmin = form.IsAdmin
    this.lastName = form.LastName
    this.staffId = form.StaffId

    const postObject = {
      Active: form.Active,
      EmailAccountInformation: form.EmailAccountInformation,
      EmailAddress: form.EmailAddress,
      FirstName: form.FirstName,
      GrantWebAccess: form.GrantWebAccess,
      Id: this.id,
      IsAdmin: form.Admin,
      LastName: form.LastName,
      Password: form.Password,
      ThirdPartyId: form.StaffId,
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_USER,
    })
  }

  @action handleGetDataResponse = (data) => {
    this.isActive = data.Active
    this.emailAddress = data.EmailAddress
    this.firstName = data.FirstName
    this.id = data.Id
    this.isAdmin = data.IsAdmin
    this.lastName = data.LastName
    this.staffId = data.ThirdPartyId

    this.isLoadingData = false
  }

  @action handleSaveResponse = (id) => {
    const notification = createNotificationObject({
      key: id,
      message: 'Staff info has been saved.',
      variant: 'success',
    })

    this.createStafferNotifications.push(notification)
    this.isSavingData = false
    navigate('/manageStaff')
  }

  @action removeSnackbar = (keyValue) => {
    this.createStafferNotifications = removeNotification({
      array: this.createStudentNotifications,
      keyValue,
    })
  }
}

export default new CreateStafferStore()
