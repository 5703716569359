import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ListItemText } from '../common'
import { getLocalDateFromUTC } from '../../helpers'

const DetailedPost = ({
  dangerouslySetInnerHtml,
  postContent,
  postedBy,
  postedOn
}) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={2} lg={3}>
        <Typography style={{ fontSize: 13 }}>POSTED BY: {postedBy}</Typography>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Typography style={{ fontSize: 13 }}>
          POSTED ON: {getLocalDateFromUTC(postedOn)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {dangerouslySetInnerHtml ? (
          <Typography
            style={{ fontSize: 14 }}
            dangerouslySetInnerHTML={{ __html: postContent }}
          />
        ) : (
          <ListItemText>{postContent}</ListItemText>
        )}
      </Grid>
    </Grid>
  )
}

export default DetailedPost
