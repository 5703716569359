import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'

import zoomIcon from '../../images/zoomIcon.jpg'

import {
  CheckboxFormField,
  DatePickerFormField,
  SelectFormField,
  SubmitButton,
  TextFormField,
} from '../common'
import { urls } from '../../constants'
import { getDataApi } from '../../services/api'

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 64,
    width: 64,
    marginRight: theme.spacing(),
  },
  meetingInfo: {
    fontWeight: 'bold',
  },
  timePicker: {
    maxWidth: 300,

    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
  zoomContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  zoomIntergrationMessage: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const schema = yup.object({
  endTime: yup.date(),
  meetingDate: yup.date(),
  meetingName: yup.string().required('A meeting name is required'),
  roomLocation: yup.string(),
  startTime: yup.date(),
  selectedGroup: yup
    .string()
    .required()
    .notOneOf(['-1'], 'A team assignment is required'),
  selectedNoteTaker: yup.string(),
})

const CreateMeetingForm = ({
  endTime,
  getNoteTakerInfo,
  groups,
  handleSubmit,
  meetingDate,
  meetingName,
  roomLocation,
  selectedGroup,
  selectedNoteTaker,
  startTime,
  teamMembers,
  zoomJoinLink,
  zoomMeetingId,
  zoomMeetingPasscode,
}) => {
  const classes = useStyles()

  const [integratedWithZoom, setIntegratedWithZoom] = useState(false)
  const [gettingZoomStatus, setGettingZoomStatus] = useState(false)

  useEffect(() => {
    setGettingZoomStatus(true)

    getDataApi({
      handleSuccess: (data) => {
        setIntegratedWithZoom(data)
        setGettingZoomStatus(false)
      },

      url: urls.CHECK_ZOOM_INTEGRATION,
    })
  }, [])

  const handleEndTimeChange = (e, form) => {
    if (e < form.values.startTime) {
      form.setFieldValue('endTime', e)
      form.setFieldValue('startTime', e)
    } else {
      form.setFieldValue('endTime', e)
    }
  }

  const handleStartTimeChange = (e, form) => {
    if (e > form.values.endTime) {
      form.setFieldValue('endTime', e)
      form.setFieldValue('startTime', e)
    } else {
      form.setFieldValue('startTime', e)
    }
  }

  if (gettingZoomStatus) return null

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        endTime: moment(endTime),
        meetingDate,
        meetingName,
        removeZoomMeeting: false,
        roomLocation,
        startTime: moment(startTime),
        selectedGroup,
        selectedNoteTaker,
        setupZoomMeeting: false,
        zoomJoinLink,
        zoomMeetingId,
        zoomMeetingPasscode,
      }}
      onSubmit={(values) => handleSubmit(values)}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={schema}>
      {(
        formProps //passing in the formProps to show/hide fields
      ) => (
        <Form data-testid='create-staffer-form'>
          <Grid container direction='column'>
            <Field
              dataTestId='meeting-name-input'
              label='Meeting Name'
              name='meetingName'
              component={TextFormField}
            />
            <Field
              dataTestId='start-time-picker'
              fieldValue='startTime'
              component={({ field, form }) => (
                <TimePicker
                  className={classes.timePicker}
                  label='Start Time'
                  onChange={(e) => handleStartTimeChange(e, form)}
                  value={form.values.startTime}
                />
              )}
            />
            <Field
              dataTestId='end-time-picker'
              fieldValue='endTime'
              component={({ field, form }) => (
                <TimePicker
                  className={classes.timePicker}
                  label='End Time'
                  onChange={(e) => handleEndTimeChange(e, form)}
                  value={form.values.endTime}
                />
              )}
            />
            <Field
              fieldValue='meetingDate'
              label='Meeting Date'
              name='meetingDate'
              component={DatePickerFormField}
            />
            <Field
              dataTestId='team-assignment-input'
              fieldValue='selectedGroup'
              label='Team Assignment'
              menuItems={groups}
              name='selectedGroup'
              onChange={(e) => {
                formProps.setFieldValue('selectedGroup', e.target.value)
                getNoteTakerInfo(e.target.value)
              }}
              component={SelectFormField}
            />
            <Field
              dataTestId='room-location-input'
              label='Room Location'
              name='roomLocation'
              component={TextFormField}
            />
            <Field
              dataTestId='note-taker-input'
              label='Note Taker'
              menuItems={teamMembers}
              name='selectedNoteTaker'
              component={SelectFormField}
            />
            {integratedWithZoom && (
              <div className={classes.zoomContainer}>
                <div className={classes.zoomIntergrationMessage}>
                  <img
                    alt='Zoom Icon'
                    className={classes.icon}
                    src={zoomIcon}
                  />
                  <Typography>Zoom has been setup for your account!</Typography>
                </div>
                {zoomJoinLink !== null && zoomJoinLink !== undefined ? (
                  <div>
                    <Field
                      dataTestId='remove-zoom-meeting-checkbox'
                      label='Remove Zoom Meeting'
                      name='removeZoomMeeting'
                      component={CheckboxFormField}
                    />
                    <Typography>
                      <span className={classes.meetingInfo}>Link: </span>
                      <a href={zoomJoinLink} target='__blank'>
                        {zoomJoinLink}
                      </a>
                    </Typography>
                    <Typography>
                      <span className={classes.meetingInfo}>Meeting Id:</span>{' '}
                      {zoomMeetingId}
                    </Typography>
                    <Typography>
                      <span className={classes.meetingInfo}>Password:</span>{' '}
                      {zoomMeetingPasscode}
                    </Typography>
                  </div>
                ) : (
                  <Field
                    dataTestId='zoom-meeting-checkbox'
                    label='Setup Zoom Meeting'
                    name='setupZoomMeeting'
                    component={CheckboxFormField}
                  />
                )}
              </div>
            )}
            <SubmitButton label='Save Meeting' />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
/* 
  // have access to zoom?

*/

export default CreateMeetingForm
