export const taskStatusId = {
  Open: 1,
  Assigned: 2,
  Completed: 3
}

export const userTypeId = {
  Teacher: 1,
  Student: 2,
  Partner: 3
}
