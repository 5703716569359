import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  checkbox: {
    paddingLeft: 0
  },
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const LabeledCheckbox = ({ checked, label, name, onChange }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Checkbox
        checked={checked}
        className={classes.checkbox}
        name={name}
        onChange={onChange}
      />
      <Typography>{label}</Typography>
    </div>
  )
}

LabeledCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default LabeledCheckbox
