import { navTextColor } from './color'

export const navIcon = theme => ({
  color: navTextColor,

  [theme.breakpoints.up('md')]: {
    width: 81,

    display: 'flex',
    justifyContent: 'flex-end',

    marginRight: 10,
    paddingRight: 10
  }
})

export const navText = {
  color: navTextColor,
  fontSize: 18
}
