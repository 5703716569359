import { action, observable } from 'mobx'
import { getDataApi, postDataApi } from '../services/api'
import { urls } from '../constants'
import { createNotificationObject } from '../helpers'

class MeetingTasksStore {
  @observable isLoadingData = false
  @observable meetingTasksCompleted = []
  @observable meetingTasksRemaining = []
  @observable testObservable = 'This is from the store'

  @action getCompletedTasks = meetingId => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetCompletedTasksResponse,
      url: `${urls.GET_TASKS_COMPLETED_BY_MEETING_ID}${meetingId}`
    })
  }

  @action getRemainingTasks = meetingId => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetRemainingTasksResponse,
      url: `${urls.GET_TASKS_REMAINING_BY_MEETING_ID}${meetingId}`
    })
  }

  @action handleGetCompletedTasksResponse = data => {
    this.meetingTasksCompleted = data
    this.isLoadingData = false
  }

  @action handleGetRemainingTasksResponse = data => {
    this.meetingTasksRemaining = data

    this.isLoadingData = false
  }

  @action handleMarkTaskAsCompletedResponse = taskId => {
    this.meetingTasksRemaining = this.meetingTasksRemaining.filter(
      task => task.TaskId !== taskId
    )
  }

  @action handleMarkTaskAsInProgressResponse = taskId => {
    this.meetingTasksCompleted = this.meetingTasksCompleted.filter(
      task => task.TaskId !== taskId
    )
  }

  @action markTaskAsCompleted = taskId => {
    postDataApi({
      handleSuccess: this.handleMarkTaskAsCompletedResponse,
      url: `${urls.MARK_TASK_AS_COMPLETED}${taskId}`
    })
  }

  @action markTaskAsInProgress = taskId => {
    postDataApi({
      handleSuccess: this.handleMarkTaskAsInProgressResponse,
      url: `${urls.MARK_TASK_AS_IN_PROGRESS}${taskId}`
    })
  }


  // @action deleteTask = itemId => {
  //   this.isLoadingData = true

  //   getDataApi({
  //     handleSuccess: this.deleteTaskResponse,
  //     url: `${urls.DELETE_TASK_BY_TASK_ID}${itemId}`
  //   })
  // }

  // @action deleteTaskResponse = (taskId) => {
  //   this.tasks.replace(this.tasks.filter((task) => task.Id !== taskId))

  //   this.meetingNotesNotifications.push(
  //     createNotificationObject({
  //       key: taskId,
  //       message: 'Task has been deleted',
  //       variant: 'success',
  //     })
  //   )

  //   this.isDeletingData = false
  // }
}

export default new MeetingTasksStore()
