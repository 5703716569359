import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Typography, withStyles } from '@material-ui/core'
import { getFirstNameLastNameInitials } from '../../helpers'
import {
  InternalLink,
  LinkButton,
  Loading,
  SectionTitle,
  UserAvatar,
} from '../common'
import { ListOfStudentNotes, Subheading } from '../StudentNotes'

const styles = (theme) => ({
  internalLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
})

@inject('HeaderStore', 'StudentNotesStore')
@observer
class StudentsNotes extends Component {
  state = {
    sortByDatePosted: false,
    sortByPostedBy: false,
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Student Notes')
    this.props.StudentNotesStore.getData(this.props.userId)
  }

  handleSortBy = (stateValue, sortByValue) => {
    this.setState(
      {
        sortByDatePosted: false,
        sortByPostedBy: false,
        [stateValue]: !this.state[stateValue],
      },
      () => {
        this.props.StudentNotesStore.sortStudentNotes(
          sortByValue,
          this.state[stateValue]
        )
      }
    )
  }

  render() {
    const { classes, groupId } = this.props
    const {
      deleteItem,
      firstName,
      graduationYear,
      isDeletingData,
      isLoadingData,
      lastName,
      userId,
      userFullName,
      studentNotes,
    } = this.props.StudentNotesStore

    if (isDeletingData)
      return (
        <Loading dataTestId='deleting-component' text='Deleting Student Note' />
      )
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Student Notes' />
      )

    return (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar
              name={getFirstNameLastNameInitials(firstName, lastName)}
            />
            <div>
              <SectionTitle text={userFullName} />
              <Typography>Graduation Year: {graduationYear}</Typography>
            </div>
          </div>
          <LinkButton
            label='Create New Note'
            to={`/createStudentNote/${groupId}/${userId}/0`}
          />
        </div>
        <div className={classes.internalLinkContainer}>
          <InternalLink
            label='Back to the Student Groups'
            to={`/studentGroups/${groupId}`}
          />
        </div>
        <Subheading handleSortBy={this.handleSortBy} />
        <ListOfStudentNotes
          data={studentNotes}
          deleteItem={deleteItem}
          groupId={groupId}
          userId={this.props.userId}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(StudentsNotes)
