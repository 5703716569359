import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Subheading, TaskItem } from '../Tasks'
import { SectionContainer } from '../common'

@inject('MeetingTasksStore')
@observer
class InProgress extends Component {
  componentDidMount() {
    this.props.MeetingTasksStore.getRemainingTasks(this.props.meetingId)
  }

  render() {
    const {
      isLoadingData,
      markTaskAsCompleted,
      meetingTasksRemaining
    } = this.props.MeetingTasksStore
    return (
      <div>
        <SectionContainer
          data={meetingTasksRemaining}
          isLoadingData={isLoadingData}
          loadingText='Loading Active Tasks'
          ListItem={TaskItem}
          markTask={markTaskAsCompleted}
          noDataMessage='There are no tasks to display.'
          sectionTitle='Tasks'
          SubheadingItems={Subheading}
        />
      </div>
    )
  }
}

export default InProgress
