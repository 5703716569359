import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Container } from './components/layout'
import Notifier from './Notifier'

const App = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Notifier />
      <Container />
    </Fragment>
  )
}

export default withStyles({ withTheme: true })(App)
