import { action, observable } from 'mobx'
import { navigate } from '@reach/router'
import { getDataApi, postDataApi } from '../services/api'
import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'

class CreateStudentNoteStore {
  @observable createStudentNoteNotifications = []
  @observable graduationYear = ''
  @observable groupId = ''
  @observable firstName = ''
  @observable fullName = ''
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable lastName = ''
  @observable note = ''
  @observable title = ''
  @observable userId = ''

  getData(groupId, userId) {
    this.groupId = groupId
    this.userId = userId

    this.title = ''
    this.note = ''

    getDataApi({
      handleSuccess: this.handleGetUserResponse,
      url: `${urls.GET_USER_BY_USER_ID}${userId}`
    })
  }

  getStudentNote = noteId => {
    getDataApi({
      handleSuccess: this.handleGetStudentNoteResponse,
      url: `${urls.GET_STUDENT_NOTE_BY_NOTE_ID}${noteId}`
    })
  }

  save = noteId => {
    this.isSavingData = true

    const postObject = {
      id: noteId,
      Note: this.note,
      Title: this.title,
      UserId: this.userId
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_USER_NOTE
    })
  }

  @action handleGetUserResponse = data => {
    this.graduationYear = data.UserStudent.GraduationYear
    this.firstName = data.FirstName
    this.fullName = data.FullName
    this.lastName = data.LastName

    this.isLoadingData = false
  }

  @action handleGetStudentNoteResponse = data => {
    this.note = data.UserNote
    this.title = data.UserNoteTitle
  }

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value
  }

  @action handleNoteChange = value => {
    this.note = value
  }

  @action handleSaveResponse = data => {
    const notificationObject = createNotificationObject({
      key: data,
      message: 'Student Note has been saved.',
      variant: 'success'
    })

    this.note = ''
    this.title = ''

    this.createStudentNoteNotifications.push(notificationObject)
    this.isSavingData = false

    navigate(`/studentNotes/${this.groupId}/${this.userId}`)
  }

  @action removeSnackbar = keyValue => {
    this.createStudentNoteNotifications = removeNotification({
      array: this.createStudentNoteNotifications,
      keyValue
    })
  }
}

export default new CreateStudentNoteStore()
