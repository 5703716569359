import React, { useRef } from 'react'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy'
import moment from 'moment'
import { Grid, MenuItem, makeStyles } from '@material-ui/core'
import Check from '@material-ui/icons/Check'

import {
  Chip,
  HiddenLabel,
  ListItemText,
  MenuButton,
  MenuLink,
} from '../common'
import { flexRow, secondaryColor, subheadingColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  chipAssigned: {
    width: 84,
  },
  chipInProgress: {
    color: secondaryColor,
    border: `1px solid ${secondaryColor}`,
    width: 84,
  },
  chipOpen: {
    color: '#293984',
    border: `1px solid #293984`,
    width: 84,
  },
  icon: {
    marginRight: theme.spacing(),
    cursor: 'pointer',

    '&:hover': {
      color: secondaryColor,
    },
  },
  item: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  root: {
    alignItems: 'center',

    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),

    borderBottom: `1px solid ${subheadingColor}`,

    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    ...flexRow,
  },
  toDoItem: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      borderBottom: 'none',
    },

    display: 'flex',
    alignItems: 'center',
  },
}))

const TaskAssignmentItem = ({
  details,
  markTaskAsCompleted,
  markTaskAsInProgress,
  deleteTask,
}) => {
  const classes = useStyles()

  const childRef = useRef()

  // run the onClick and close the menu via the childRef
  const handleMenuOnClick = (onClick) => {
    onClick()
    childRef.current.closeMenuFromParent()
  }

  const {
    AssignedDate,
    AssociatedGoal,
    TaskId,
    TaskStatus,
    TaskStatusId,
    TaskTitle,
    TeamName,
  } = details

  return (
    <Grid container className={classes.root} data-testid='task-item'>
      <Grid xs={12} sm={2} item className={classes.toDoItem}>
        <HiddenLabel>To Do Item:</HiddenLabel>
        <Tooltip
          title={`Click to mark ${TaskTitle} as complete`}
          position='right'
          trigger='mouseenter'>
          <Check
            className={classes.icon}
            onClick={() => markTaskAsCompleted(TaskId)}
          />
        </Tooltip>
        <ListItemText overwriteClass={classes.title}>{TaskTitle}</ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item className={classes.item}>
        <HiddenLabel>Assigned:</HiddenLabel>
        <ListItemText>{moment(AssignedDate).format('MM/DD/YYYY')}</ListItemText>
      </Grid>
      <Grid xs={12} sm={3} item className={classes.item}>
        <HiddenLabel>Team:</HiddenLabel>
        <ListItemText>{TeamName}</ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item className={classes.item}>
        <HiddenLabel>Status:</HiddenLabel>
        <Chip
          label={TaskStatus}
          className={
            TaskStatus === 'Open'
              ? classes.chipOpen
              : TaskStatus === 'Assigned'
              ? classes.chipAssigned
              : classes.chipInProgress
          }
        />
      </Grid>
      <Grid xs={12} sm={1} item className={classes.item}>
        <HiddenLabel>Goal:</HiddenLabel>
        <ListItemText>{AssociatedGoal}</ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item>
        <MenuButton ref={childRef}>
          {TaskStatusId === 4 && (
            <MenuItem
              onClick={() =>
                handleMenuOnClick(() => markTaskAsCompleted(TaskId))
              }>
              Close
            </MenuItem>
          )}
          {TaskStatusId === 2 && (
            <MenuItem
              onClick={() =>
                handleMenuOnClick(() => markTaskAsInProgress(TaskId))
              }>
              Start
            </MenuItem>
          )}
          <MenuLink label='Add Notes' to={`/taskNotes/${TaskId}`} />
          <MenuLink label='Edit' to={`/editTask/${TaskId}`} />
          <MenuItem
              onClick={() =>
                handleMenuOnClick(() => deleteTask(TaskId))
              }>
              Delete
            </MenuItem>
        </MenuButton>
      </Grid>
    </Grid>
  )
}

export default TaskAssignmentItem
