import React from 'react'
import { Link } from '@reach/router'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import Message from '@material-ui/icons/Message'

import { DeleteModal, HiddenLabel, InternalLink, ListItemText } from '../common'
import { subheadingColor } from '../../constants'
import { getLocalDateFromUTC } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    padding: theme.spacing(),

    borderBottom: `1px solid ${subheadingColor}`,
  },
  commentLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '&:visited': {
      color: 'blue',
    },
  },
  commentCount: {
    color: '#b2b2b2',
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  note: {
    fontSize: 14,
  },
}))

const NoteItem = ({
  commentCount,
  datePosted,
  deleteItem,
  id,
  groupId,
  note,
  postedBy,
  title,
  userId,
}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.root}
      //spacing={1}
      data-testid='student-note'>
      <Grid className={classes.item} item xs={12} sm={4}>
        <HiddenLabel>Note:</HiddenLabel>
        <InternalLink
          dataTestId='note-title'
          italicize
          label={title}
          to={`/createStudentNote/${groupId}/${userId}/${id}`}
        />
      </Grid>
      <Grid item sm={2}>
        <Link
          className={classes.commentLink}
          to={`/studentComments/${groupId}/${id}`}>
          <Message className={classes.commentCount} />
          <ListItemText className={classes.commentCount}>
            {commentCount} Comments
          </ListItemText>
        </Link>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={2}>
        <HiddenLabel>Date Posted:</HiddenLabel>
        <ListItemText>{getLocalDateFromUTC(datePosted)}</ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={2}>
        <HiddenLabel>Posted By:</HiddenLabel>
        <ListItemText>{postedBy}</ListItemText>
      </Grid>
      <Grid item sm={2} className={classes.item}>
        <DeleteModal
          buttonText='Delete Note'
          confirmDeleteCallback={() => deleteItem(id)}
          confirmDeleteLabel='Delete Student Note'
          confirmDeleteMessage='Are you sure you want to delete this note?'
          itemName={title}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={classes.note}
          dangerouslySetInnerHTML={{ __html: note }}></Typography>
      </Grid>
    </Grid>
  )
}

export default NoteItem
