import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Grid, withStyles } from '@material-ui/core'

import {
  Chip,
  DeleteModal,
  HiddenLabel,
  InternalLink,
  ListItemText,
  MenuButton,
  MenuLink,
} from '../common'
import { primaryColor, secondaryColor, subheadingColor } from '../../constants'
import { getLocalDateTimeFromUTC, getLocalTimeFromUTC } from '../../helpers'

const styles = (theme) => ({
  item: {
    display: 'flex',

    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  link: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',

      paddingRight: theme.spacing(),
    },
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  notPostedChip: {
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
  },
  postedChip: {
    color: secondaryColor,
    border: `1px solid ${secondaryColor}`,
  },
  root: {
    alignItems: 'center',

    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),

    borderBottom: `1px solid ${subheadingColor}`,

    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
})

@inject('HeaderStore')
@observer
class MeetingItem extends Component {
  render() {
    const { classes, deleteItem, details } = this.props
    const { isAdmin } = this.props.HeaderStore
    const {
      EndDateTime,
      FlattenedTeams,
      IsAgendaPosted,
      IsUserTeamLead,
      MeetingId,
      Location,
      MeetingName,
      StartDateTime,
      TotalTasks,
      TasksCompleted,
    } = details

    const now = moment()

    const renderAgendaLink = () => {
      const agendaLink = (
        <MenuLink label='Agenda' to={`/meetingAgenda/${MeetingId}`} />
      )

      if (isAdmin || IsAgendaPosted || IsUserTeamLead) {
        return agendaLink
      } else if (moment(StartDateTime >= now)) {
        return agendaLink
      } else {
        return null
      }
    }

    const renderEditDeleteMenuItems = () => {
      const menuItems = (
        <div>
          <MenuLink label='Edit' to={`/createMeeting/${MeetingId}`} />
          <DeleteModal
            menuItemText='Cancel'
            confirmDeleteCallback={() => deleteItem(MeetingId)}
            confirmDeleteLabel='Cancel Meeting'
            confirmDeleteMessage='Are you sure you want to cancel this meeting?'
            itemName={MeetingName}
            useMenuItem
          />
        </div>
      )

      if (IsUserTeamLead || isAdmin) {
        return menuItems
      } else return null
    }

    // TODO: resolve browser warning regarding formatting
    const shouldRenderActionsButton = () => {
      const convertedTime = moment(getLocalDateTimeFromUTC(StartDateTime))
      const formattedTime = moment(convertedTime).format()

      if (isAdmin) {
        return true
      } else if (IsUserTeamLead) {
        return true
      } else if (IsAgendaPosted) {
        return true
      } else if (formattedTime <= moment().format()) {
        return true
      } else {
        return false
      }
    }

    return (
      <Fragment>
        <Grid container className={classes.root}>
          <Grid className={classes.item} item xs={12} sm={2}>
            <HiddenLabel>Meeting Name:</HiddenLabel>
            <ListItemText>{MeetingName}</ListItemText>
          </Grid>
          <Grid className={classes.item} item xs={12} sm={2} lg={1}>
            <HiddenLabel>Date:</HiddenLabel>
            <ListItemText>
              {moment(StartDateTime).format('MM/DD/YYYY')}
            </ListItemText>
          </Grid>
          <Grid className={classes.item} item xs={12} sm={2}>
            <HiddenLabel>Time:</HiddenLabel>
            <ListItemText>
              {getLocalTimeFromUTC(StartDateTime)} -{' '}
              {getLocalTimeFromUTC(EndDateTime)}
            </ListItemText>
          </Grid>
          <Grid className={classes.item} item xs={12} sm={3}>
            <HiddenLabel>Team:</HiddenLabel>
            <ListItemText>{FlattenedTeams}</ListItemText>
          </Grid>
          <Grid className={classes.item} item xs={12} sm={1}>
            <HiddenLabel>Location:</HiddenLabel>
            <ListItemText>{Location}</ListItemText>
          </Grid>
          <Grid item xs={12} sm={1}>
            {shouldRenderActionsButton() && (
              <MenuButton>
                {renderAgendaLink()}
                {renderEditDeleteMenuItems()}
              </MenuButton>
            )}
          </Grid>
          <Grid item xs={12} sm={1}>
            {IsAgendaPosted ? (
              <Chip className={classes.postedChip} label='Agenda Posted' />
            ) : (
              <Chip
                className={classes.notPostedChip}
                label='Agenda Not Posted'
              />
            )}
          </Grid>
          {moment(now).isAfter(StartDateTime) && (
            <Grid className={classes.link} item xs={12}>
              <InternalLink
                label={`Tasks Completed: ${TasksCompleted} Total Tasks: ${TotalTasks}`}
                to={`/meetingTasks/${MeetingId}`}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(MeetingItem)
