import React from 'react'
import moment from 'moment'
import { Link } from '@reach/router'
import PieChart from 'react-minimal-pie-chart'
import { Grid, MenuItem, Typography, withStyles } from '@material-ui/core'

import { DeleteModal, MenuButton, MenuLink, Paper } from '../common'
import { graphColors } from '../../constants'

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  infoContainer: {
    padding: theme.spacing(),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  itemLink: {
    color: 'blue',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  pieChart: {
    height: 125,
    width: 125,

    marginLeft: theme.spacing(),
  },
  even: {
    backgroundColor: '#eee',
  },
})

const GoalItem = ({
  classes,
  deleteGoal,
  goalDescription,
  goalId,
  goalPercentageCompleted,
  goalName,
  index,
  memberCountWithAssignedTasks,
  readOnly,
  allowedToMarkGoalAsCompleted,
  markGoalAsCompleted,
  targetDate,
  tasksRemaining,
  teamName,
}) => {
  const calculateColorByIndex = () => {
    if (index % 4 === 0) {
      return graphColors[1]
    } else if (index % 3 === 0) {
      return graphColors[3]
    } else if (index % 2 === 0) {
      return graphColors[0]
    } else {
      return graphColors[2]
    }
  }

  const generateBgClassByIndex = () => {
    if (index % 2 === 0) {
      return null
    } else {
      return classes.even
    }
  }

  return (
    <Paper overwrite={generateBgClassByIndex()}>
      <Grid
        data-testid='team-goal'
        container
        className={classes.container}
        key={goalId}>
        {!readOnly && (
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <PieChart
              className={classes.pieChart}
              data={[
                {
                  value: Math.floor(goalPercentageCompleted),
                  color: calculateColorByIndex(),
                },
              ]}
              totalValue={100}
              lineWidth={20}
              label={({ data, dataIndex }) =>
                Math.round(data[dataIndex].percentage) + '%'
              }
              labelStyle={{
                fontSize: '25px',
                fontFamily: 'Roboto',
              }}
              labelPosition={0}
              animate
            />
          </Grid>
        )}
        <Grid item xs={12} sm={7} className={classes.infoContainer}>
          {!readOnly && <Typography>{`Team > ${teamName}`}</Typography>}
          <Grid container className={classes.itemRow}>
            <Grid item xs={12} sm={4}>
              <Typography data-testid='goal-name' variant='h6'>
                {goalName}
              </Typography>
            </Grid>
            <Typography>
              Target Date: {moment(targetDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
          <Typography paragraph>{goalDescription}</Typography>
          {!readOnly && (
            <Link className={classes.itemLink} to={`/teamTasks/${goalId}`}>
              <Typography
                className={
                  classes.itemLink
                }>{`Remaining Tasks: ${tasksRemaining} - Assigned to ${memberCountWithAssignedTasks} Team Members`}</Typography>
            </Link>
          )}
        </Grid>
        <Grid item xs={12} sm={1}>
          {!readOnly && (
            <MenuButton>
              <div>
                {allowedToMarkGoalAsCompleted && (
                  <MenuItem onClick={() => markGoalAsCompleted(goalId)}>
                    Complete
                  </MenuItem>
                )}
                <DeleteModal
                  confirmDeleteCallback={() => deleteGoal(goalId)}
                  confirmDeleteLabel='Delete Goal'
                  confirmDeleteMessage='Are you sure you want to delete this goal?'
                  itemName={goalName}
                  menuItemText='Delete'
                  useMenuItem
                />
                <MenuLink label='Edit' to={`/createGoal/${goalId}`} />
              </div>
            </MenuButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(GoalItem)
