import axios from 'axios'
import { LoginStore } from '../stores'

//export const ROOT_URL = 'https://enrichingteachers.azurewebsites.net/v1.0/'
//export const ROOT_URL = 'https://etdev.azurewebsites.net/v1.0/'
//export const ROOT_URL = 'http://localhost:52332//v1.0/'

export const ROOT_URL = process.env.REACT_APP_ROOT_URL

export const etAuthToken = () => {
  return {
    headers: {
      ETAuthToken: localStorage.getItem('ETAuthToken'),
    },
  }
}

const handleError = (error) => {
  console.log(error)
  if (error.response.status === 401) {
    LoginStore.handleBadTokenLogout()
    localStorage.clear()
  }
}

export const deleteDataApi = ({ handleSuccess, itemId, url }) => {
  axios
    .delete(`${ROOT_URL}${url}`, { withCredentials: true })
    .then(() => handleSuccess(itemId))
    .catch((error) => handleError(error))
}

export const getDataApi = ({ handleSuccess, url }) => {
  axios
    .get(`${ROOT_URL}${url}`, { withCredentials: true })
    .then((response) => handleSuccess(response.data))
    .catch((error) => handleError(error))
}

export const postDataApi = ({ handleSuccess, postObject, url }) => {
  axios
    .post(`${ROOT_URL}${url}`, postObject, { withCredentials: true })
    .then((response) => handleSuccess(response.data))
    .catch((error) => handleError(error))
}

export const login = ({ onSuccess, loginObject }) => {
  axios
    .post(`${ROOT_URL}usermembership/login`, loginObject, {
      withCredentials: true,
    })
    .then((response) => onSuccess(response.data))
    .catch((error) => handleError(error))
}

export const validateEmailAddressIsUnique = async (emailAddress) => {
  const response = await axios.get(
    `${ROOT_URL}/user/isUserEMailUnique?id=${emailAddress}`,
    { withCredentials: true }
  )

  return response.data
}

export const validateStaffIdIsUnique = async (staffId) => {
  const response = await axios.get(
    `${ROOT_URL}user/isTeacherSISUnique/${staffId}`,
    { withCredentials: true }
  )

  return response.data
}

export const validateStudentSisIsUnique = async (sisId) => {
  const response = await axios.get(
    `${ROOT_URL}user/isStudentSISUnique/${sisId}`,
    { withCredentials: true }
  )

  return response.data
}
