import React, { Fragment } from 'react'
import moment from 'moment'
import { Typography, withStyles } from '@material-ui/core'
import { getLocalTimeFromUTC } from '../../helpers'

const styles = (theme) => ({
  detailItem: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  details: {
    display: 'flex',

    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },

    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
})

const TitleDateTimeLocation = ({
  classes,
  endTime,
  location,
  noteTaker,
  renderMenu,
  startTime,
  title,
  useMenu,
}) => {
  return (
    <Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h5'>{title}</Typography>
        {useMenu && renderMenu()}
      </div>
      <div className={classes.details}>
        <Typography>
          <b>Date:</b> {moment(startTime).format('MM/DD/YYYY')}
        </Typography>
        <Typography className={classes.detailItem}>
          <b>Time: </b>
          {getLocalTimeFromUTC(startTime)} - {getLocalTimeFromUTC(endTime)}
        </Typography>
        <Typography className={classes.detailItem}>
          <b>Location:</b> {location}
        </Typography>
        <Typography className={classes.detailItem}>
          <b>Note Taker:</b> {noteTaker}
        </Typography>
      </div>
    </Fragment>
  )
}

export default withStyles(styles)(TitleDateTimeLocation)
