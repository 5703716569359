import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { withStyles } from '@material-ui/core'
import {
  DatePickerFormField,
  Loading,
  SelectFormField,
  SubmitButton,
  TextFormField,
} from '../common'

const schema = yup.object({
  AssignedTo: yup
    .string()
    .required()
    .notOneOf(['-1'], 'Please selected an assignee'),
  DueDate: yup.date(),
  SelectedGoal: yup.string(),
  Title: yup.string().required('Todo Item is required'),
})

const styles = (theme) => ({
  buttonContainer: { marginTop: theme.spacing() },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
})

@inject('HeaderStore', 'CreateTaskStore')
@observer
class CreateGoal extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create Task')
    this.props.CreateTaskStore.getData(
      this.props.agendaItemId,
      this.props.meetingId,
      this.props.taskId,
      this.props.teamId
    )
  }

  handleSave = () => {
    this.props.CreateTaskStore.save(this.props.agendaItemId)
  }

  render() {
    const { classes } = this.props
    const {
      dueDate,
      goals,
      isLoadingData,
      isSavingData,
      save,
      selectedGoal,
      selectedTeamMember,
      teamMembers,
      todo,
    } = this.props.CreateTaskStore

    if (isLoadingData) return <Loading text='Loading Task Info' />
    if (isSavingData) return <Loading text='Creating New Task' />

    return (
      <Formik
        validationSchema={schema}
        enableReinitialize={true}
        initialValues={{
          AssignedTo: selectedTeamMember,
          DueDate: dueDate,
          SelectedGoal: selectedGoal,
          Title: todo,
        }}
        onSubmit={(values) => save(values)}>
        <Form className={classes.form}>
          <Field
            label='Todo Item'
            name='Title'
            component={TextFormField}
            dataTestId='todo-item'
          />
          <Field
            label='Assign To'
            menuItems={teamMembers}
            name='AssignedTo'
            component={SelectFormField}
            dataTestId='assign-to'
          />
          <Field
            label='Goal'
            menuItems={goals}
            name='SelectedGoal'
            component={SelectFormField}
            dataTestId='goals'
          />
          <Field
            fieldValue='DueDate'
            label='Date Due'
            name='DueDate'
            component={DatePickerFormField}
          />
          <div className={classes.buttonContainer}>
            <SubmitButton label='Save' />
          </div>
        </Form>
      </Formik>
    )
  }
}

export default withStyles(styles)(CreateGoal)
