import React from 'react'
import { Chip, Grid, MenuItem, withStyles } from '@material-ui/core'
import { DeleteModal, ListItemText, Menu, UserAvatar } from '../common'
import { primaryColor, subheadingColor } from '../../constants'
import { getFirstNameLastNameInitials } from '../../helpers'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    borderBottom: `1px solid ${subheadingColor}`,
    padding: theme.spacing()
  },
  chip: {
    fontSize: 11,
    height: 22,
    color: primaryColor,
    border: `1px solid ${primaryColor}`
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing()
    }
  },
  teamMember: {
    display: 'flex',
    alignItems: 'center'
  }
})

const TeamMemberItem = ({
  classes,
  deleteTeamMember,
  email,
  index,
  isTeamLead,
  firstName,
  lastName,
  userTeamId
}) => {
  return (
    <Grid className={classes.container} container>
      <Grid item sm={1} className={classes.item}>
        <UserAvatar
          index={index}
          name={getFirstNameLastNameInitials(firstName, lastName)}
        />
      </Grid>
      <Grid item sm={3} className={classes.item}>
        <span className={classes.teamMember}>
          <ListItemText>{`${firstName} ${lastName}`}</ListItemText>
          <Menu>
            <MenuItem>Assign Task</MenuItem>
            <DeleteModal
              confirmDeleteCallback={() => deleteTeamMember(userTeamId)}
              confirmDeleteLabel='Remove from Team'
              confirmDeleteMessage='Are you sure you want to remove this user from team?'
              itemName={`${firstName} ${lastName}`}
              menuItemText='Remove From Team'
              useMenuItem
            />
          </Menu>
        </span>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.item}>
        {isTeamLead && (
          <Chip label='Team Lead' className={classes.chip} variant='outlined' />
        )}
      </Grid>
      <Grid item xs={12} sm={2} className={classes.item}>
        <a href={`mailto: ${email}`}>
          <ListItemText>{email}</ListItemText>
        </a>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TeamMemberItem)
