import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, MenuItem, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import {
  Button,
  LinkButton,
  Loading,
  Menu,
  MenuButton,
  MenuLink,
  Subheading,
  SubheadingItemText,
  TitleDateTimeLocation,
} from '../common'

import { AgendaModal, List, MoveAgendaItemDrawer } from '../MeetingAgenda'
import PostedAgenda from './PostedAgenda'

const styles = (theme) => ({
  addAgendaContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: theme.spacing(),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: 'blue',
    marginRight: theme.spacing(),

    '&:visited': {
      color: 'blue',
    },
  },
  moveAgendaItemControls: {
    display: 'flex',
  },
  postAgendaButtonContainer: {
    marginRight: theme.spacing(),
  },
})

@inject('CreateMeetingStore', 'HeaderStore', 'MeetingAgendaStore')
@observer
class MeetingAgenda extends Component {
  state = {
    agendaItemsToMove: [],
    movingAgendaItems: false,
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Meeting Agenda')
    this.props.MeetingAgendaStore.getData(this.props.meetingId)
    this.props.MeetingAgendaStore.resetHasPostedData()
  }

  onDragEnd = (result) => {
    const { destination, source } = result

    if (!destination) {
      // dropped outside the list
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      //item hasn't moved
      return
    }

    const moveNorm = async () => {
      this.props.MeetingAgendaStore.moveItem(result)
    }

    moveNorm().then(() =>
      this.props.MeetingAgendaStore.saveAgendaItemsAfterReorder()
    )
  }

  cancelMoveAgendaItems = () => {
    this.setState({ ...this.state, movingAgendaItems: false })
  }

  moveAgendaItem = (e, agendaItemId) => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        agendaItemsToMove: [...this.state.agendaItemsToMove, agendaItemId],
      })
    } else {
      this.setState({
        ...this.state,
        agendaItemsToMove: this.state.agendaItemsToMove.filter(
          (item) => item !== agendaItemId
        ),
      })
    }
  }

  startMovingAgendaItems = () => {
    this.setState({ ...this.state, movingAgendaItems: true })
  }

  render() {
    const { classes } = this.props
    const {
      agendaId,
      agendaItems,
      agendaTitle,
      allowedToAddItems,
      deleteItem,
      getNorms,
      getStudents,
      groupNorms,
      hasAgendaPosted,
      hasPostedData,
      isDeletingData,
      isLoadingData,
      isLoadingGroupNorms,
      isLoadingStudents,
      isPostingAgenda,
      meetingEndTime,
      meetingLocation,
      meetingStartTime,
      meetingZoomJoinLink,
      noteTakerName,
      postAgenda,
      students,
      teamId,
      usersInMeeting,
    } = this.props.MeetingAgendaStore

    const hasMeetingStarted = moment(meetingStartTime) >= moment()

    const {
      isSavingData: isSavingMeetingWithAgendaItems,
    } = this.props.CreateMeetingStore

    if (isSavingMeetingWithAgendaItems)
      return (
        <Loading dataTestId='posting-component' text='Moving Agenda Items' />
      )

    if (isDeletingData)
      return (
        <Loading dataTestId='deleting-component' text='Deleting Agenda Item' />
      )
    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Meeting Agenda' />
      )
    if (isPostingAgenda)
      return (
        <Loading dataTestId='posting-component' text='Posting Meeting Agenda' />
      )

    if (hasPostedData)
      return (
        <PostedAgenda
          agendaTitle={agendaTitle}
          meetingEndTime={meetingEndTime}
          meetingLocation={meetingLocation}
          meetingStartTime={meetingStartTime}
          usersInMeeting={usersInMeeting}
          noteTakerName={noteTakerName}
        />
      )

    if (!hasAgendaPosted && !allowedToAddItems && !hasMeetingStarted) {
      return (
        <Typography
          className={classes.centered}
          data-testid='message-container'>
          The Agenda for this meeting has not been posted. If you have further
          questions please contact a Team Lead.
        </Typography>
      )
    }

    return (
      <Fragment>
        <TitleDateTimeLocation
          endTime={meetingEndTime}
          location={meetingLocation}
          noteTaker={noteTakerName}
          renderMenu={() => (
            <Menu>
              <MenuLink
                label='Edit Meeting'
                to={`/createMeeting/${this.props.meetingId}`}
              />
              {allowedToAddItems && (
                <MenuItem onClick={this.startMovingAgendaItems}>
                  Move Agenda Items
                </MenuItem>
              )}
              <AgendaModal
                allowedToAddItems={allowedToAddItems}
                getNorms={getNorms}
                getStudents={getStudents}
                groupNorms={groupNorms}
                isLoadingGroupNorms={isLoadingGroupNorms}
                isLoadingStudents={isLoadingStudents}
                students={students}
                teamId={teamId}
              />
            </Menu>
          )}
          startTime={meetingStartTime}
          title={agendaTitle}
          useMenu
        />
        <div className={classes.addAgendaContainer}>
          <div className={classes.moveAgendaItemControls}>
            {meetingZoomJoinLink && (
              <Typography>
                <a
                  className={classes.link}
                  href={meetingZoomJoinLink}
                  target='__blank'>
                  Join Zoom Meeting
                </a>
              </Typography>
            )}
            {this.state.movingAgendaItems && (
              <Button label='Cancel' onClick={this.cancelMoveAgendaItems} />
            )}
            {this.state.agendaItemsToMove.length > 0 &&
              this.state.movingAgendaItems && (
                <MoveAgendaItemDrawer
                  agendaItemsToMove={this.state.agendaItemsToMove}
                />
              )}
          </div>
          {allowedToAddItems &&
            (hasAgendaPosted ? (
              <LinkButton
                label='Add Agenda Item'
                to={`/createAgendaItem/${agendaId}/${this.props.meetingId}/0`}
              />
            ) : (
              <MenuButton label='Agenda Actions'>
                <MenuLink
                  label='Add Agenda Item'
                  to={`/createAgendaItem/${agendaId}/${this.props.meetingId}/0`}
                />
                <MenuItem onClick={postAgenda}>Post Agenda</MenuItem>
              </MenuButton>
            ))}
        </div>
        <Subheading>
          <Grid item sm={2}>
            <SubheadingItemText>Item</SubheadingItemText>
          </Grid>
          <Grid item sm={1}>
            <SubheadingItemText>Description</SubheadingItemText>
          </Grid>
          <Grid item sm={2} className={classes.centered}>
            <SubheadingItemText>*Type</SubheadingItemText>
          </Grid>
          <Grid item sm={2} lg={1}>
            <SubheadingItemText>Facilitator</SubheadingItemText>
          </Grid>
          <Grid item sm={2} className={classes.centered}>
            <SubheadingItemText>Time(min)</SubheadingItemText>
          </Grid>
          <Grid item sm={1}>
            <SubheadingItemText>Outcome</SubheadingItemText>
          </Grid>
        </Subheading>
        <List
          allowedToAddItems={allowedToAddItems}
          data={agendaItems}
          deleteItem={deleteItem}
          meetingId={this.props.meetingId}
          moveAgendaItem={this.moveAgendaItem}
          movingAgendaItems={this.state.movingAgendaItems}
          onDragEnd={this.onDragEnd}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(MeetingAgenda)
