import React from 'react'
import { Grid } from '@material-ui/core/'
import { Subheading, SubheadingItemText } from '../common'

const TeamSubheading = () => (
  <Subheading data-test='component-container'>
    <Grid item sm={1} />
    <Grid item sm={5}>
      <SubheadingItemText>Team Member</SubheadingItemText>
    </Grid>
    <Grid item sm={3}>
      <SubheadingItemText>Email</SubheadingItemText>
    </Grid>
  </Subheading>
)

export default TeamSubheading
