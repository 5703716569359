import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, withStyles } from '@material-ui/core'

const styles = {
  checkbox: {}
}

const EsCheckbox = ({ checked, classes, onChange, overwriteClass }) => {
  return (
    <Checkbox
      checked={checked}
      className={[classes.checkbox, overwriteClass].join(' ')}
      onChange={onChange}
    />
  )
}

EsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default withStyles(styles)(EsCheckbox)
