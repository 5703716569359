import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import {
  Editor,
  Loading,
  SelectFormField,
  SubmitButton,
  TextFormField,
} from '../common'

const schema = yup.object({
  considerationTime: yup.number('Consideration time must be a number'),
  itemDescription: yup.string(),
  itemName: yup.string().required('Item name is required'),
  selectedAgendaType: yup
    .string()
    .required('A type is required')
    .notOneOf(['-1'], 'Please select a type'),
  selectedFacilitator: yup
    .string()
    .required('A facilitator is required')
    .notOneOf(['-1'], 'Please selected a facilitator'),
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}

@inject('HeaderStore', 'CreateAgendaStore')
@observer
class CreateAgendaItem extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Add Agenda Item')
    this.props.CreateAgendaStore.getData(
      this.props.agendaId,
      this.props.agendaItemId,
      this.props.meetingId
    )
  }

  render() {
    const { classes } = this.props
    const {
      agendaTypes,
      considerationTime,
      facilitators,
      isLoadingData,
      isSavingData,
      itemDescription,
      itemName,
      save,
      selectedAgendaType,
      selectedFacilitator,
      updateItemDescription,
    } = this.props.CreateAgendaStore

    if (isLoadingData) {
      return (
        <Loading dataTestId='loading-component' text='Loading Agenda Info' />
      )
    }

    if (isSavingData) {
      return <Loading text='Creating Agenda Item' />
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          considerationTime,
          //itemDescription,
          itemName,
          selectedAgendaType,
          selectedFacilitator,
        }}
        onSubmit={(values) => save(values)}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={schema}>
        {(formProps) => (
          <Form className={classes.root} data-testid='create-agenda-item-form'>
            <Field
              label='Item Name'
              name='itemName'
              component={TextFormField}
              dataTestId='item-name'
            />
            <Editor
              data={itemDescription}
              onChange={(e) => updateItemDescription(e)}
            />
            <Field
              label='Type'
              menuItems={agendaTypes}
              name='selectedAgendaType'
              component={SelectFormField}
            />
            <Field
              label='Facilitator'
              menuItems={facilitators}
              name='selectedFacilitator'
              component={SelectFormField}
            />
            <Field
              label='Consideration Time(min)'
              name='considerationTime'
              component={TextFormField}
              dataTestId='consideration-time'
            />
            <SubmitButton label='Save' />
          </Form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(CreateAgendaItem)
