import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Grid, Typography } from '@material-ui/core'
import { Button, Editor, SectionTitle, Loading } from '../common'
import { ListOfNotes } from './taskNotes/'
import { withStyles } from '@material-ui/styles'

const styles = (theme) => ({
  details: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: 0,
  },
})

@inject('HeaderStore', 'TaskNotesStore')
@observer
class TaskNotes extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Task Notes')
    this.props.TaskNotesStore.getData(this.props.taskId)
  }

  render() {
    const { classes } = this.props
    const {
      assignedDate,
      edit,
      deleteItem,
      handleTaskNoteChange,
      isDeletingData,
      isLoadingData,
      isSavingData,
      save,
      taskId,
      taskNote,
      taskNotes,
      taskTitle,
      teamName,
    } = this.props.TaskNotesStore

    if (isDeletingData) return <Loading text='Deleting Task Note' />
    if (isLoadingData) return <Loading text='Loading Task Notes' />
    if (isSavingData) return <Loading text='Saving Task Notes' />

    return (
      <Fragment>
        <SectionTitle overwriteClass={classes.title} text={taskTitle} />
        <div className={classes.details}>
          <Typography>
            Assigned Date: {moment(assignedDate).format('MM/DD/YYYY')}
          </Typography>
          <Typography>Team: {teamName}</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Editor data={taskNote} onChange={handleTaskNoteChange} />
            <Button label='Save Notes' onClick={() => save(taskId)} />
          </Grid>
          <Grid item sm={6}>
            <ListOfNotes data={taskNotes} deleteItem={deleteItem} edit={edit} />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(TaskNotes)
