import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const DroppableSection = ({
  data,
  dataTestId,
  onDragEnd,
  ListItem,
  idField,
  ...otherProps
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {droppableProvided => (
          <div ref={droppableProvided.innerRef} data-testid={dataTestId}>
            {data.map((item, index) => (
              <ListItem
                key={item[idField]}
                idField={item[idField]}
                index={index}
                {...item}
                {...otherProps}
              />
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DroppableSection
