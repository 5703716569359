import React, { useState, useEffect, useContext } from 'react'
import { ListOfDistrictGoals } from '../ManageDistrictGoals'
import axios from 'axios'
import { ROOT_URL } from '../../services/api'
import { HeaderContext } from '../layout/Container'
import { LinkButton } from '../common'

const ManageDistrictGoals = () => {
  const { changeHeaderTitleViaContext } = useContext(HeaderContext)

  const [listOfDistrictGoals, setListOfDistrictGoals] = useState([])

  useEffect(() => {
    changeHeaderTitleViaContext('Manage District Goals')

    axios
      .get(`${ROOT_URL}goal/getDistrictGoals`, { withCredentials: true })
      .then((response) => setListOfDistrictGoals(response.data))
      .catch((error) => console.log(error))
  }, [])

  const deleteDistrictGoal = (id) => {
    axios
      .delete(`${ROOT_URL}goal/delete/${id}`, { withCredentials: true })
      .then(() => handleDeleteGoalResponse(id))
      .catch((error) => console.log(error))
  }

  const handleDeleteGoalResponse = (id) => {
    var newArray = listOfDistrictGoals.filter((item) => item.GoalId !== id)
    console.log(newArray)
    setListOfDistrictGoals(newArray)
  }

  return (
    <div data-testid='container'>
      <LinkButton to='/createDistrictGoal/0' label='Create District Goal' />
      <ListOfDistrictGoals
        data={listOfDistrictGoals}
        deleteDistrictGoal={deleteDistrictGoal}
      />
    </div>
  )
}

export default ManageDistrictGoals
