import React from 'react'
import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import { subheadingColor, editButtonStyle } from '../../constants'
import { HiddenLabel, ListItemText, TaskIcon, LinkButton } from '../common'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  extraSpace: {
    paddingLeft: theme.spacing(),
  },
  taskItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(),
    borderBottom: `1px solid ${subheadingColor}`,
  },
  xsLabel: {
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  editButton: {
    ...editButtonStyle,

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      marginLeft: theme.spacing(),
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  buttonContainer: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      paddingRight: theme.spacing(),
    },
  },
}))

const TaskItem = ({ details, markTask }) => {
  const classes = useStyles()
  const {
    AssignedToFInitialLastName,
    GoalName,
    TaskAssignedDate,
    TaskId,
    TaskStatusDisplay,
    TaskStatusId,
    TaskTitle,
    TeamName,
    AllowedToMarkTaskAsCompletedOrEdit,
  } = details
  return (
    <Grid
      container
      alignItems='center'
      key={TaskId}
      className={classes.taskItem}>
      <Grid item xs={12} sm={3} className={classes.item}>
        <HiddenLabel>To Do Item:</HiddenLabel>
        {AllowedToMarkTaskAsCompletedOrEdit && (
          <TaskIcon
            markTaskById={markTask}
            statusId={TaskStatusId}
            taskId={TaskId}
            taskName={TaskTitle}
          />
        )}
        <ListItemText overwriteClass={classes.extraSpace}>
          {TaskTitle}
        </ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={2} md={1}>
        <HiddenLabel>Assigned:</HiddenLabel>
        <ListItemText>
          {moment(TaskAssignedDate).format('MM/DD/YYYY')}
        </ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={2}>
        <HiddenLabel>Group:</HiddenLabel>
        <ListItemText>{TeamName}</ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={2} md={1}>
        <HiddenLabel>Status:</HiddenLabel>
        <ListItemText>{TaskStatusDisplay}</ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={1} md={2}>
        <HiddenLabel>Goal:</HiddenLabel>
        <ListItemText>{GoalName}</ListItemText>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={1} md={2}>
        <HiddenLabel>Assigned To:</HiddenLabel>
        <ListItemText>{AssignedToFInitialLastName}</ListItemText>
      </Grid>
      {AllowedToMarkTaskAsCompletedOrEdit && (
        <Grid xs={12} sm={1} md={1} item className={classes.buttonContainer}>
          <LinkButton
            label='Edit'
            to={`/editTask/${TaskId}`}
            overwriteClass={classes.editButton}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default TaskItem
