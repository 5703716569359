import { action, observable } from 'mobx'
import { navigate } from '@reach/router'
import { getDataApi, postDataApi, deleteDataApi } from '../services/api'
import { sortBy } from '../helpers/compare'
import { urls } from '../constants'
import { createNotificationObject } from '../helpers'

class MeetingNotesStore {
  @observable agendaItemDescription = ''
  @observable agendaItemId = 0
  @observable agendaItemOutcome = null
  @observable agendaItemTitle = ''
  @observable agendaItemTypeName = ''
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable meetingDateEndTime = null
  @observable meetingDateStartTime = null
  @observable meetingId
  @observable meetingLocation = ''
  @observable noteTakerName = ''
  @observable meetingNotesNotifications = []
  @observable tasks = []
  @observable teamId = ''

  deleteTaskAssignment = (itemId) => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteTaskAssignmentResponse,
      itemId,
      url: `${urls.DELETE_TASK_BY_TASK_ID}${itemId}`,
    })
  }

  getData(agendaId, meetingId) {
    this.isLoadingData = true
    this.meetingId = meetingId

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_AGENDA_ITEM_MEETING_NOTES_BY_ID}${agendaId}`,
    })
  }

  saveAndClose = () => {
    this.isSavingData = true

    const postObject = {
      Id: this.agendaItemId,
      Outcome: this.agendaItemOutcome,
    }

    postDataApi({
      handleSuccess: this.handleSaveAndCloseSuccess,
      postObject,
      url: urls.SAVE_AGENDA_ITEM_OUTCOME,
    })
  }

  saveOutcome = () => {
    this.isSavingData = true

    const postObject = {
      Id: this.agendaItemId,
      Outcome: this.agendaItemOutcome,
    }

    postDataApi({
      handleSuccess: this.handleSaveOutcomeSuccess,
      postObject,
      url: urls.SAVE_AGENDA_ITEM_OUTCOME,
    })
  }

  @action handleAgendaItemOutcomeChange = (value) => {
    this.agendaItemOutcome = value
  }

  @action handleDeleteTaskAssignmentResponse = (taskId) => {
    this.tasks.replace(this.tasks.filter((task) => task.Id !== taskId))

    this.meetingNotesNotifications.push(
      createNotificationObject({
        key: taskId,
        message: 'Task Assignment has been deleted',
        variant: 'success',
      })
    )

    this.isDeletingData = false
  }

  @action handleGetDataResponse = (data) => {
    this.agendaItemDescription = data.AgendaItemDescription
    this.agendaItemId = data.AgendaItemId
    this.agendaItemOutcome = data.AgendaItemOutcome
    this.agendaItemTitle = data.AgendaItemTitle
    this.agendaItemTypeName = data.AgendaItemTypeName
    this.meetingDateEndTime = data.MeetingDateEndTime
    this.meetingDateStartTime = data.MeetingDateStartTime
    this.meetingLocation = data.MeetingLocation
    this.noteTakerName = data.NoteTakerName
    this.tasks = data.Tasks
    this.teamId = data.TeamId

    this.isLoadingData = false
  }

  @action handleSaveAndCloseSuccess = (data) => {
    this.meetingNotesNotifications.push({
      key: data,
      message: 'Meeting notes have been saved.',
      options: {
        variant: 'success',
      },
    })

    this.isSavingData = false
    navigate(`/meetingAgenda/${this.meetingId}`)
  }

  @action handleSaveOutcomeSuccess = (data) => {
    this.meetingNotesNotifications.push({
      key: data,
      message: 'Meeting notes have been saved.',
      options: {
        variant: 'success',
      },
    })

    this.isSavingData = false
  }

  @action removeSnackbar = (keyValue) => {
    this.meetingNotesNotifications = this.meetingNotesNotifications.filter(
      (item) => item.key !== keyValue
    )
  }

  @action sortTasks = (ascValue, fieldName) => {
    this.tasks.replace(this.tasks.slice().sort(sortBy(fieldName, ascValue)))
  }
}

export default new MeetingNotesStore()
