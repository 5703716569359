import React from 'react'
import { Grid } from '@material-ui/core'
import { Subheading, SubheadingItemText } from '../common'

const AgendaSubheading = ({ sortAgendaItems }) => {
  return (
    <Subheading>
      <Grid item sm={4}>
        <SubheadingItemText>Item Name</SubheadingItemText>
      </Grid>
      <Grid item sm={8}>
        <SubheadingItemText onClick={() => sortAgendaItems()} sortable>
          Abbreviation
        </SubheadingItemText>
      </Grid>
    </Subheading>
  )
}

export default AgendaSubheading
