import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'
import {
  createNotificationObject,
  removeNotification,
  sortArrayByLastNameFirstName
} from '../helpers'
import { urls } from '../constants'

class ManageStudentsStore {
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable manageStudentsNotifications = []
  @observable users = []

  getData() {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: urls.GET_ALL_STUDENTS
    })
  }

  deleteItem = id => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteItemResponse,
      itemId: id,
      url: `${urls.DELETE_USER_BY_USER_ID}${id}`
    })
  }

  @action handleDeleteItemResponse = userId => {
    this.users = this.users.filter(item => item.StudentUserId !== userId)

    const notification = createNotificationObject({
      key: userId,
      message: 'Student has been deleted.',
      variant: 'success'
    })

    this.manageStudentsNotifications.push(notification)
    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.users = sortArrayByLastNameFirstName(data)
    this.isLoadingData = false
  }

  @action removeSnackbar = keyValue => {
    this.manageStudentsNotifications = removeNotification({
      array: this.manageStudentsNotifications,
      keyValue
    })
  }
}

export default new ManageStudentsStore()
