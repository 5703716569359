import { action, observable } from 'mobx'
import { navigate } from '@reach/router'
import { getDataApi, postDataApi } from '../services/api'

import {
  createNotificationObject,
  removeNotification,
  setupMenuItems,
  sortArrayByFieldName,
  sortArrayByLastNameFirstName,
} from '../helpers'
import { urls } from '../constants'

class CreateAgendaStore {
  @observable agendaId = ''
  @observable agendaItemId = null
  @observable agendaTypes = []
  @observable considerationTime = ''
  @observable createAgendaNotifications = []
  @observable facilitatorId = null
  @observable facilitators = []
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable itemDescription = ''
  @observable itemName = ''
  @observable meetingId = ''
  @observable orderId = 0
  @observable selectedAgendaType = ''
  @observable selectedFacilitator = ''

  getData = (agendaId, agendaItemId, meetingId) => {
    this.isLoadingData = true
    this.agendaId = agendaId
    this.agendaItemId = agendaItemId
    this.meetingId = meetingId

    if (agendaItemId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetAgendaItemDetailsResponse,
        url: `${urls.GET_AGENDA_ITEM_BY_AGENDA_ITEM_ID}${agendaItemId}`,
      })
    } else {
      this.considerationTime = ''
      this.facilitatorId = null
      this.itemDescription = ''
      this.itemName = ''
      this.selectedAgendaType = ''
      this.selectedFacilitator = ''
    }

    getDataApi({
      handleSuccess: this.handleGetAgendaTypesResponse,
      url: urls.GET_AGENDA_ITEM_TYPES,
    })

    getDataApi({
      handleSuccess: this.handleGetTeamsUsersResponse,
      url: `${urls.GET_LIST_OF_TEAM_USERS_BY_AGENDA_ID}${agendaId}`,
    })
  }

  save = (formValues) => {
    this.isSavingData = true

    const postObject = {
      AgendaId: this.agendaId,
      AgendaItemFacilitators: [
        {
          Id: this.facilitatorId,
          UserId: formValues.selectedFacilitator,
        },
      ],
      Description: this.itemDescription,
      Id: this.agendaItemId,
      ItemTypeId: formValues.selectedAgendaType,
      OrderId: this.orderId,
      TimeInMinutes: parseInt(formValues.considerationTime),
      Title: formValues.itemName,
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_AGENDA_ITEM,
    })
  }

  @action handleGetAgendaItemDetailsResponse = (data) => {
    this.agendaId = data.AgendaId
    this.agendaItemId = data.Id
    this.considerationTime = data.TimeInMinutes
    this.facilitatorId = data.AgendaItemFacilitators[0].Id
    this.itemDescription = data.Description
    this.itemName = data.Title
    this.orderId = data.OrderId
    this.selectedAgendaType = data.ItemTypeId
    if (data.AgendaItemFacilitators.length > 0) {
      this.selectedFacilitator = data.AgendaItemFacilitators[0].UserId
    }
  }

  @action handleGetAgendaTypesResponse = (data) => {
    const sortedArray = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Name',
    })

    let array = setupMenuItems({
      data: sortedArray,
      descriptionField: 'Name',
      valueField: 'Id',
    })

    this.agendaTypes = array
    this.isLoadingData = false
  }

  @action handleGetTeamsUsersResponse = (data) => {
    const sortedArray = sortArrayByLastNameFirstName(data)

    sortedArray.forEach((item) => {
      item.DisplayName = item.LastName + ', ' + item.FirstName
    })

    let array = setupMenuItems({
      data: sortedArray,
      descriptionField: 'DisplayName',
      valueField: 'Id',
    })

    this.facilitators = array
    this.isLoadingData = false
  }

  @action handleSaveResponse = (data) => {
    this.considerationTime = ''
    this.facilitatorId = null
    this.itemDescription = ''
    this.itemName = ''
    this.orderId = 0
    this.selectedAgendaType = ''
    this.selectedFacilitator = ''

    const notificationObject = createNotificationObject({
      key: data,
      message: 'Agenda Item has been created.',
      variant: 'success',
    })

    this.createAgendaNotifications.push(notificationObject)
    this.isSavingData = false
    navigate(`/meetingAgenda/${this.meetingId}`)
  }

  @action removeSnackbar = (keyValue) => {
    this.createAgendaNotifications = removeNotification({
      array: this.createAgendaNotifications,
      keyValue,
    })
  }

  @action updateItemDescription = (newDescription) => {
    this.itemDescription = newDescription
  }
}

export default new CreateAgendaStore()
