import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Hidden, Tab, Tabs, withStyles } from '@material-ui/core'
import { Button, Loading, TextField } from '../common'
import { AddUser, UsersInTeam } from '../CreateTeam'

const styles = theme => ({
  addSection: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(10)
    }
  },
  gridContainer: {
    marginTop: theme.spacing(4)
  },
  groupControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  saveGroupButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

@inject('HeaderStore', 'CreateGroupStore')
@observer
class TestScreen extends React.Component {
  state = {
    selectedTab: 0,
    selectedUserTab: 0
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create Team')
    this.props.CreateGroupStore.getData(this.props.groupId)
  }

  componentWillUnmount() {
    this.props.CreateGroupStore.clearFilter()
  }

  handleTabChange = (e, value) => {
    this.setState({ selectedTab: value })
  }

  handleSelectedUserTabChange = (e, value) => {
    this.setState({ selectedUserTab: value })
  }

  render() {
    const { classes } = this.props
    const {
      addAllUsersToGroupByUserTypeId,
      addUserToGroup,
      clearFilter,
      filterValue,
      handleInputChange,
      handleTeamLeadChange,
      isLoadingData,
      isSavingData,
      partnerList,
      removeStafferFromTeam,
      saveGroup,
      stafferList,
      studentList,
      teamName,
      staffersInTeam,
      studentsInTeam,
      partnersInTeam
    } = this.props.CreateGroupStore
    const { selectedTab, selectedUserTab } = this.state

    if (isLoadingData)
      return <Loading dataTestId='loading-component' text='Loading Team Data' />
    if (isSavingData) return <Loading text='Creating New Team' />

    const addUserContent = (
      <Grid item xs={12} md={7} className={classes.addSection}>
        <AddUser
          addAllUsersToGroupByUserTypeId={addAllUsersToGroupByUserTypeId}
          addUserToGroup={addUserToGroup}
          clearFilter={clearFilter}
          filterValue={filterValue}
          handleInputChange={handleInputChange}
          handleTabChange={this.handleTabChange}
          partnerList={partnerList}
          stafferList={stafferList}
          studentList={studentList}
          selectedTab={selectedTab}
        />
      </Grid>
    )

    const usersInTeamContent = (
      <Grid item xs={12} md={5}>
        <UsersInTeam
          handleTabChange={this.handleTabChange}
          handleTeamLeadChange={handleTeamLeadChange}
          partnersInTeam={partnersInTeam}
          removeStafferFromTeam={removeStafferFromTeam}
          selectedTab={selectedTab}
          staffersInTeam={staffersInTeam}
          studentsInTeam={studentsInTeam}
        />
      </Grid>
    )

    return (
      <div>
        <Grid container className={classes.groupControls}>
          <Grid item xs={6}>
            <TextField
              dataTestId='team-name-input'
              label='Enter Team Name'
              name='teamName'
              onChange={e => handleInputChange(e)}
              value={teamName}
            />
          </Grid>
          <Grid item xs={6} className={classes.saveGroupButtonContainer}>
            <Button
              dataTestId='save-team-button'
              disabled={!teamName}
              label='Save Team'
              onClick={saveGroup}
            />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid className={classes.gridContainer} container>
            {addUserContent}
            {usersInTeamContent}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Fragment>
            <Tabs
              value={selectedUserTab}
              onChange={this.handleSelectedUserTabChange}>
              <Tab label='Add User To Team' />
              <Tab label='Users in this Team' />
            </Tabs>
            {selectedUserTab === 0 && <Fragment>{addUserContent}</Fragment>}
            {selectedUserTab === 1 && <Fragment>{usersInTeamContent}</Fragment>}
          </Fragment>
        </Hidden>
      </div>
    )
  }
}

export default withStyles(styles)(TestScreen)
