import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Subheading, SubheadingItemText } from '../common'

const useStyles = makeStyles({
  nonSortable: {
    textTransform: 'uppercase',
  },
  sortable: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
})

const StudentNotesSubheading = ({ handleSortBy }) => {
  const classes = useStyles()
  return (
    <Subheading>
      <Grid item sm={6} className={classes.nonSortable}>
        <SubheadingItemText>Note</SubheadingItemText>
      </Grid>
      <Grid item sm={2} className={classes.sortable}>
        <SubheadingItemText
          onClick={() => handleSortBy('sortByDatePosted', 'DatePosted')}
          sortable>
          Date Posted
        </SubheadingItemText>
      </Grid>
      <Grid item sm={2} className={classes.sortable}>
        <SubheadingItemText
          onClick={() =>
            handleSortBy('sortByPostedBy', 'PostedByFInitialLastName')
          }
          sortable>
          Posted By
        </SubheadingItemText>
      </Grid>
    </Subheading>
  )
}

export default StudentNotesSubheading
