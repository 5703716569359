import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Loading } from '../common'

import { CreateMeetingForm } from '../CreateMeeting'

@inject('HeaderStore', 'CreateMeetingStore')
@observer
class CreateMeeting extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create Meeting')
    this.props.CreateMeetingStore.getData(this.props.meetingId)
  }

  render() {
    const {
      endTime,
      getNoteTakerInfo,
      groups,
      isLoadingData,
      isSavingData,
      meetingDate,
      meetingName,
      roomLocation,
      saveNewMeeting,
      selectedGroup,
      selectedNoteTaker,
      startTime,
      teamMembers,
      zoomJoinLink,
      zoomMeetingId,
      zoomMeetingPasscode,
    } = this.props.CreateMeetingStore

    if (isLoadingData) return <Loading text='Loading Create Meeting Data' />
    if (isSavingData) return <Loading text='Creating New Meeting' />

    return (
      <CreateMeetingForm
        endTime={endTime}
        getNoteTakerInfo={getNoteTakerInfo}
        groups={groups}
        handleSubmit={saveNewMeeting}
        isLoadingData={isLoadingData}
        isSavingData={isSavingData}
        meetingDate={meetingDate}
        meetingName={meetingName}
        roomLocation={roomLocation}
        selectedGroup={selectedGroup}
        selectedNoteTaker={selectedNoteTaker}
        startTime={startTime}
        teamMembers={teamMembers}
        zoomJoinLink={zoomJoinLink}
        zoomMeetingId={zoomMeetingId}
        zoomMeetingPasscode={zoomMeetingPasscode}
      />
    )
  }
}

export default CreateMeeting
