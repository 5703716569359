import React, { Component } from 'react'
import { Button, Menu, withStyles } from '@material-ui/core'

import { ArrowDropDown, MoreHoriz } from '@material-ui/icons'
import { primaryColor } from '../../constants'

const styles = (theme) => ({
  icon: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(),
    },

    cursor: 'pointer',

    '&:hover': {
      color: primaryColor,
    },
  },
  menuButton: { fontWeight: 300 },
})

class EsMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { buttonText, children, classes, className, useButton } = this.props
    const { anchorEl } = this.state

    return (
      <div>
        {useButton ? (
          <Button onClick={this.handleClick} className={classes.menuButton}>
            {buttonText} <ArrowDropDown />
          </Button>
        ) : (
          <MoreHoriz
            data-testid='menu-icon'
            className={`${classes.icon} ${className}`}
            onClick={this.handleClick}
          />
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          {children}
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(EsMenu)
