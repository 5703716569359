import React from 'react'
import { Grid } from '@material-ui/core'
import { SubheadingItemText, Subheading } from '../common'

const StaffSubheading = () => {
  return (
    <Subheading spacing={2}>
      <Grid item sm={1} />
      <Grid item sm={4}>
        <SubheadingItemText>Name</SubheadingItemText>
      </Grid>
      <Grid item sm={5}>
        <SubheadingItemText>Email</SubheadingItemText>
      </Grid>
    </Subheading>
  )
}

export default StaffSubheading
