import React from 'react'
import { Tooltip } from 'react-tippy'
import { makeStyles } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { taskStatusId } from '../../constants'

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const TaskIcon = ({ markTaskById, taskId, taskName, statusId }) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        statusId === taskStatusId.Completed
          ? `Click to mark ${taskName} as Active`
          : `Click to mark ${taskName} as Completed`
      }
      position='right'
      trigger='mouseenter'>
      {statusId === taskStatusId.Completed ? (
        <Close className={classes.icon} onClick={() => markTaskById(taskId)} />
      ) : (
        <Check className={classes.icon} onClick={() => markTaskById(taskId)} />
      )}
    </Tooltip>
  )
}

export default TaskIcon
