import { action, observable } from 'mobx'
import { getDataApi, deleteDataApi } from '../services/api'
import { removeNotification } from '../helpers'
import { urls } from '../constants'

class StudentCommentsStore {
  @observable isDeletingData = false
  @observable isLoadingData = true

  @observable studentCommentsNotifications = []

  @observable commentId = 0
  @observable comments = []
  @observable firstName = ''
  @observable fullName = ''
  @observable graduationYear = ''
  @observable lastName = ''
  @observable postedBy = ''
  @observable postedOn = ''
  @observable studentNote = ''
  @observable userId = ''
  @observable userNoteId = 0

  deleteStudentComment = commentId => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteStudentCommentResponse,
      itemId: commentId,
      url: `${urls.DELETE_USER_NOTE_COMMENT}${commentId}`
    })
  }

  getData = userNoteId => {
    this.isLoadingData = true
    this.userNoteId = parseInt(userNoteId)

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_USER_NOTES_WITH_COMMENTS_BY_USER_NOTE_ID}${userNoteId}`
    })
  }

  @action handleDeleteStudentCommentResponse = id => {
    this.comments = this.comments.filter(comment => comment.Id !== id)

    this.studentCommentsNotifications.push({
      key: id,
      message: 'Comment has been deleted.',
      options: {
        variant: 'success'
      }
    })

    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.comments = data.UserNoteComments
    this.firstName = data.UserFirstName
    this.fullName = data.UserFullName
    this.graduationYear = data.GraduationYear
    this.lastName = data.UserLastName
    this.postedBy = data.PostedByFInitialLastName
    this.postedOn = data.DatePosted
    this.studentNote = data.Note
    this.userId = data.UserId

    this.isLoadingData = false
  }

  @action removeSnackbar = keyValue => {
    this.studentCommentsNotifications = removeNotification({
      array: this.studentCommentsNotifications,
      keyValue
    })
  }
}

export default new StudentCommentsStore()
