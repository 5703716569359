import React from 'react'
import { Chip as MuiChip, makeStyles } from '@material-ui/core'

import { primaryColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  chip: {
    fontSize: 11,
    height: 22,
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
  },
}))

const Chip = ({ className, label, variant = 'outlined' }) => {
  const classes = useStyles()

  return (
    <MuiChip
      className={`${classes.chip} ${className}`}
      label={label}
      variant={variant}
    />
  )
}

export default Chip
