import React from 'react'
import { Checkbox, Typography } from '@material-ui/core'

const CheckboxFormField = ({ dataTestId, field, form, label, ...props }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={field.value}
        inputProps={{
          'data-testid': dataTestId
        }}
        onChange={(_, v) => form.setFieldValue(field.name, v)}
        style={{ paddingLeft: 0 }}
        {...props}
      />
      <Typography>{label}</Typography>
    </div>
  )
}

export default CheckboxFormField
