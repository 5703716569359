import React from 'react'
import { EmptyArrayMessage } from '../common'
import SchoolGoalItem from './SchoolGoalItem'

const ListOfSchoolGoals = ({ data, deleteSchoolGoal }) => {
  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map(item => (
            <SchoolGoalItem
              key={item.GoalId}
              deleteSchoolGoal={deleteSchoolGoal}
              goalDescription={item.GoalDescription}
              goalId={item.GoalId}
              targetDate={item.TargetDate}
              goalName={item.GoalName}
            />
          ))}
        </>
      ) : (
        <EmptyArrayMessage text='There are no school goals to display' />
      )}
    </>
  )
}
export default ListOfSchoolGoals
