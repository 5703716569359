import React, { Component } from 'react'
import { MenuItem, withStyles } from '@material-ui/core'
//import Notifications from '@material-ui/icons/Notifications'
//import SearchField from './SearchField'
import UserAvatar from './UserAvatar'
import { headerHeight, navTextColor } from '../../constants'
import { Menu, MenuLink } from '../common'
import { inject, observer } from 'mobx-react'
import { getFirstNameLastNameInitials } from '../../helpers'

const styles = (theme) => ({
  notifications: {
    color: navTextColor,
    fontSize: 40,
  },
  notificationContainer: {
    height: 81,

    display: 'flex',
    alignItems: 'center',

    borderLeft: `1px solid ${navTextColor}`,
    borderRight: `1px solid ${navTextColor}`,

    paddingLeft: 15,
    paddingRight: 15,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flex: 1,

      height: headerHeight,
    },
  },
  tempLink: {
    color: 'blue',

    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    marginRight: theme.spacing(2),

    '&:visited': {
      color: 'blue',
    },
  },
  userName: {
    color: navTextColor,
    fontSize: 15,
  },
  userNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
})

@inject('LoginStore')
@observer
class SearchAlertsUserMenu extends Component {
  state = { searchTerm: '' }
  render() {
    const { classes, firstName, lastName } = this.props
    //const { searchTerm } = this.state

    return (
      <div className={classes.root}>
        {/* <SearchField
          onChange={e => this.setState({ searchTerm: e.target.value })}
          placeholder='Search Enriching Teachers'
          value={searchTerm}
        /> */}
        {/*<div className={classes.notificationContainer}>
          <Notifications className={classes.notifications} /> 
        </div>*/}
        <a
          className={classes.tempLink}
          href='https://www.enrichingstudents.com/feedback-enriching-teachers'
          rel='noopener noreferrer'
          target='_blank'>
          Give us your feedback
        </a>
        <div className={classes.userNameContainer}>
          <UserAvatar
            name={getFirstNameLastNameInitials(firstName, lastName)}
          />
          <Menu useButton={true} buttonText={`${firstName} ${lastName}`}>
            <MenuLink label='My Account' to='/myAccount' />
            <MenuItem onClick={this.props.LoginStore.handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SearchAlertsUserMenu)
