import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, IconButton, Typography, withStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../../images/ETLogo_icon.png'
import { SearchAlertsUserName } from '../common'
import { flexRow, navTextColor, primaryColor } from '../../constants'

const styles = (theme) => ({
  appBar: {
    backgroundColor: '#FFF',
    height: 81,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      width: '100%',
      zIndex: 1000,
    },
  },
  headerTitle: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },

    marginLeft: 10,
    color: navTextColor,
    fontWeight: 300,
  },
  icon: {
    fontSize: 40,
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },

    height: 81,
    width: 81,

    backgroundColor: primaryColor,

    paddingRight: theme.spacing(2),
  },
  menuButton: {
    color: navTextColor,
    marginRight: 0,

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  pageInfoContainer: {
    ...flexRow,
    flex: 1,
  },
  test2: {
    ...flexRow,
    flex: 2,
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userName: {
    color: navTextColor,
  },
})

@inject('HeaderStore')
@observer
class Header extends Component {
  state = {
    searchTerm: '',
  }

  render() {
    const { classes, handleDrawerToggle } = this.props
    const {
      loggedInUserFirstName,
      loggedInUserLastName,
    } = this.props.HeaderStore

    return (
      <AppBar position='fixed' className={classes.appBar}>
        <div className={classes.pageInfoContainer}>
          <div className={classes.logoContainer}>
            <img
              alt='Enriching Teachers Logo'
              src={logo}
              style={{ height: 80 }}
            />
          </div>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon className={classes.icon} />
          </IconButton>
          <Typography variant='h5' className={classes.headerTitle} noWrap>
            {this.props.HeaderStore.headerTitle}
          </Typography>
        </div>
        <div className={classes.test2}>
          <SearchAlertsUserName
            firstName={loggedInUserFirstName}
            lastName={loggedInUserLastName}
          />
        </div>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Header)
