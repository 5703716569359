import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Grid } from '@material-ui/core'
import {
  Loading,
  TextFormField,
  TextAreaFormField,
  DatePickerFormField,
  SelectFormField,
  SubmitButton
} from '../common'

const schema = yup.object({
  goalDescription: yup.string(),
  goalName: yup.string().required('A goal name is required'),
  selectedGroup: yup
    .string()
    .required()
    .notOneOf(['-1'], 'Please select a team for this goal'),
  targetDate: yup.date()
})

@inject('HeaderStore', 'CreateGroupGoalStore')
@observer
class CreateGoal extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create A Goal')
    this.props.CreateGroupGoalStore.getData(this.props.goalId)
  }

  render() {
    const {
      goalDescription,
      goalName,
      formattedGroups,
      isLoadingData,
      isSavingData,
      save,
      selectedGroup,
      targetDate
    } = this.props.CreateGroupGoalStore

    if (isLoadingData) {
      return <Loading dataTestId='loading-component' text='Loading Groups' />
    }

    if (isSavingData) {
      return <Loading dataTestId='saving-component' text='Creating New Goal' />
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          goalDescription,
          goalName,
          selectedGroup,
          targetDate
        }}
        onSubmit={values => save(values)}
        validationSchema={schema}>
        <Form>
          <Grid data-testid='container' container direction='column'>
            <Field
              dataTestId='goal-name-input'
              label='Goal Name'
              name='goalName'
              component={TextFormField}
            />
            <Field
              dataTestId='goal-description-input'
              label='Goal Description'
              name='goalDescription'
              component={TextAreaFormField}
            />
            <Field
              dataTestId='target-date-date-picker'
              fieldValue='targetDate'
              label='Target Date'
              name='targetDate'
              component={DatePickerFormField}
            />
            <Field
              dataTestId='team-assignment-select'
              label='Team Assignment'
              menuItems={formattedGroups}
              name='selectedGroup'
              component={SelectFormField}
            />
            <SubmitButton label='Save' />
          </Grid>
        </Form>
      </Formik>
    )
  }
}

export default CreateGoal
