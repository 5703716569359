import React from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core'
import { primaryColor } from '../../constants'

const styles = theme => ({
  progress: {
    color: primaryColor
  },
  typography: {
    marginTop: theme.spacing(2)
  }
})

const LoadingRows = ({ classes, text }) => {
  return (
    <TableRow>
      <TableCell align='center' colSpan={6}>
        <CircularProgress className={classes.progress} size={80} />
        <Typography className={classes.typography} variant='h4' align='center'>
          {text}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

LoadingRows.propTypes = {
  text: PropTypes.string.isRequired
}

export default withStyles(styles)(LoadingRows)
