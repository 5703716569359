import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { MyTaskAssignments } from '../MyTaskAssignments'
import { UpcomingMeetings } from '../UpcomingMeetings'

@inject('HeaderStore')
@observer
class Dashboard extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Dashboard')
  }
  render() {
    return (
      <div data-test='component-container'>
        <UpcomingMeetings />
        <MyTaskAssignments />
      </div>
    )
  }
}

export default Dashboard
