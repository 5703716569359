import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { LinkButton, Loading } from '../common'
import { flexRow, navTextColor } from '../../constants'
import { withStyles } from '@material-ui/core'

import { TeamsList } from '../MyTeams'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  container: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  pageTitle: {
    color: navTextColor
  },
  testAlign: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    ...flexRow
  }
})

@inject('HeaderStore', 'GroupStore')
@observer
class MyGroups extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('My Teams')
    this.props.GroupStore.getData()
  }

  render() {
    const { classes } = this.props
    const {
      deleteTeam,
      deleteTeamMember,
      isDeletingTeam,
      isDeletingTeamMember,
      isLoadingData,
      teams
    } = this.props.GroupStore
    const { isAdmin, loggedInUserId } = this.props.HeaderStore

    if (isLoadingData)
      return <Loading dataTestId='loading-component' text='Loading Team Data' />

    if (isDeletingTeam)
      return (
        <Loading dataTestId='delete-component' text='Deleting Team Forever' />
      )

    if (isDeletingTeamMember)
      return (
        <Loading
          dataTestId='delete-team-member-component'
          text='Removing Team Member'
        />
      )

    return (
      <div data-testid='container' style={{ position: 'relative' }}>
        <div className={classes.buttonContainer}>
          <LinkButton label='Create New Team' to='/createTeam/0' />
        </div>
        <TeamsList
          deleteTeam={deleteTeam}
          deleteTeamMember={deleteTeamMember}
          isAdmin={isAdmin}
          loggedInUserId={loggedInUserId}
          teams={teams}
        />
      </div>
    )
  }
}

export default withStyles(styles)(MyGroups)
