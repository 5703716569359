import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { Typography, makeStyles } from '@material-ui/core'

import zoomIcon from '../../images/zoomIcon.jpg'

import { Button, TextFormField, SubmitButton, Loading } from '../common'
import { urls } from '../../constants'
import { getDataApi, postDataApi } from '../../services/api'

const useStyles = makeStyles((theme) => ({
  container: { display: 'flex' },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
    paddingLeft: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    height: 64,
    width: 64,

    marginRight: theme.spacing(3),
  },
  link: {
    color: 'blue',
    textDecoration: 'none',

    '&:visited': {
      color: 'blue',
    },
  },
  nav: {
    padding: theme.spacing(),

    borderRight: '1px solid #d9d9d9',
    minHeight: '50vh',
    minWidth: 150,

    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
    },
  },
  navItem: {
    paddingTop: theme.spacing(),

    '&:hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  pageTitle: {
    fontWeight: 300,
    fontSize: 32,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(),
    },
  },
  revokeZoomAccess: {
    background: '#3d4849',

    '&:hover': {
      background: '#a9a9a9',
    },
  },
  title: {
    color: 'blue',
    fontSize: 22,
  },
  zoomContainer: {
    display: 'flex',
  },
  zoomInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const schema = yup.object({
  ConfirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('Password'), null], 'Passwords must match'),
  Password: yup
    .string()
    .required('Please provide a password')
    .min(6, 'Password must be at least 6 characters in length'),
})

const MyAccount = inject(
  'HeaderStore',
  'MyAccountStore'
)(
  observer((props) => {
    const { isResettingPassword, resetPassword } = props.MyAccountStore

    const [viewable, setViewable] = useState(0)
    const [integratedWithZoom, setIntegratedWithZoom] = useState(false)

    const [zoomClientId, setZoomClientId] = useState('')

    const classes = useStyles()

    useEffect(() => {
      props.HeaderStore.handleTitleChange('My Account')

      getDataApi({
        handleSuccess: (data) => setIntegratedWithZoom(data),
        url: urls.CHECK_ZOOM_INTEGRATION,
      })

      getDataApi({
        handleSuccess: (response) => setZoomClientId(response),
        url: urls.GET_ZOOM_CLIENT_ID,
      })
    }, [])

    const revokeZoomAccess = () => {
      postDataApi({
        handleSuccess: () => setIntegratedWithZoom(false),
        postObject: {},
        url: urls.REVOKE_ZOOM_ACCESS,
      })
    }

    if (isResettingPassword) return <Loading text='Resetting Password' />

    return (
      <>
        <Typography className={classes.pageTitle}>Account Settings</Typography>
        <div className={classes.container}>
          <div className={classes.nav}>
            <Typography
              className={classes.navItem}
              onClick={() => setViewable(0)}>
              Reset Password
            </Typography>
            <Typography
              className={classes.navItem}
              onClick={() => setViewable(1)}>
              Integrations
            </Typography>
          </div>
          {/* <div style={{ marginBottom: 64 }}>
          <SectionTitle text='Nicholas Bernard' />
          <Button label='Upload Photo' onClick={() => console.log('')} />
        </div> */}
          <div className={classes.content}>
            {viewable === 0 && (
              <div>
                <Typography variant='h6'>Reset Password</Typography>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ConfirmPassword: '',
                    Password: '',
                  }}
                  onSubmit={(values) => resetPassword(values)}
                  validateOnBlur={true}
                  validateOnChange={false}
                  validationSchema={schema}>
                  <Form
                    data-testid='create-staffer-form'
                    className={classes.form}>
                    <Field
                      dataTestId='password-input'
                      label='Password'
                      name='Password'
                      type='password'
                      component={TextFormField}
                    />
                    <Field
                      dataTestId='confirm-password-input'
                      label='Confirm Password'
                      name='ConfirmPassword'
                      type='password'
                      component={TextFormField}
                    />
                    <SubmitButton label='Save' />
                  </Form>
                </Formik>
              </div>
            )}
            {viewable === 1 && (
              <div>
                <Typography variant='h6'>Integrations</Typography>
                <div className={classes.zoomContainer}>
                  <img
                    alt='Zoom Icon'
                    className={classes.icon}
                    src={zoomIcon}
                  />
                  <div className={classes.zoomInfo}>
                    <Typography className={classes.title}>
                      Zoom Meetings
                    </Typography>
                    <Typography paragraph>
                      Create a Zoom Meeting with a click of the mouse when
                      creating your PLC Team meeting. All team members will be
                      sent an email notification and calendar events that
                      includes the Zoom Meeting link. If you cancel a PLC Team
                      meeting, the Zoom Meeting that was created will be
                      removed.
                    </Typography>
                    {integratedWithZoom ? (
                      <div>
                        <Button
                          overwriteClass={classes.revokeZoomAccess}
                          label='Disconnect Zoom Meetings'
                          onClick={revokeZoomAccess}
                        />
                      </div>
                    ) : (
                      <div>
                        <a
                          className={classes.link}
                          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${zoomClientId}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URI}`}>
                          <Button label='Connect Zoom Meetings' />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  })
)

export default MyAccount
