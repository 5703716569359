import React, { Fragment, forwardRef } from 'react'
import { MenuItem, Modal, makeStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { dangerColor } from '../../constants'
import Button from './Button'
import SectionTitle from './SectionTitle'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: 16,
    marginTop: 16,
    fontSize: 32,
    cursor: 'pointer',
  },
  deleteButton: {
    backgroundColor: dangerColor,

    '&:hover': {
      backgroundColor: '#f44336',
      color: '#fff',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.paper,

    opacity: 0.85,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const DeleteModal = forwardRef((props, ref) => {
  const {
    buttonText,
    confirmDeleteCallback,
    confirmDeleteLabel,
    confirmDeleteMessage,
    itemName,
    menuItemText,
    useCancel,
    useMenuItem,
  } = props

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmDelete = () => {
    setOpen(false)

    confirmDeleteCallback()
  }

  return (
    <Fragment>
      {useMenuItem ? (
        <MenuItem onClick={handleOpen} ref={ref}>
          {menuItemText}
        </MenuItem>
      ) : (
        <Button
          dataTestId='delete-modal-button'
          label={buttonText ? buttonText : 'Delete'}
          onClick={handleOpen}
          overwriteClass={classes.deleteButton}
        />
      )}
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}>
        <div className={classes.paper}>
          <Close className={classes.closeIcon} onClick={handleClose} />
          <div className={classes.modalContent}>
            <SectionTitle
              text={
                useCancel
                  ? 'You are about to cancel:'
                  : 'You are about to delete:'
              }
            />
            <h3>{itemName}</h3>
            <p>{confirmDeleteMessage}</p>
          </div>
          <Button
            dataTestId='confirm-delete-button'
            label={confirmDeleteLabel}
            onClick={handleConfirmDelete}
            overwriteClass={classes.deleteButton}
          />
        </div>
      </Modal>
    </Fragment>
  )
})

export default DeleteModal
