import React from 'react'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core'

const styles = theme => ({
  selectField: {
    width: 250
  }
})

const EsSelectField = ({
  classes,
  label,
  menuItems,
  name,
  onChange,
  selectedValue
}) => {
  return (
    <FormControl className={classes.selectField}>
      <InputLabel>{label}</InputLabel>
      <Select value={selectedValue} name={name} onChange={onChange}>
        {menuItems.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(EsSelectField)
