import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import {
  DeleteModal,
  ListItemText,
  Menu,
  MenuLink,
  UserAvatar
} from '../common'
import { navColor } from '../../constants'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    borderBottom: `1px solid ${navColor}`
  },
  studentName: {
    marginRight: theme.spacing()
  },
  studentNameContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const StudentItem = ({
  emailAddress,
  firstName,
  graduationYear,
  groupId,
  index,
  lastCommaFirst,
  lastName,
  removeStudent,
  studentUserId,
  userTeamId
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      alignItems='center'
      data-testid='student-item'>
      <Grid className={classes.studentNameContainer} item sm={3}>
        <UserAvatar
          index={index}
          name={firstName.charAt(0) + lastName.charAt(0)}
        />
        <ListItemText className={classes.studentName} dataTestId='student-name'>
          {lastCommaFirst}
        </ListItemText>
        <Menu>
          <MenuLink
            label='Edit Student'
            to={`/createStudent/${studentUserId}`}
          />
          {/* <MenuItem>
            <a
              className={classes.link}
              href='app.enrichingstudents.com'
              target='_blank'>
              ES Student Profile
            </a>
          </MenuItem> */}
          <MenuLink
            label='View Student Notes'
            to={`/studentNotes/${groupId}/${studentUserId}`}
          />
          <DeleteModal
            confirmDeleteCallback={() => removeStudent(userTeamId)}
            confirmDeleteLabel='Remove Student'
            confirmDeleteMessage='Are you sure you want to remove this student from the team?'
            itemName={`${firstName} ${lastName}`}
            menuItemText='Remove Student'
            useMenuItem
          />
        </Menu>
      </Grid>
      <Grid item sm={4}>
        <a data-testid='email-address' href={`mailto: ${emailAddress}`}>
          <ListItemText>{emailAddress}</ListItemText>
        </a>
      </Grid>
      <Grid item sm={2}>
        <ListItemText dataTestId='graduation-year'>
          {graduationYear}
        </ListItemText>
      </Grid>
    </Grid>
  )
}

export default StudentItem
