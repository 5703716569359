import { action, observable } from 'mobx'
import { getDataApi } from '../services/api'
import { urls } from '../constants'

class HeaderStore {
  @observable headerTitle = ''
  @observable searchTerm = ''

  @observable fInitialLastName = ''
  @observable isAdmin = false
  @observable isAnyTeamLead = false
  @observable isSuperAdmin = false
  @observable locationId = ''
  @observable loggedInUserFirstName = ''
  @observable loggedInUserLastName = ''
  @observable loggedInUserId = ''
  @observable loggedInUserUserTypeId = null
  @observable siteAdminKey1 = false
  @observable siteAdminKey2 = false

  fetchSearchReults(searchTerm) {
    //make an api call and use this term
    //this.searchTerm
  }

  @action handleGetLoggedInUserResponse = (data) => {
    this.loggedInUserFirstName = data.FirstName
    this.loggedInUserLastName = data.LastName
    this.loggedInUserId = data.Id
    this.loggedInUserUserTypeId = data.UserTypeId

    localStorage.setItem('LoggedInUserFirstName', data.FirstName)
    localStorage.setItem('LoggedInUserLastName', data.LastName)
    localStorage.setItem('LoggedInUserFInitialLastName', data.FInitialLastName)
    localStorage.setItem('LoggedInUserId', data.Id)
  }

  @action handleTitleChange = (headerTitle) => {
    this.headerTitle = headerTitle
  }

  @action updateUserDetails = (data) => {
    this.isAdmin = data.IsAdmin
    this.isAnyTeamLead = data.IsAnyTeamLead
    this.isSuperAdmin = data.IsSuperAdmin
    this.locationId = data.LocationId
    this.loggedInUserId = data.UserId
    this.siteAdminKey1 = data.SuperAdminGuidKey1
    this.siteAdminKey2 = data.SuperAdminGuidKey2

    getDataApi({
      handleSuccess: this.handleGetLoggedInUserResponse,
      url: `${urls.GET_USER_BY_USER_ID}${data.UserId}`,
    })
  }
}

export default new HeaderStore()
