import React from 'react'

import { EmptyArrayMessage } from '../common'

import GoalItem from './GoalItem'

const ListOfGoals = ({ data, deleteGoal, markGoalAsCompleted }) => {
  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map((item, index) => (
            <GoalItem
              key={item.GoalId}
              deleteGoal={deleteGoal}
              goalDescription={item.GoalDescription}
              goalId={item.GoalId}
              goalPercentageCompleted={item.GoalPercentageCompleted}
              goalName={item.GoalName}
              index={index}
              memberCountWithAssignedTasks={item.MemberCountWithAssignedTasks}
              targetDate={item.TargetDate}
              tasksRemaining={item.TasksRemaining}
              teamName={item.TeamName}
              readOnly={item.ReadOnly}
              allowedToMarkGoalAsCompleted={item.AllowedToMarkGoalAsCompleted}
              markGoalAsCompleted={markGoalAsCompleted}
            />
          ))}
        </>
      ) : (
        <EmptyArrayMessage text='There are no goals to display.' />
      )}
    </>
  )
}

export default ListOfGoals
