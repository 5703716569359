import React, { Fragment } from 'react'
import GroupNormItem from './GroupNormItem'
import { DroppableList, EmptyArrayMessage } from '../common'

const List = ({
  data,
  deleteGroupNorm,
  editNorm,
  onDragEnd,
  allowedToEdit,
}) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <DroppableList
          data={data}
          dataTestId='norms-list'
          deleteGroupNorm={deleteGroupNorm}
          editNorm={editNorm}
          idField={'Id'}
          ListItem={GroupNormItem}
          onDragEnd={onDragEnd}
          allowedToEdit={allowedToEdit}
        />
      ) : (
        <EmptyArrayMessage text='There are no Team Norms to display.' />
      )}
    </Fragment>
  )
}

export default List
