import React from 'react'
import { TextField, Typography, makeStyles } from '@material-ui/core'
import { Button } from '../common'

const useStyles = makeStyles(theme => ({
  erroredLabel: {},
  focusedLabel: {},
  forgotPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  label: {
    color: 'white',
    '&$focusedLabel': {
      color: 'white'
    },
    '&$erroredLabel': {
      color: 'orange'
    }
  },
  sendEmailButton: {
    marginLeft: theme.spacing(2)
  },
  sendEmailContainer: {
    display: 'flex',
    alignItems: 'center',

    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  text: {
    color: '#fff'
  },
  underline: {
    '&$error:after': {
      borderBottomColor: 'orange'
    },
    '&:after': {
      borderBottom: `2px solid #fff`
    },
    '&:before': {
      borderBottom: `2px solid #fff`
    }
  }
}))

const ForgotPasswordContainer = ({
  email,
  handleForgotPassword,
  handleInputChange,
  toggleForgotPassword
}) => {
  const classes = useStyles()
  return (
    <div className={classes.forgotPasswordContainer}>
      <Typography className={classes.text} variant='h5'>
        Forgot Password?
      </Typography>
      <Typography className={classes.text}>
        Enter your email and we'll send you an email to reset your password.
      </Typography>
      <div className={classes.sendEmailContainer}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel
            }
          }}
          InputProps={{
            classes: {
              root: classes.underline
            }
          }}
          label='Email'
          name='email'
          onChange={handleInputChange}
          value={email}
        />
        <Button
          label='Send Email'
          onClick={handleForgotPassword}
          overwriteClass={classes.sendEmailButton}
        />
      </div>
      <Button label='Cancel' onClick={toggleForgotPassword} />
    </div>
  )
}

export default ForgotPasswordContainer
