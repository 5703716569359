import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { withStyles } from '@material-ui/core'
import {
  CheckboxFormField,
  Loading,
  SubmitButton,
  TextFormField
} from '../common'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}

@inject('HeaderStore', 'CreateStudentStore')
@observer
class CreateStudent extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Create Student')
    this.props.CreateStudentStore.setupData(this.props.studentId)
  }

  render() {
    const { classes } = this.props
    const {
      emailAddress,
      firstName,
      graduationYear,
      isActive,
      isLoadingData,
      isSavingData,
      lastName,
      thirdPartyId,
      save,
      userId,
      validateEmailAddress,
      validateStudentSisId
    } = this.props.CreateStudentStore

    const schema = yup.object({
      FirstName: yup
        .string()
        .required('First Name is required')
        .min(2, 'First Name must be at least 2 characters'),
      Active: yup.bool(),
      LastName: yup
        .string()
        .required('Last Name is required')
        .min(2, 'Last Name must be at least 2 characters'),
      EmailAddress: yup
        .string()
        .required('Email Address is required')
        .email('Email Address must be an email address')
        .test(
          'unique-email',
          'This email address is associated with another user',
          async value => {
            const response = await validateEmailAddress(value)
            return response
          }
        ),
      GraduationYear: yup
        .number()
        .test(
          'len',
          'Graduation Year must be 4 digits',
          val => val.toString().length === 4
        ),
      ThirdPartyId: yup
        .number()
        .required('Student Id is required')
        .test(
          'unique-student-id',
          'This student id is associated with another student',
          async value => {
            const response = await validateStudentSisId(value)
            return response
          }
        )
    })

    if (isLoadingData)
      return (
        <Loading dataTestId='loading-component' text='Loading Student Info' />
      )
    if (isSavingData) return <Loading text='Saving Student Info' />

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Active: isActive,
          EmailAddress: emailAddress,
          FirstName: firstName,
          Id: userId,
          LastName: lastName,
          ThirdPartyId: thirdPartyId,
          GraduationYear: graduationYear
        }}
        onSubmit={values => save(values)}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={schema}>
        <Form className={classes.root}>
          <Field
            dataTestId='first-name-input'
            label='First Name'
            name='FirstName'
            component={TextFormField}
          />
          <Field
            dataTestId='last-name-input'
            label='Last Name'
            name='LastName'
            component={TextFormField}
          />
          <Field
            dataTestId='email-address-input'
            label='Email Address'
            name='EmailAddress'
            component={TextFormField}
          />
          <Field
            dataTestId='graduation-year-input'
            label='Graduation Year'
            name='GraduationYear'
            component={TextFormField}
          />
          <Field
            dataTestId='student-id-input'
            label='Students Id'
            name='ThirdPartyId'
            component={TextFormField}
          />
          <Field
            dataTestId='active-checkbox'
            label='Active'
            name='Active'
            component={CheckboxFormField}
          />
          <SubmitButton label='Save' />
        </Form>
      </Formik>
    )
  }
}

export default withStyles(styles)(CreateStudent)
