export const urls = {
  CHECK_ZOOM_INTEGRATION: 'meeting/checkIfZoomIntegrationExists',
  CREATE_GOAL: 'goal/save',
  CREATE_MEETING: 'meeting/save',
  CREATE_MEETING_WITH_AGENDA_ITEMS: 'meeting/saveWithAgendaItems',
  CREATE_TEAM_WITH_USERS: 'team/addTeamWithUsers',
  DELETE_AGENDA_ITEM_BY_AGENDA_ITEM_ID: 'agendaitem/delete/',
  DELETE_AGENDA_ITEM_TYPE: 'agendaitemtype/delete/',
  DELETE_GOAL_BY_GOAL_ID: 'goal/delete/',
  DELETE_MEETING_BY_MEETING_ID: 'meeting/delete/',
  DELETE_TASK_NOTE: 'tasknote/delete/',
  DELETE_USER_BY_USER_ID: 'user/Delete/',
  DELETE_USER_NOTE: 'usernote/delete/',
  DELETE_USER_NOTE_COMMENT: 'usernotecomment/delete/',
  DELETE_TASK_BY_TASK_ID: 'task/delete/',
  DELETE_TEAM: 'team/delete/',
  DELETE_TEAM_NORM: 'teamnorms/delete/',
  DELETE_USER_FROM_TEAM: 'userteam/delete/',
  GET_AGENDA_ITEM_BY_AGENDA_ITEM_ID: 'agendaitem/getById/',
  GET_AGENDA_ITEM_MEETING_NOTES_BY_ID:
    'agendaItem/getAgendaItemMeetingNotesById/',
  GET_AGENDA_ITEM_TYPES: 'agendaitemtype/getAgendaItemTypes',
  GET_AGENDA_ITEMS_BY_MEETING_ID: 'agendaitem/getAgendaItemsByMeetingId/',
  GET_ALL_STAFFERS: 'user/GetStaffers',
  GET_ALL_STAFFERS_BY_TEAM_ID: 'team/getAllStaffersById/',
  GET_ALL_STUDENTS: 'user/GetStudents',
  GET_ALL_USERS: 'user/getAllUsers',
  GET_COMPLETE_AGENDA_MEETING_AGENDA_ITEMS_BY_MEETING_ID:
    'agenda/getCompleteAgendaMeetingAgendaItemsByMeetingId/',
  GET_CURRENTLY_LOGGED_IN_USER_FULL_NAME:
    'user/getCurrentlyLoggedInUserFullName',
  GET_DASHBOARD_TASKS_BY_ASSIGNED_USER: 'task/getMyAssignedDashboardTasks',
  GET_GOAL_BY_GOAL_ID: 'goal/getById/',
  GET_GOALS_BY_TEAM_ID: 'goal/getByTeamId/',
  GET_GOAL_ITEMS_BY_MEETING_ID: 'goal/getByMeetingId/',
  GET_GOAL_STATUS: 'goal/getGoalStatus/',
  GET_IS_TEACHER_SIS_UNIQUE: 'user/isTeacherSISUnique/',
  GET_IS_STUDENT_SIS_UNIQUE: 'user/isStudentSISUnique/',
  GET_LIST_OF_HISTORICAL_MEETINGS: 'meeting/getMyHistoricalMeeting',
  GET_LIST_OF_TEAM_USERS_BY_AGENDA_ID: 'agenda/getListOfTeamUsersByAgendaId/',
  GET_MEETING_BY_MEETING_ID: 'meeting/getById/',
  GET_MEETINGS_BY_USER_ID_AND_GOAL_ID: 'meeting/getMeetingsByUserIdAndGoalId/',
  GET_MEETINGS_FOR_TASK_ASSIGNMENT_BY_USER_ID:
    'meeting/getMeetingsAssignerAndAssigneeHaveInCommonForTaskAssignment/',
  GET_MY_TEAM_GOALS: 'goal/myTeamGoals',
  GET_MY_TEAMS_WITH_USER_TYPE_ID_TO_EXCLUDE:
    'team/getMyTeamsWithUserTypeIdToExclude/',
  GET_MY_UPCOMING_MEETINGS: 'meeting/getMyUpcomingMeeting',
  GET_STUDENT_NOTE_BY_NOTE_ID:
    'usernote/getStudentNoteCreateEditVMByUserNoteId/',
  GET_STUDENT_NOTES_BY_USER_ID: 'usernote/getStudentNoteVMByUserId/',
  GET_STUDENTS_BY_TEAM_ID: 'user/getStudentsByTeamId/',
  GET_TASK_BY_TASK_ID: 'task/getById/',
  GET_TASK_NOTES_BY_TASK_ID: 'tasknote/getTaskNotesVM/',
  GET_TASKS_BY_MEETING_ID: 'task/getByMeetingId/',
  GET_TASKS_COMPLETED_BY_MEETING_ID: 'task/getCompletedByMeetingId/',
  GET_TASKS_REMAINING_BY_MEETING_ID: 'task/getRemainingByMeetingId/',
  GET_TASKS_COMPLETED_BY_GOAL_ID: 'goal/getTasksCompletedByGoalId/',
  GET_TASKS_REMAINING_BY_GOAL_ID: 'goal/getTasksRemainingByGoalId/',
  GET_TEAM_BY_LOCATION_ID: 'team/getByLocationId',
  GET_TEAM_FOR_LOGGED_IN_USER: 'team/getForLoggedInUser',
  GET_TEAM_NORMS_BY_TEAM_ID: 'team/getTeamWithNormsByTeamId/',
  GET_TEAMS_WITH_USERS_BY_ID: 'team/getTeamWithUsersById/',
  GET_USER_DETAILS: 'user/getUserDetails/',
  GET_USERS_BY_GOAL_ID: 'user/getByGoalId/',
  GET_USER_BY_USER_ID: 'user/getById/',
  GET_USER_NOTE_COMMENT_BY_ID: 'usernotecomment/getById/',
  GET_USER_NOTES_WITH_COMMENTS_BY_USER_NOTE_ID:
    'usernote/getNoteWithCommentsByUserNoteId/',
  GET_USERS_BY_TEAM_ID: 'team/getById/',
  GET_ZOOM_CLIENT_ID: 'zoomapi/getClientId',
  MARK_GOAL_AS_COMPLETED: 'goal/markAsCompleted/',
  MARK_TASK_AS_COMPLETED: 'task/markAsDone/',
  MARK_TASK_AS_IN_PROGRESS: 'task/markAsInProgress/',
  POST_MEETING_AGENDA: 'agenda/postMeetingAgenda/',
  REVOKE_ZOOM_ACCESS: 'zoomapi/revokeZoomAccess',
  SAVE_AGENDA_ITEM: 'agendaitem/save',
  SAVE_AGENDA_ITEM_OUTCOME: 'agendaitem/saveOutcome',
  SAVE_AGENDA_ITEM_TYPE: 'agendaitemtype/save',
  SAVE_AGENDA_ITEMS_REORDER: 'agendaitem/reorder',
  SAVE_EXISTING_MEETING_WITH_AGENDA_ITEMS:
    'meeting/saveExistingMeetingWithAgendaItems',
  SAVE_TASK_FROM_ASSIGN_TASK: 'task/save',
  SAVE_TASK_FROM_AGENDA_ITEM: 'task/saveFromAgendaItem',
  SAVE_TASK_NOTE: 'tasknote/Save',
  SAVE_TEAM_NORM: 'teamnorms/save',
  SAVE_TEAM_NORM_TITLE: 'team/updateNormTitle',
  SAVE_TEAM_NORMS_REORDER: '/teamnorms/reorder',
  SAVE_USER: 'user/save',
  SAVE_USER_NOTE: 'usernote/Save',
  SAVE_USER_NOTE_COMMENT: 'usernotecomment/save',
  SAVE_USER_PASSWORD: 'usermembership/UpdatePassword',
  SAVE_ZOOM_RESPONSE: 'zoomapi/zoomUserResponse/',
  USER_FORGOT_PASSWORD: 'usermembership/ForgotPassword',
  UPDATE_PASSWORD_FOR_LOGGED_ON_USER:
    'usermembership/UpdatePasswordForLoggedInUser',
}
