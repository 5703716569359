import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { primaryColor } from '../../constants'

const renderBackground = (index, isDragging) => {
  if (!isDragging) {
    return 'whitesmoke'
  } else {
    return primaryColor
  }
}

const getItemStyle = (isDragging, draggableStyle, index) => ({
  userSelect: 'none',
  padding: 8,
  margin: `0 0 8px 0`,
  display: 'flex',
  background: renderBackground(index, isDragging),
  cursor: 'default',

  // styles we need to apply on draggables
  ...draggableStyle
})

const DraggableItem = ({ children, dataTestId, id, index }) => {
  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(draggableProvided, draggableSnapshot) => (
        <div
          data-testid={dataTestId}
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          style={getItemStyle(
            draggableSnapshot.isDragging,
            draggableProvided.draggableProps.style,
            index
          )}>
          {children}
        </div>
      )}
    </Draggable>
  )
}

export default DraggableItem
