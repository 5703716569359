import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: 250,
    fontFamily: 'Roboto'
  }
})

const TextArea = ({ name, onChange, placeholder, value }) => {
  const classes = useStyles()

  return (
    <TextareaAutosize
      className={classes.root}
      rows={10}
      rowsMax={20}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default TextArea
