import React, { Fragment } from 'react'
import { Typography, makeStyles } from '@material-ui/core'

import { EmptyArrayMessage, Paper } from '../../common'
import { primaryColor } from '../../../constants'

import NotesItem from './NotesItem'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minHeight: 292,
  },
  title: {
    //padding: theme.spacing(),
    color: primaryColor,
  },
}))

const ListOfNotes = ({ data, deleteItem, edit }) => {
  const classes = useStyles()

  return (
    <Paper overwrite={classes.paper}>
      <Typography className={classes.title} variant='h5'>
        Previous task notes
      </Typography>
      {data.length > 0 ? (
        <Fragment>
          {data.map((item, index) => (
            <NotesItem
              key={index}
              comment={item.Comment}
              commentDate={item.CommentDate}
              createdBy={item.CreatedByFInitialLastName}
              createdByFirstName={item.CreatedByFirstName}
              createdByLastName={item.CreatedByLastName}
              deleteItem={deleteItem}
              edit={edit}
              id={item.Id}
              index={index}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no notes to display.' />
      )}
    </Paper>
  )
}

export default ListOfNotes
