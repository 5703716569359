import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import moment from 'moment'
import { navigate } from '@reach/router'
import { Grid, makeStyles } from '@material-ui/core'
import { ROOT_URL } from '../../services/api'
import { HeaderContext } from '../layout/Container'
import {
  TextFormField,
  SubmitButton,
  DatePickerFormField,
  SelectFormField,
} from '../common'
import { setupMenuItems, sortArrayByFieldName } from '../../helpers'

const schema = yup.object({
  dueDate: yup.string(),
  selectedAgendaItemId: yup.string().nullable(),
  selectedGoalId: yup.string().nullable(),
  selectedMeetingId: yup.string().nullable(),
  selectedUserId: yup.string().required('A user is required'),
  title: yup.string(),
})

const useStyles = makeStyles((theme) => ({
  sumbitButton: { paddingTop: theme.spacing() },
}))

const EditTask = (props) => {
  const classes = useStyles()

  const { changeHeaderTitleViaContext } = useContext(HeaderContext)

  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState({
    agendaItems: [],
    agendaItemsDisabled: true,
    dueDate: moment(),
    goals: [],
    meetings: [],
    selectedUserId: '',
    selectedMeetingId: '',
    selectedAgendaItemId: '',
    selectedGoalId: '',
    selectedTaskStatusId: '',
    title: '',
    users: [],
    taskStatusList: [],
  })

  useEffect(() => {
    changeHeaderTitleViaContext('Edit Task')
    if (props.taskId !== '0') {
      axios
        .get(`${ROOT_URL}task/getById/${props.taskId}`, {
          withCredentials: true,
        })
        .then((response) => handleGetTaskByIdResponse(response.data))
        .catch((error) => console.log(error))
    }
  }, [])

  const handleGetTaskByIdResponse = (data) => {
    axios
      .all([
        axios.get(
          `${ROOT_URL}user/getAssignableUsersByTaskId/${props.taskId}`,
          { withCredentials: true }
        ),
        axios.get(`${ROOT_URL}meeting/getByTaskId/${props.taskId}`, {
          withCredentials: true,
        }),
        axios.get(`${ROOT_URL}goal/getByTaskId/${props.taskId}`, {
          withCredentials: true,
        }),
        axios.get(
          `${ROOT_URL}agendaitem/getAgendaItemsByMeetingId/${data.MeetingId}`,
          { withCredentials: true }
        ),
        axios.get(`${ROOT_URL}task/getTaskStatusList`, {
          withCredentials: true,
        }),
      ])
      .then((response) => {
        handleGetAssignableUsersByTaskIdResponse(response[0].data)
        handleGetMeetingResponse(response[1].data)
        handleGetGoalItemsResponse(response[2].data)
        handleGetAgendaItemsResponse(response[3].data)
        handleGetTaskStatusListResponse(response[4].data)
      })
      .then(() =>
        setValues((prevState) => ({
          ...prevState,
          title: data.Title,
          selectedUserId: data.AssignedToUserId,
          selectedMeetingId: data.MeetingId,
          selectedAgendaItemId: data.AgendaItemId,
          selectedGoalId: data.GoalId,
          selectedTaskStatusId: data.TaskStatusId,
          dueDate: data.DueDate,
        }))
      )
      .then(() => setLoading(false))
      .catch((error) => console.log(error))
  }

  const handleGetAssignableUsersByTaskIdResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'FullName',
    })

    const menuItems = setupMenuItems({
      data: sortedData,
      descriptionField: 'FullName',
      valueField: 'Id',
    })

    setValues((prevState) => ({ ...prevState, users: menuItems }))
    setLoading(false)
  }

  const handleGetTaskStatusListResponse = (data) => {
    setValues((prevState) => ({
      ...prevState,
      taskStatusList: setupMenuItems({
        data: data,
        descriptionField: 'Name',
        valueField: 'Id',
      }),
    }))
  }

  const getMeetingsByUser = (data) => {
    axios
      .get(`${ROOT_URL}meeting/getByUserId/${data}`, { withCredentials: true })
      .then((response) => handleGetMeetingResponse(response.data))
      .catch((error) => console.log(error))
  }

  const getMeetingsByGoalId = (data) => {
    axios
      .get(`${ROOT_URL}meeting/getByGoalId/${data}`, { withCredentials: true })
      .then((response) => handleGetMeetingResponse(response.data))
      .catch((error) => console.log(error))
  }

  const handleGetMeetingResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Subject',
    })

    setValues((prevState) => ({
      ...prevState,
      meetings: setupMenuItems({
        data: sortedData,
        descriptionField: 'Subject',
        valueField: 'Id',
      }),
    }))
  }

  const getAgendaItems = (data) => {
    axios
      .get(`${ROOT_URL}agendaitem/getAgendaItemsByMeetingId/${data}`, {
        withCredentials: true,
      })
      .then((response) => handleGetAgendaItemsResponse(response.data))
      .catch((error) => console.log(error))
  }

  const handleGetAgendaItemsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Title',
    })

    setValues((prevState) => ({
      ...prevState,
      agendaItems: setupMenuItems({
        data: sortedData,
        descriptionField: 'Title',
        valueField: 'Id',
      }),
      agendaItemsDisabled: false,
    }))
  }

  const getGoalsByUser = (data) => {
    axios
      .get(`${ROOT_URL}goal/getByUserId/${data}`, { withCredentials: true })
      .then((response) => handleGetGoalItemsResponse(response.data))
      .catch((error) => console.log(error))
  }

  const getGoalsByMeetingId = (data) => {
    axios
      .get(`${ROOT_URL}goals/getByMeetingId/${data}`, { withCredentials: true })
      .then((response) => handleGetGoalItemsResponse(response.data))
      .catch((error) => console.log(error))
  }

  const handleGetGoalItemsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Title',
    })

    setValues((prevState) => ({
      ...prevState,
      goals: setupMenuItems({
        data: sortedData,
        descriptionField: 'Title',
        valueField: 'Id',
      }),
    }))
  }

  const save = (values) => {
    axios
      .post(
        `${ROOT_URL}task/save`,
        {
          AgendaItemId: values.selectedAgendaItemId,
          AssignedToUserId: values.selectedUserId,
          DueDate: values.dueDate,
          GoalId: values.selectedGoalId,
          Id: props.taskId,
          MeetingId: values.selectedMeetingId,
          TaskStatusId: values.selectedTaskStatusId,
          Title: values.title,
        },
        { withCredentials: true }
      )
      .then(() => navigate('/'))
      .catch((error) => console.log(error))
  }

  if (loading) return <p>Loading...</p>

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: values.title,
        selectedUserId: values.selectedUserId,
        selectedMeetingId: values.selectedMeetingId,
        selectedAgendaItemId: values.selectedAgendaItemId,
        selectedGoalId: values.selectedGoalId,
        selectedTaskStatusId: values.selectedTaskStatusId,
        dueDate: values.dueDate,
      }}
      onSubmit={(formValues) => save(formValues)}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={schema}>
      {(
        // passing in the formProps to manually set the value
        // for the selectedMeeting, so that I can make an API
        // call as a callback
        formProps
      ) => (
        <Form>
          <Grid container direction='column'>
            <Field
              fieldValue='selectedUserId'
              label='Assigned To'
              menuItems={values.users}
              name='selectedUserId'
              onChange={(e) => {
                formProps.setFieldValue('selectedUserId', e.target.value)
                getMeetingsByUser(e.target.value)
                getGoalsByUser(e.target.value)
              }}
              component={SelectFormField}
            />
            <Field
              label='Todo Item'
              name='title'
              component={TextFormField}
              dataTestId='todo-item'
            />
            <Field
              label='Task Status'
              menuItems={values.taskStatusList}
              name='selectedTaskStatusId'
              component={SelectFormField}
            />
            <Field
              fieldValue='selectedMeetingId'
              label='Meetings'
              menuItems={values.meetings}
              name='selectedMeetingId'
              onChange={(e) => {
                formProps.setFieldValue('selectedMeetingId', e.target.value)
                getAgendaItems(e.target.value)
                if (values.selectedGoalId === '0') {
                  getGoalsByMeetingId(e.target.value)
                }
              }}
              component={SelectFormField}
            />
            <Field
              label='Agenda Items'
              menuItems={values.agendaItems}
              disabled={values.agendaItemsDisabled}
              name='selectedAgendaItemId'
              component={SelectFormField}
            />
            <Field
              label='Goal'
              menuItems={values.goals}
              name='selectedGoalId'
              component={SelectFormField}
              onChange={(e) => {
                formProps.setFieldValue('selectedGoalId', e.target.value)
                if (values.selectedMeetingId === '0') {
                  getMeetingsByGoalId(e.target.value)
                }
              }}
            />
            <Field
              fieldValue='dueDate'
              label='Date Due'
              name='dueDate'
              component={DatePickerFormField}
            />
            <Grid item className={classes.sumbitButton}>
              <SubmitButton label='Save' />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default EditTask
