import { getIn } from 'formik'
import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const TextFormField = ({
  dataTestId,
  field,
  form,
  placeholder,
  type,
  ...props
}) => {
  const classes = useStyles()

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <TextField
      className={classes.textField}
      error={!!errorText}
      helperText={errorText}
      inputProps={{ 'data-testid': `${dataTestId}` }}
      margin='normal'
      placeholder={placeholder}
      style={{ width: 300 }}
      type={type}
      {...field}
      {...props}
    />
  )
}

export default TextFormField
