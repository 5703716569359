import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { InfoContainer } from '../Settings'

@inject('HeaderStore')
@observer
class Admin extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Settings')
  }

  render() {
    return (
      <Fragment>
        <InfoContainer
          description='Set the options for different types of agenda items.'
          linkLabel='Manage Agenda Types'
          to='/manageAgendaTypes'
        />
        <InfoContainer
          description='Add, edit and delete staff and admins.'
          linkLabel='Manage Staff'
          to='/manageStaff'
        />
        <InfoContainer
          description='Add, edit and delete students.'
          linkLabel='Manage Students'
          to='/manageStudents'
        />
        <InfoContainer
          description='Manage all your teams in your account. Create, edit and remove users
          from teams.'
          linkLabel='Manage Teams'
          to='/manageTeams'
        />
        <InfoContainer
          description='Add, edit and delete district level goals.'
          linkLabel='Manage District Goals'
          to='/manageDistrictGoals'
        />
        <InfoContainer
          description='Add, edit and delete school level goals.'
          linkLabel='Manage School Goals'
          to='/manageSchoolGoals'
        />
      </Fragment>
    )
  }
}

export default Admin
