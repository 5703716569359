import React, { Component, Fragment } from 'react'
import { Grid } from '@material-ui/core'
import AssignmentItem from './AssignmentItem'
import {
  EmptyArrayMessage,
  LinkButton,
  SectionTitle,
  Subheading,
  SubheadingItemText,
} from '../common'

class ListOfAssignments extends Component {
  state = {
    sortByAssignedDate: false,
    sortByAssociatedGoal: false,
    sortByTaskStatus: false,
    sortByTaskTitle: false,
  }

  sortBy = (stateValue, sortByValue) => {
    this.setState(
      {
        sortByAssignedDate:
          stateValue === 'sortByAssignedDate'
            ? !this.state.sortByAssignedDate
            : false,
        sortByAssociatedGoal:
          stateValue === 'sortByAssociatedGoal'
            ? !this.state.sortByAssociatedGoal
            : false,
        sortByTaskStatus:
          stateValue === 'sortByTaskStatus'
            ? !this.state.sortByTaskStatus
            : false,
        sortByTaskTitle:
          stateValue === 'sortByTaskTitle'
            ? !this.state.sortByTaskTitle
            : false,
      },
      () => {
        this.props.sortTasks(this.state[stateValue], sortByValue)
      }
    )
  }

  render() {
    const {
      agendaItemId,
      deleteTaskAssignment,
      meetingId,
      saveMeetingNotes,
      tasks,
      teamId,
    } = this.props

    return (
      <div>
        <Grid
          container
          alignItems='center'
          justify='space-between'
          style={{ marginTop: 10, marginBottom: 10 }}>
          <SectionTitle text='Assignments' />
          <LinkButton
            label='Add Task'
            onClick={saveMeetingNotes}
            to={`/createMeetingsNotes/${meetingId}/${teamId}/${agendaItemId}/0`}
          />
        </Grid>
        <Subheading>
          <Grid item sm={3} lg={2}>
            <SubheadingItemText
              onClick={() => this.sortBy('sortByTaskTitle', 'Title')}
              sortable>
              To Do Item
            </SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText
              onClick={() => this.sortBy('sortByAssignedDate', 'DateAssigned')}
              sortable>
              Assigned
            </SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText>Assigned To</SubheadingItemText>
          </Grid>
          <Grid item sm={3} lg={1}>
            <SubheadingItemText>Status</SubheadingItemText>
          </Grid>
          <Grid item sm={2}>
            <SubheadingItemText
              onClick={() =>
                this.sortBy('sortByAssociatedGoal', 'GoalNameDisplay')
              }
              sortable>
              Goal
            </SubheadingItemText>
          </Grid>
        </Subheading>
        {tasks.length > 0 ? (
          <Fragment>
            {tasks.map((item) => (
              <AssignmentItem
                key={item.Id}
                details={item}
                deleteTaskAssignment={deleteTaskAssignment}
                teamId={teamId}
              />
            ))}
          </Fragment>
        ) : (
          <EmptyArrayMessage text='There are no Assignments to display.' />
        )}
      </div>
    )
  }
}

export default ListOfAssignments
