import React from 'react'
import { Hidden, TableRow, makeStyles } from '@material-ui/core'
import { navColor } from '../../constants'

const useStyles = makeStyles({
  root: {
    backgroundColor: navColor
  }
})

const SubheadingRow = ({ children }) => {
  const classes = useStyles()
  return (
    <Hidden xsDown>
      <TableRow className={classes.root}>{children}</TableRow>
    </Hidden>
  )
}

export default SubheadingRow
