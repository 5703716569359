import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'
import { sortBy } from '../helpers/compare'
import { urls } from '../constants'

class MyGroupsStore {
  @observable isDeletingTeam = false
  @observable isDeletingTeamMember = false
  @observable isLoadingData = true
  @observable teamNotifications = []
  @observable teams = []

  /* istanbul ignore next */
  deleteTeam = (teamId) => {
    this.isDeletingTeam = true

    deleteDataApi({
      handleSuccess: this.handleDeleteTeamResponse,
      itemId: teamId,
      url: `${urls.DELETE_TEAM}${teamId}`,
    })
  }

  deleteTeamMember = (userTeamId, teamId) => {
    this.isDeletingTeamMember = true

    const handleDeleteTeamMemberResponse = () => {
      this.teamNotifications.push({
        key: userTeamId,
        message: 'Team Member has been deleted.',
        options: {
          variant: 'success',
        },
      })

      let clonedTeams = [...this.teams]
      let selectedClonedTeam = clonedTeams.filter(
        (team) => team.TeamId === teamId
      )
      const clonedTeamMembers = selectedClonedTeam[0].TeamMembers.filter(
        (member) => member.UserTeamId !== userTeamId
      )
      selectedClonedTeam[0].TeamMembers = clonedTeamMembers

      this.teams = clonedTeams

      this.isDeletingTeamMember = false
    }

    deleteDataApi({
      handleSuccess: handleDeleteTeamMemberResponse,
      itemId: userTeamId,
      url: `${urls.DELETE_USER_FROM_TEAM}${userTeamId}`,
    })
  }

  /* istanbul ignore next */
  getData = () => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleServerResponse,
      url: `${urls.GET_MY_TEAMS_WITH_USER_TYPE_ID_TO_EXCLUDE}2`,
    })
  }

  @action handleDeleteTeamResponse = (teamId) => {
    this.teams.replace(this.teams.filter((team) => team.TeamId !== teamId))

    this.isDeletingTeam = false

    this.teamNotifications.push({
      key: teamId,
      message: 'Team has been deleted.',
      options: {
        variant: 'success',
      },
    })
  }

  @action handleSelectAllCheckboxChange = (teamId, e) => {
    this.teams
      .find((team) => team.TeamId === teamId)
      .TeamMembers.forEach((item) => (item.IsSelected = e.target.checked))
  }

  @action handleServerResponse = (data) => {
    data.forEach((item) => {
      item.TeamMembers.forEach((member) => (member.IsSelected = false))
      item.TeamMembers.sort(sortBy('FirstName', false))
    })

    this.teams = data
    this.isLoadingData = false
  }

  @action removeSnackbar = (keyValue) => {
    this.teamNotifications = this.teamNotifications.filter(
      (item) => item.key !== keyValue
    )
  }
}

export default new MyGroupsStore()
