import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, Menu, makeStyles, withStyles } from '@material-ui/core'

import { secondaryColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#FFF',
    backgroundColor: secondaryColor,
    fontSize: 13,

    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),

    '&:hover': {
      backgroundColor: '#6abf69',
      color: '#000',
    },
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const MenuButton = forwardRef((props, ref) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // controls what gets exposed to the parents via the ref
  useImperativeHandle(ref, () => ({
    closeMenuFromParent() {
      setAnchorEl(null)
    },
  }))

  return (
    <div ref={ref}>
      <Button
        className={classes.button}
        onClick={handleClick}
        variant='contained'>
        {props.label ? props.label : 'Actions'}
      </Button>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {props.children}
      </StyledMenu>
    </div>
  )
})

export default MenuButton
