import React from 'react'
import { MenuItem, Typography, makeStyles } from '@material-ui/core'
import { DragIndicator } from '@material-ui/icons'

import { DraggableItem, Menu } from '../common'
import { primaryColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  dragIndicator: {
    marginRight: theme.spacing(),
    '&&:hover': {
      color: primaryColor,
      cursor: 'move',
    },
  },
  teamNorm: {
    padding: theme.spacing(),
    background: 'whitesmoke',
    marginBottom: theme.spacing(),
  },
  text: {
    paddingRight: theme.spacing(),
  },
}))

const GroupNormItem = ({
  allowedToEdit,
  deleteGroupNorm,
  editNorm,
  Id,
  index,
  Norm,
}) => {
  const classes = useStyles()

  return (
    <>
      {allowedToEdit ? (
        <DraggableItem dataTestId='group-norm-item' id={Id} index={index}>
          <Typography
            data-testid='norm-text'
            className={classes.text}
            style={{ display: 'flex', alignItems: 'center' }}>
            <DragIndicator className={classes.dragIndicator} />
            {Norm}
          </Typography>
          <Menu>
            <MenuItem onClick={() => editNorm(Id)}>Edit Norm</MenuItem>
            <MenuItem onClick={() => deleteGroupNorm(Id)}>Delete Norm</MenuItem>
          </Menu>
        </DraggableItem>
      ) : (
        <div className={classes.teamNorm}>
          <Typography
            data-testid='norm-text'
            className={classes.text}
            style={{ display: 'flex', alignItems: 'center' }}>
            {Norm}
          </Typography>
        </div>
      )}
    </>
  )
}

export default GroupNormItem
