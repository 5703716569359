import { action, observable } from 'mobx'
import {
  getDataApi,
  postDataApi,
  validateEmailAddressIsUnique,
  validateStudentSisIsUnique
} from '../services/api'
import { createNotificationObject, removeNotification } from '../helpers'
import moment from 'moment'
import { urls } from '../constants'
import { navigate } from '@reach/router'

class CreateStudentStore {
  @observable createStudentNotifications = []
  @observable emailAddress = ''
  @observable firstName = ''
  @observable graduationYear = moment().year()
  @observable isActive = false
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable lastName = ''
  @observable thirdPartyId = ''
  @observable userId = '00000000-0000-0000-0000-000000000000'

  setupData = userId => {
    this.isLoadingData = true

    if (userId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetDataResponse,
        url: `${urls.GET_USER_BY_USER_ID}${userId}`
      })
    } else {
      this.emailAddress = ''
      this.firstName = ''
      this.graduationYear = moment().year()
      this.isActive = false
      this.lastName = ''
      this.thirdPartyId = ''
      this.userId = '00000000-0000-0000-0000-000000000000'

      this.isLoadingData = false
    }
  }

  save = form => {
    this.isSavingData = true

    const postObject = {
      Active: form.Active,
      EmailAddress: form.EmailAddress,
      FirstName: form.FirstName,
      Id: form.Id,
      LastName: form.LastName,
      ThirdPartyId: parseInt(form.ThirdPartyId),
      UserStudent: {
        GraduationYear: form.GraduationYear
      }
    }

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: urls.SAVE_USER
    })
  }

  validateEmailAddress = async emailAddress => {
    // no input
    if (emailAddress === undefined) {
      return false
    }

    // user has edited emailAddress and then changed back to original id
    if (emailAddress === this.emailAddress) {
      // staffId matches existings record is 'is unique'
      return true
    }

    // otherwise this is a brand new emailAddress and we need to validate
    const response = await validateEmailAddressIsUnique(emailAddress)
    return response
  }

  validateStudentSisId = async sisId => {
    if (sisId === undefined) {
      return false
    }

    if (sisId === this.thirdPartyId) {
      return true
    }

    const response = await validateStudentSisIsUnique(sisId)
    return response
  }

  @action handleCheckboxChange = e => {
    this[e.target.name] = e.target.checked
  }

  @action handleGetDataResponse = data => {
    this.emailAddress = data.EmailAddress
    this.firstName = data.FirstName
    this.graduationYear = data.UserStudent.GraduationYear
    this.isActive = data.Active
    this.lastName = data.LastName
    if (data.ThirdPartyId !== null) {
      this.thirdPartyId = data.ThirdPartyId
    }
    this.userId = data.Id

    this.isLoadingData = false
  }

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value
  }

  @action handleSaveResponse = data => {
    this.emailAddress = ''
    this.firstName = ''
    this.graduationYear = ''
    this.isActive = false
    this.lastName = ''
    this.thirdPartyId = ''
    this.userId = '0'

    const notificationObject = createNotificationObject({
      key: data,
      message: 'Student information has been saved.',
      variant: 'success'
    })

    this.createStudentNotifications.push(notificationObject)

    this.isSavingData = false

    navigate('/manageStudents')
  }

  @action removeSnackbar = keyValue => {
    this.createStudentNotifications = removeNotification({
      array: this.createStudentNotifications,
      keyValue
    })
  }
}

export default new CreateStudentStore()
