import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import { ROOT_URL } from '../../services/api'
import moment from 'moment'
import { HeaderContext } from '../layout/Container'
import {
  TextFormField,
  SubmitButton,
  DatePickerFormField,
  TextAreaFormField,
} from '../common'
import { navigate } from '@reach/router'
import { Grid, makeStyles } from '@material-ui/core'

const schema = yup.object({
  title: yup.string().required('The title is required'),
  description: yup.string(),
  targetDate: yup.string(),
})

const useStyles = makeStyles((theme) => ({
  sumbitButton: { paddingTop: theme.spacing() },
}))

const CreateDistrictGoal = (props) => {
  const classes = useStyles()
  const { changeHeaderTitleViaContext } = useContext(HeaderContext)

  const [values, setValues] = useState({
    title: '',
    description: '',
    targetDate: moment(),
  })

  const handleGetAdminResponse = (data) => {
    setValues({
      title: data.Title,
      description: data.Description,
      targetDate: data.TargetDate,
    })
  }

  useEffect(() => {
    changeHeaderTitleViaContext('Add/Edit District Goal')

    if (props.goalId !== '0') {
      axios
        .get(`${ROOT_URL}goal/getAdminModifiableOnlyGoal/${props.goalId}`, {
          withCredentials: true,
        })
        .then((response) => handleGetAdminResponse(response.data))
        .catch((error) => console.log(error))
    }
  }, [])

  const save = (values) => {
    axios
      .post(
        `${ROOT_URL}goal/saveDistrictGoal`,
        { ...values, Id: props.goalId },
        { withCredentials: true }
      )
      .then(() => navigate('/manageDistrictGoals'))
      .catch((error) => console.log(error))
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: values.title,
        description: values.description,
        targetDate: values.targetDate,
      }}
      onSubmit={(values) => save(values)}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={schema}>
      <Form>
        <Grid container direction='column'>
          <Field
            dataTestId='title-input'
            label='Title'
            name='title'
            component={TextFormField}
          />
          <Field
            dataTestId='goal-description-input'
            label='Description'
            name='description'
            component={TextAreaFormField}
          />
          <Field
            dataTestId='target-date-input'
            fieldValue='targetDate'
            label='Target Date'
            name='targetDate'
            component={DatePickerFormField}
          />
          <Grid item className={classes.sumbitButton}>
            <SubmitButton label='Save' />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default CreateDistrictGoal
