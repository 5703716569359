import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'
import {
  Button,
  Editor,
  Loading,
  StudentInfoWithLink,
  TextField
} from '../common'

const styles = theme => ({
  buttonContainer: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    },

    marginTop: theme.spacing(2)
  },
  studentDetails: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      marginBottom: theme.spacing(3)
    }
  }
})

@inject('CreateStudentNoteStore', 'HeaderStore')
@observer
class CreateStudentNote extends Component {
  componentDidMount() {
    this.props.CreateStudentNoteStore.getData(
      this.props.groupId,
      this.props.studentId
    )
    this.props.HeaderStore.handleTitleChange('Create Student Note')

    if (this.props.noteId !== '0') {
      this.props.CreateStudentNoteStore.getStudentNote(this.props.noteId)
    }
  }
  render() {
    const { classes, groupId, noteId, studentId } = this.props
    const {
      graduationYear,
      firstName,
      fullName,
      handleInputChange,
      handleNoteChange,
      isLoadingData,
      isSavingData,
      lastName,
      note,
      save,
      title
    } = this.props.CreateStudentNoteStore

    if (isLoadingData) return <Loading text='Loading Student Note' />
    if (isSavingData) return <Loading text='Saving Student Note' />

    return (
      <Fragment>
        <StudentInfoWithLink
          firstName={firstName}
          fullName={fullName}
          graduationYear={graduationYear}
          lastName={lastName}
          linkLabel='Back to the Student Notes'
          to={`/studentNotes/${groupId}/${studentId}`}
        />
        <TextField
          name='title'
          label='Title'
          onChange={handleInputChange}
          value={title}
        />
        <Editor data={note} onChange={handleNoteChange} />
        <div className={classes.buttonContainer}>
          <Button label='Save Notes' onClick={() => save(noteId)} />
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(CreateStudentNote)
