import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'

import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'

class StudentGroupsStore {
  @observable isDeletingData = false
  @observable isLoadingData = true
  @observable studentGroupsNotifications = []
  @observable students = []
  @observable teamName = ''

  @action getData = teamId => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_STUDENTS_BY_TEAM_ID}${teamId}`
    })
  }

  removeStudent = userTeamId => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleRemoveStudentResponse,
      itemId: userTeamId,
      url: `${urls.DELETE_USER_FROM_TEAM}${userTeamId}`
    })
  }

  @action handleGetDataResponse = data => {
    this.students = data
    if (data.length > 0) {
      this.teamName = data[0].TeamName
    }

    this.isLoadingData = false
  }

  @action handleRemoveStudentResponse = userTeamId => {
    this.students = this.students.filter(item => item.UserTeamId !== userTeamId)

    const notificationObject = createNotificationObject({
      key: userTeamId,
      message: 'Student has been removed from team.',
      variant: 'success'
    })

    this.studentGroupsNotifications.push(notificationObject)

    this.isDeletingData = false
  }

  @action removeSnackbar = keyValue => {
    this.studentGroupsNotifications = removeNotification({
      array: this.studentGroupsNotifications,
      keyValue
    })
  }
}

export default new StudentGroupsStore()
