import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography, withStyles } from '@material-ui/core'

import { primaryColor } from '../../constants'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: 'calc(100vh - 115px)'
  },
  fullscreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '90vh',
    width: '100vw'
  },
  login: {
    color: 'white'
  },
  loginTypography: {
    color: 'white'
  },
  progress: {
    color: primaryColor
  },
  typography: {
    marginTop: theme.spacing(2)
  }
})

const Loading = ({ classes, dataTestId, fullscreen, login, text }) => {
  return (
    <div
      className={fullscreen ? classes.fullscreen : classes.container}
      data-testid={dataTestId}>
      <CircularProgress
        className={login ? classes.login : classes.progress}
        size={80}
      />
      <Typography
        className={login ? classes.loginTypography : classes.typography}
        variant='h4'
        align='center'>
        {text}
      </Typography>
    </div>
  )
}

Loading.propTypes = {
  text: PropTypes.string.isRequired
}

export default withStyles(styles)(Loading)
