import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'

import { EmptyArrayMessage, SectionTitle } from '../common'

import AgendaItem from './AgendaItem'
import AgendaSubheading from './AgendaSubheading'

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: theme.spacing(3) },
}))

const ListOfAgendaItems = ({ data, deleteItem, editItem, sortAgendaItems }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <SectionTitle text='Current Agenda Items' />
      <AgendaSubheading sortAgendaItems={sortAgendaItems} />
      {data.length > 0 ? (
        <Fragment>
          {data.map((item) => (
            <AgendaItem
              key={item.Id}
              currentlyInUseByAgendaItem={item.CurrentlyInUseByAgendaItem}
              deleteItem={deleteItem}
              displayName={item.DisplayName}
              editItem={editItem}
              id={item.Id}
              name={item.Name}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no Agenda Items to display' />
      )}
    </div>
  )
}

export default ListOfAgendaItems
