export const createNotificationObject = ({ key, message, variant }) => {
  return {
    key,
    message,
    options: {
      variant
    }
  }
}

export const removeNotification = ({ array, keyValue }) => {
  return array.filter(item => item.key !== keyValue)
}
