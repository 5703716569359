import React from 'react'
import { FormatBold, FormatItalic, FormatUnderlined } from '@material-ui/icons'

import StyleButton from './StyleButton'

const INLINE_STYLES = [
  { label: <FormatBold />, style: 'BOLD' },
  { label: <FormatItalic />, style: 'ITALIC' },
  { label: <FormatUnderlined />, style: 'UNDERLINE' },
]

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <div className='RichEditor-controls'>
      {INLINE_STYLES.map((type, i) => (
        <StyleButton
          key={i}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

export default InlineStyleControls
