import { action, observable } from 'mobx'
import { postDataApi } from '../services/api'
import { urls } from '../constants'
import { createNotificationObject, removeNotification } from '../helpers'

class MyAccoutStore {
  @observable isResettingPassword = false
  @observable myAccountNotifications = []

  @action handleResetPasswordResponse = (data) => {
    this.myAccountNotifications.push(
      createNotificationObject({
        key: data,
        message: 'Your password has been updated',
        variant: 'success',
      })
    )
  }

  @action removeSnackbar = (keyValue) => {
    this.myAccountNotifications = removeNotification({
      array: this.myAccountNotifications,
      keyValue,
    })

    this.isResettingPassword = false
  }

  @action resetPassword = (formValues) => {
    this.isResettingPassword = true

    const postObject = {
      NewPassword: formValues.Password,
    }

    postDataApi({
      handleSuccess: this.handleResetPasswordResponse,
      postObject,
      url: urls.UPDATE_PASSWORD_FOR_LOGGED_ON_USER,
    })
  }
}

export default new MyAccoutStore()
