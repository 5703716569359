import React, { Component, Fragment } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'

import { urls } from '../../constants'
import { getDataApi } from '../../services/api'

import Header from './Header'
import Main from './Main'
import MainUnauthenticated from './MainUnauthenticated'
import Navigation from './Navigation'

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
})
export const HeaderContext = React.createContext()

@inject('LoginStore', 'HeaderStore')
@observer
class Container extends Component {
  state = {
    mobileOpen: false,
  }

  componentDidMount() {
    if (this.props.LoginStore.userIsAuthenticated) {
      getDataApi({
        handleSuccess: (data) => this.props.HeaderStore.updateUserDetails(data),
        url: urls.GET_USER_DETAILS,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.LoginStore.userIsAuthenticated) {
      getDataApi({
        handleSuccess: (data) => this.props.HeaderStore.updateUserDetails(data),
        url: urls.GET_USER_DETAILS,
      })
    }
  }

  changeHeaderTitleViaContext = (newTitle) => {
    this.props.HeaderStore.handleTitleChange(newTitle)
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false })
  }

  render() {
    const { classes } = this.props
    const { userIsAuthenticated } = this.props.LoginStore
    const { mobileOpen } = this.state

    return (
      <div className={classes.root}>
        {!userIsAuthenticated ? (
          <MainUnauthenticated />
        ) : (
          <Fragment>
            <Header handleDrawerToggle={this.handleDrawerToggle} />
            <Navigation
              handleDrawerClose={this.handleDrawerClose}
              handleDrawerToggle={this.handleDrawerToggle}
              mobileOpen={mobileOpen}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <HeaderContext.Provider
                value={{
                  changeHeaderTitleViaContext: this.changeHeaderTitleViaContext,
                }}>
                <Main />
              </HeaderContext.Provider>
            </MuiPickersUtilsProvider>
          </Fragment>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Container)
