import React, { Fragment } from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import { DragIndicator } from '@material-ui/icons'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'

import {
  Checkbox,
  DeleteModal,
  DraggableItem,
  ListItemText,
  MenuButton,
  MenuLink,
  Popover,
} from '../common'
import { primaryColor } from '../../constants'

const useStyles = makeStyles((theme) => ({
  centered: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  dragIndicator: {
    marginRight: theme.spacing(),
    '&&:hover': {
      color: primaryColor,
      cursor: 'move',
    },
  },
  modalItemTypeLabel: {
    color: primaryColor,
  },
  modalItemTypeText: {
    fontWeight: 'bold',
  },
  modalTitle: {
    color: primaryColor,
  },
}))

const AgendaItem = ({
  AgendaId,
  AgendaItemFacilitators,
  allowedToAddItems,
  deleteItem,
  Description,
  Id,
  index,
  ItemTypeDisplay,
  ItemTypeDisplayFullName,
  meetingId,
  moveAgendaItem,
  movingAgendaItems,
  Outcome,
  TimeInMinutes,
  Title,
  AllowedToEdit,
  AllowedToDelete,
  AllowedToTakeNotes,
}) => {
  const classes = useStyles()

  const renderDescriptionContent = () => {
    return `
      <div style="display: flex; flex-direction: column">
        <Typography style="color: ${primaryColor}; font-size: 22px;">${Title}</Typography>
        <div style="display: flex; padding-top: 4px; padding-bottom: 4px;">
        <Typography style="color: ${primaryColor};">Item Type:&nbsp;</Typography>
        <Typography style="font-weight: bold">${ItemTypeDisplayFullName}</Typography>
        </div>
        <div style="padding-top: 4px; padding-bottom: 8px;">
        <Typography style="color: ${primaryColor};">Topic:</Typography>
        <Typography>${Description}</Typography>
      </div>
    `
  }

  const renderAgendaItemContent = () => {
    return (
      <Grid container className={classes.container} data-testid='agenda-item'>
        <Grid
          item
          xs={12}
          sm={2}
          style={{ display: 'flex', alignItems: 'center' }}>
          {/* {allowedToAddItems && (
            <DragIndicator className={classes.dragIndicator} />
          )} */}
          {allowedToAddItems && (
            <>
              {movingAgendaItems ? (
                <Checkbox onChange={(e) => moveAgendaItem(e, Id)} />
              ) : (
                <DragIndicator className={classes.dragIndicator} />
              )}
            </>
          )}
          <ListItemText>
            <Hidden smUp>Item: </Hidden>
            {Title}
          </ListItemText>
        </Grid>
        <Grid item xs={12} sm={1}>
          {Description && (
            <Popover content={renderDescriptionContent()}>
              <SpeakerNotesIcon />
            </Popover>
          )}
        </Grid>
        <Grid item xs={12} sm={2} className={classes.centered}>
          <ListItemText>
            <Hidden smUp>*Type: </Hidden>
            {ItemTypeDisplay}
          </ListItemText>
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          {AgendaItemFacilitators.length > 0 && (
            <Fragment>
              {AgendaItemFacilitators.map((item) => (
                <ListItemText key={item.Id}>
                  {item.FInitialLastName}
                </ListItemText>
              ))}
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={2} className={classes.centered}>
          <ListItemText>
            <Hidden smUp>Time(min): </Hidden>
            {TimeInMinutes}
          </ListItemText>
        </Grid>
        <Grid item xs={12} sm={1}>
          {Outcome && (
            <Popover content={Outcome}>
              <SpeakerNotesIcon />
            </Popover>
          )}
        </Grid>
        <Grid item xs={12} sm={2} lg={3}>
          <MenuButton>
            {AllowedToTakeNotes && (
              <MenuLink
                label={Outcome === null ? 'Take Notes' : 'Edit Notes'}
                to={`/meetingNotes/${Id}/${meetingId}`}
              />
            )}
            {AllowedToEdit && (
              <MenuLink
                label='Edit'
                to={`/createAgendaItem/${AgendaId}/${meetingId}/${Id}`}
              />
            )}
            {AllowedToDelete && (
              <DeleteModal
                confirmDeleteCallback={() => deleteItem(Id)}
                confirmDeleteLabel='Delete Agenda Item'
                confirmDeleteMessage='Are you sure you want to delete this agenda item?'
                itemName={Title}
                menuItemText='Delete'
                useMenuItem
              />
            )}
          </MenuButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {allowedToAddItems ? (
        <DraggableItem id={Id} index={index}>
          {renderAgendaItemContent()}
        </DraggableItem>
      ) : (
        <>{renderAgendaItemContent()}</>
      )}
    </>
  )
}

export default AgendaItem
