import React from 'react'
import { TextField } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  textfield: {
    width: 250,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})

const EsTextField = ({
  classes,
  dataTestId,
  label,
  name,
  onChange,
  overwriteClass,
  placeholder,
  type,
  value
}) => {
  return (
    <div>
      <TextField
        className={`${classes.textfield} ${overwriteClass}`}
        inputProps={{ 'data-testid': `${dataTestId}` }}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </div>
  )
}

export default withStyles(styles)(EsTextField)
