import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Menu, MenuLink, DeleteModal } from '../common'

const DistrictGoalItem = ({ deleteDistrictGoal, goalId, goalName }) => {
  return (
    <Grid data-testid='manage-district-goal' container key={goalId}>
      <Grid item>
        <Typography>{goalName}</Typography>
      </Grid>
      <Grid item>
        <Menu>
          <DeleteModal
            confirmDeleteCallback={() => deleteDistrictGoal(goalId)}
            confirmDeleteLabel='Delete District Goal'
            confirmDeleteMessage='Are you sure you want to delete this district goal?'
            itemName={goalName}
            menuItemText='Delete District Goal'
            useMenuItem
          />
          <MenuLink
            label='Edit District Goal'
            to={`/createDistrictGoal/${goalId}`}
          />
        </Menu>
      </Grid>
    </Grid>
  )
}

export default DistrictGoalItem
