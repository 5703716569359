import React, { Fragment } from 'react'
import { AutoSizer, List } from 'react-virtualized'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { Checkbox, EmptyArrayMessage, Subheading, UserAvatar } from '../common'
import { getFirstNameLastNameInitials } from '../../helpers'
import { subheadingColor } from '../../constants'

const ROW_HEIGHT = 60

const useStyles = makeStyles(theme => ({
  autosizerWrapper: {
    [theme.breakpoints.only('xs')]: {
      height: '100vh'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
      height: 'calc(100vh - 450px)'
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 400px)'
    }
  },
  container: {
    borderBottom: `1px solid ${subheadingColor}`,
    padding: theme.spacing()
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  },
  delete: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing()
  },
  stafferName: {
    paddingLeft: theme.spacing()
  },
  stafferNameContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const UsersInTeamVl = ({
  data,
  handleTeamLeadChange,
  removeStafferFromTeam,
  userTypeId
}) => {
  const classes = useStyles()

  const renderRow = ({ index, style }) => {
    const userId = data[index].UserId
    const firstName = data[index].FirstName
    const lastName = data[index].LastName
    const isTeamLead = data[index].IsTeamLead
    return (
      <Grid
        container
        alignItems='center'
        className={classes.container}
        key={userId}
        style={style}>
        <Grid item xs={6} className={classes.stafferNameContainer}>
          <UserAvatar
            index={index}
            name={getFirstNameLastNameInitials(firstName, lastName)}
          />
          <Typography className={classes.stafferName}>
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.centered}>
          {userTypeId === 1 && (
            <Checkbox
              checked={isTeamLead}
              onChange={e => handleTeamLeadChange(userId, e)}
            />
          )}
        </Grid>
        <Grid item xs={3} className={classes.centered}>
          <Delete
            className={classes.delete}
            onClick={() => removeStafferFromTeam(userId)}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          <Subheading>
            <Grid item sm={6}>
              <Typography>
                {userTypeId === 1 && 'Staffers'}
                {userTypeId === 2 && 'Students'}
                {userTypeId === 3 && 'Partners'}
              </Typography>
            </Grid>
            <Grid item sm={3} className={classes.centered}>
              <Typography>{userTypeId === 1 ? 'Team Lead' : null}</Typography>
            </Grid>
            <Grid item sm={3} className={classes.centered}>
              <Typography>Remove</Typography>
            </Grid>
          </Subheading>
          <div className={classes.autosizerWrapper}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <List
                    width={width}
                    height={height}
                    rowHeight={ROW_HEIGHT}
                    rowRenderer={renderRow}
                    rowCount={data.length}
                    overscanRowCount={5}
                  />
                )
              }}
            </AutoSizer>
          </div>
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no team members.' />
      )}
    </Fragment>
  )
}

export default UsersInTeamVl
