import React from 'react'
import PropTypes from 'prop-types'
import { Button, withStyles } from '@material-ui/core'
import { secondaryColor } from '../../constants'

const styles = theme => ({
  button: {
    color: '#FFF',
    backgroundColor: secondaryColor,

    fontSize: 13,

    maxWidth: 250,

    paddingTop: 4,
    paddingRight: 10,
    paddingBottom: 4,
    paddingLeft: 10,

    '&:hover': {
      backgroundColor: '#6abf69',
      color: '#000'
    }
  }
})

const EsButton = ({
  classes,
  dataTestId,
  disabled,
  label,
  onClick,
  overwriteClass
}) => (
  <Button
    data-testid={dataTestId}
    className={[classes.button, overwriteClass].join(' ')}
    disabled={disabled}
    onClick={onClick}
    variant='contained'>
    {label}
  </Button>
)

EsButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(EsButton)
