import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { List } from '../ManageTeams'
import { Loading } from '../common'

@inject('HeaderStore', 'ManageTeamsStore')
@observer
class ManageTeams extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Manage Teams')
    this.props.ManageTeamsStore.getTeams()
  }

  render() {
    const {
      deleteTeam,
      deleteTeamMember,
      getTeamDetails,
      isDeletingTeam,
      isDeletingTeamMember,
      isLoadingData,
      isLoadingTeamDetails,
      teams
    } = this.props.ManageTeamsStore

    if (isDeletingTeam) return <Loading text='Deleting Team' />
    if (isDeletingTeamMember) return <Loading text='Removing Team Member' />
    if (isLoadingData) return <Loading text='Loading Teams' />
    if (isLoadingTeamDetails) return <Loading text='Loading Team Details' />

    return (
      <List
        data={teams}
        deleteTeam={deleteTeam}
        deleteTeamMember={deleteTeamMember}
        getTeamDetails={getTeamDetails}
      />
    )
  }
}

export default ManageTeams
