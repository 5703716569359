import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Hidden, Typography, withStyles } from '@material-ui/core'

import {
  Button,
  Editor,
  Loading,
  Paper,
  TitleDateTimeLocation,
} from '../common'
import { primaryColor } from '../../constants'

import ListOfAssignments from '../MeetingNotes/ListOfAssignments'

const styles = (theme) => ({
  agendaItemTypeContainer: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  agendaTypeName: {
    fontWeight: 'bold',
  },
  paper: {
    padding: theme.spacing(),
    maxHeight: 292,
  },
  notesHeadings: {
    color: primaryColor,
  },
})

@inject('HeaderStore', 'MeetingNotesStore')
@observer
class MeetingNotes extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Meeting Notes')
    this.props.MeetingNotesStore.getData(
      this.props.agendaId,
      this.props.meetingId
    )
  }

  render() {
    const { classes } = this.props
    const {
      addAssignmentItem,
      agendaItemDescription,
      agendaItemId,
      agendaItemOutcome,
      agendaItemTitle,
      agendaItemTypeName,
      deleteTaskAssignment,
      handleAgendaItemOutcomeChange,
      isDeletingData,
      isLoadingData,
      isSavingData,
      meetingDateEndTime,
      meetingDateStartTime,
      meetingLocation,
      noteTakerName,
      saveAndClose,
      saveOutcome,
      sortTasks,
      tasks,
      teamId,
    } = this.props.MeetingNotesStore

    if (isDeletingData) return <Loading text='Deleting Task Assignment' />
    if (isLoadingData) return <Loading text='Loading Meeting Notes' />
    if (isSavingData) return <Loading text='Saving Meeting Notes' />

    return (
      <div>
        <TitleDateTimeLocation
          endTime={meetingDateEndTime}
          location={meetingLocation}
          startTime={meetingDateStartTime}
          title={agendaItemTitle}
          noteTaker={noteTakerName}
        />
        <Grid container spacing={2}>
          <Grid item sm={12} md={agendaItemDescription ? 6 : 12}>
            <Editor
              data={agendaItemOutcome}
              onChange={handleAgendaItemOutcomeChange}
            />
          </Grid>
          <Hidden xsDown>
            {agendaItemDescription && (
              <Grid item sm={12} md={6}>
                <Paper overwrite={classes.paper}>
                  <div className={classes.agendaItemTypeContainer}>
                    <Typography className={classes.notesHeadings}>
                      Item Type:&nbsp;
                    </Typography>
                    <Typography className={classes.agendaTypeName}>
                      {agendaItemTypeName}
                    </Typography>
                  </div>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: agendaItemDescription }}
                  />
                </Paper>
              </Grid>
            )}
          </Hidden>
        </Grid>

        <Grid container spacing={1}>
          <Grid item>
            <Button
              label='Save Discussion Notes'
              onClick={() => saveOutcome()}
            />
          </Grid>
          <Grid item>
            <Button
              label={`Save & Close Topic`}
              onClick={() => saveAndClose()}
            />
          </Grid>
        </Grid>
        <ListOfAssignments
          agendaItemId={agendaItemId}
          data={tasks}
          deleteTaskAssignment={deleteTaskAssignment}
          meetingId={this.props.meetingId}
          save={addAssignmentItem}
          saveMeetingNotes={saveOutcome}
          sortTasks={sortTasks}
          tasks={tasks}
          teamId={teamId}
        />
      </div>
    )
  }
}

export default withStyles(styles)(MeetingNotes)
