import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'
import { sortBy } from '../helpers'
import { createNotificationObject, removeNotification } from '../helpers'
import { urls } from '../constants'

class StudentNotesStore {
  @observable isDeletingData = false
  @observable isLoadingData = true

  @observable studentNotesNotifications = []

  @observable firstName = ''
  @observable graduationYear = ''
  @observable lastName = ''
  @observable userFullName = ''
  @observable studentNotes = []
  @observable userId = ''

  deleteItem = userNoteId => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteItemResponse,
      itemId: userNoteId,
      url: `${urls.DELETE_USER_NOTE}${userNoteId}`
    })
  }

  getData(userId) {
    this.isLoadingData = true
    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: `${urls.GET_STUDENT_NOTES_BY_USER_ID}${userId}`
    })
  }

  @action handleDeleteItemResponse = id => {
    const notificationObject = createNotificationObject({
      key: id,
      message: 'Student Note has been deleted.',
      variant: 'success'
    })

    this.studentNotesNotifications.push(notificationObject)
    this.removeStudentNoteFromArray(id)

    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.firstName = data.FirstName
    this.graduationYear = data.GraduationYear
    this.lastName = data.LastName
    this.studentNotes = data.UserNotes
    this.userId = data.UserId
    this.userFullName = data.UserFullName

    this.isLoadingData = false
  }

  @action removeSnackbar = keyValue => {
    this.studentNotesNotifications = removeNotification({
      array: this.studentNotesNotifications,
      keyValue
    })
  }

  @action removeStudentNoteFromArray = id => {
    this.studentNotes = this.studentNotes.filter(item => item.Id !== id)
  }

  @action sortStudentNotes = (sortByValue, asc) => {
    let array = this.studentNotes.slice()
    array.sort(sortBy(sortByValue, !asc))

    this.studentNotes = array
  }
}

export default new StudentNotesStore()
