import React, { Fragment } from 'react'
import { Grid, Hidden, Tab, Tabs, makeStyles } from '@material-ui/core'
import { SectionTitle } from '../common'
//import ListOfTeamMembers from '../screens/createTeams/ListOfTeamMembers'
import UsersInTeamVL from './UsersInTeamVL'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: theme.spacing()
  }
}))

const UsersInTeam = ({
  handleTabChange,
  handleTeamLeadChange,
  partnersInTeam,
  removeStafferFromTeam,
  selectedTab,
  staffersInTeam,
  studentsInTeam
}) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Hidden smDown>
        <SectionTitle text='Users in this Team' />
      </Hidden>

      <Grid item xs={12}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={handleTabChange}>
          <Tab label={`Staffers (${staffersInTeam.length})`} />
          <Tab label={`Students (${studentsInTeam.length})`} />
          <Tab label={`Partners (${partnersInTeam.length})`} />
        </Tabs>
      </Grid>
      {selectedTab === 0 && (
        <UsersInTeamVL
          data={staffersInTeam}
          handleTeamLeadChange={handleTeamLeadChange}
          removeStafferFromTeam={removeStafferFromTeam}
          userTypeId={1}
        />
      )}
      {selectedTab === 1 && (
        <UsersInTeamVL
          data={studentsInTeam}
          handleTeamLeadChange={handleTeamLeadChange}
          removeStafferFromTeam={removeStafferFromTeam}
          userTypeId={2}
        />
      )}
      {selectedTab === 2 && (
        <UsersInTeamVL
          data={partnersInTeam}
          handleTeamLeadChange={handleTeamLeadChange}
          removeStafferFromTeam={removeStafferFromTeam}
          userTypeId={3}
        />
      )}
    </Fragment>
  )
}

export default UsersInTeam
