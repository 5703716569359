import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { primaryColor } from '../../constants'

const styles = (theme) => ({
  text: {
    color: primaryColor,
    marginBottom: theme.spacing(),
    fontSize: 21,
    fontWeight: 300,
  },
})

const SectionTitle = ({ classes, text, overwriteClass }) => (
  <Typography
    className={[classes.text, overwriteClass].join(' ')}
    data-testid='section-title'>
    {text}
  </Typography>
)

export default withStyles(styles)(SectionTitle)
