import { action, observable } from 'mobx'
import { deleteDataApi, getDataApi } from '../services/api'
import { urls } from '../constants'
import { removeNotification, sortArrayByFieldName } from '../helpers'

class ManageTeamsStore {
  @observable isDeletingTeam = false
  @observable isDeletingTeamMember = false
  @observable isLoadingData = false
  @observable isLoadingTeamDetails = false

  @observable currentTeamId = ''

  @observable manageTeamsNotifications = []

  @observable teams = []

  @action deleteTeam = teamId => {
    this.isDeletingTeam = true

    deleteDataApi({
      handleSuccess: this.handleDeleteTeamResponse,
      itemId: teamId,
      url: `${urls.DELETE_TEAM}${teamId}`
    })
  }

  @action deleteTeamMember = userTeamId => {
    const x = this.teams.filter(
      team =>
        team.UsersInTeam &&
        team.UsersInTeam.filter(user => user.Id !== userTeamId)
    )

    this.currentTeamId = x[0].Id

    deleteDataApi({
      handleSuccess: this.handleDeleteTeamMemberResponse,
      itemId: userTeamId,
      url: `${urls.DELETE_USER_FROM_TEAM}${userTeamId}`
    })
  }

  @action getTeamDetails = teamId => {
    this.isLoadingTeamDetails = true
    this.currentTeamId = ''

    getDataApi({
      handleSuccess: this.handleGetTeamDetailsResponse,
      url: `${urls.GET_USERS_BY_TEAM_ID}${teamId}`
    })
  }

  @action getTeams = () => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetTeamsResponse,
      url: urls.GET_TEAM_BY_LOCATION_ID
    })
  }

  @action handleDeleteTeamResponse = id => {
    this.teams.replace(this.teams.filter(team => team.Id !== id))

    this.isDeletingTeam = false

    this.manageTeamsNotifications.push({
      key: id,
      message: 'Team has been deleted.',
      options: {
        variant: 'success'
      }
    })
  }

  @action handleDeleteTeamMemberResponse = id => {
    this.manageTeamsNotifications.push({
      key: id,
      message: 'Team Member has been deleted.',
      options: {
        variant: 'success'
      }
    })

    this.isDeletingTeamMember = false
    this.getTeamDetails(this.currentTeamId)
  }

  @action handleGetTeamDetailsResponse = data => {
    const teams = this.teams.slice()
    const team = teams.find(x => x.Id === data.Id)
    team.UsersInTeam = data.UsersInTeam

    this.teams = teams
    this.isLoadingTeamDetails = false
  }

  @action handleGetTeamsResponse = data => {
    this.teams = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Name'
    })
    this.isLoadingData = false
  }

  @action removeSnackbar = keyValue => {
    this.manageTeamsNotifications = removeNotification({
      array: this.manageTeamsNotifications,
      keyValue
    })
  }
}

export default new ManageTeamsStore()
