import { Component } from 'react'
import { withSnackbar } from 'notistack'
import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'

@inject(
  'AssignTaskStore',
  'CreateAgendaStore',
  'CreateGroupGoalStore',
  'CreateGroupStore',
  'CreateMeetingStore',
  'CreateStafferStore',
  'CreateStudentCommentStore',
  'CreateStudentNoteStore',
  'CreateStudentStore',
  'CreateTaskStore',
  'GroupNormsStore',
  'GroupStore',
  'ManageAgendaTypesStore',
  'ManageStaffStore',
  'ManageStudentsStore',
  'ManageTeamsStore',
  'MeetingAgendaStore',
  'MeetingNotesStore',
  'MeetingsStore',
  'MyAccountStore',
  'StudentGroupsStore',
  'StudentNotesStore',
  'TaskNotesStore',
  'TaskStore'
)
@observer
class Notifier extends Component {
  displayed = []

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id]
  }

  componentDidMount() {
    autorun(() => {
      const { assignTaskNotifications } = this.props.AssignTaskStore
      const { createAgendaNotifications } = this.props.CreateAgendaStore
      const { createGroupGoalNotifications } = this.props.CreateGroupGoalStore
      const { createGroupNotifications } = this.props.CreateGroupStore
      const { createMeetingNotifications } = this.props.CreateMeetingStore
      const { createStafferNotifications } = this.props.CreateStafferStore
      const {
        createStudentCommentNotifications
      } = this.props.CreateStudentCommentStore
      const {
        createStudentNoteNotifications
      } = this.props.CreateStudentNoteStore
      const { createStudentNotifications } = this.props.CreateStudentStore
      const { createTaskNotifications } = this.props.CreateTaskStore
      const { groupNormsNotifications } = this.props.GroupNormsStore
      const { agendaItemsNotifications } = this.props.ManageAgendaTypesStore
      const { manageStafferNotifications } = this.props.ManageStaffStore
      const { manageStudentsNotifications } = this.props.ManageStudentsStore
      const { manageTeamsNotifications } = this.props.ManageTeamsStore
      const { meetingAgendaNotifications } = this.props.MeetingAgendaStore
      const { meetingNotesNotifications } = this.props.MeetingNotesStore
      const { myAccountNotifications } = this.props.MyAccountStore
      const { studentGroupsNotifications } = this.props.StudentGroupsStore
      const { studentNotesNotifications } = this.props.StudentNotesStore
      const { taskAssignmentNotifications } = this.props.TaskStore
      const { taskNotesNotifications } = this.props.TaskNotesStore
      const { teamNotifications } = this.props.GroupStore
      const { upcomingMeetingsNotifications } = this.props.MeetingsStore

      const notifications = [
        {
          array: agendaItemsNotifications,
          store: this.props.ManageAgendaTypesStore
        },
        {
          array: assignTaskNotifications,
          store: this.props.AssignTaskStore
        },
        {
          array: createAgendaNotifications,
          store: this.props.CreateAgendaStore
        },
        {
          array: createGroupGoalNotifications,
          store: this.props.CreateGroupGoalStore
        },
        {
          array: createGroupNotifications,
          store: this.props.CreateGroupStore
        },
        {
          array: createMeetingNotifications,
          store: this.props.CreateMeetingStore
        },
        {
          array: createStafferNotifications,
          store: this.props.CreateStafferStore
        },
        {
          array: createStudentCommentNotifications,
          store: this.props.CreateStudentCommentStore
        },
        {
          array: createStudentNoteNotifications,
          store: this.props.CreateStudentNoteStore
        },
        {
          array: createStudentNotifications,
          store: this.props.CreateStudentStore
        },
        {
          array: createTaskNotifications,
          store: this.props.CreateTaskStore
        },
        {
          array: groupNormsNotifications,
          store: this.props.GroupNormsStore
        },
        {
          array: manageStafferNotifications,
          store: this.props.ManageStaffStore
        },
        {
          array: manageStudentsNotifications,
          store: this.props.ManageStudentsStore
        },
        {
          array: manageTeamsNotifications,
          store: this.props.ManageTeamsStore
        },
        {
          array: meetingAgendaNotifications,
          store: this.props.MeetingAgendaStore
        },
        {
          array: meetingNotesNotifications,
          store: this.props.MeetingNotesStore
        },
        {
          array: myAccountNotifications,
          store: this.props.MyAccountStore
        },
        {
          array: studentGroupsNotifications,
          store: this.props.StudentGroupsStore
        },
        {
          array: studentNotesNotifications,
          store: this.props.StudentNotesStore
        },
        {
          array: taskAssignmentNotifications,
          store: this.props.TaskStore
        },
        { array: taskNotesNotifications, store: this.props.TaskNotesStore },
        { array: teamNotifications, store: this.props.GroupStore },
        {
          array: upcomingMeetingsNotifications,
          store: this.props.MeetingsStore
        }
      ]

      notifications.forEach(obj => {
        obj.array.forEach(notification => {
          // Do nothing if snackbar is already displayed
          if (this.displayed.includes(notification.key)) return

          // Display snackbar using notistack
          this.props.enqueueSnackbar(notification.message, notification.options)

          // Keep track of snackbars that we've displayed
          this.storeDisplayed(notification.key)

          // Dispatch action to remove snackbar from mobx store
          obj.store.removeSnackbar(notification.key)
        })
      })
    })
  }

  render() {
    return null
  }
}

const WithSnackbarNotifier = withSnackbar(Notifier)

export default WithSnackbarNotifier
