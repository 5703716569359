import { getIn } from 'formik'
import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  selectField: {
    width: 300,

    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const SelectFormField = ({
  customCallback,
  dataTestId,
  field,
  fieldValue,
  form,
  label,
  menuItems,
  ...props
}) => {
  const classes = useStyles()
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <FormControl className={classes.selectField} error={!!errorText}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        inputProps={{ 'data-testid': `${dataTestId}` }}
        {...field}
        {...props}>
        {menuItems.map((item) => (
          <MenuItem
            data-testid='select-form-field-option'
            key={item.value}
            value={item.value}>
            {item.description}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

export default SelectFormField
