import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { Button, DeleteModal, HiddenLabel } from '../common'
import { subheadingColor, editButtonStyle } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(),

    borderBottom: `1px solid ${subheadingColor}`,
  },
  editButton: {
    ...editButtonStyle,

    marginRight: theme.spacing(),
  },
  item: {
    display: 'flex',

    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
}))

const AgendaItem = ({
  currentlyInUseByAgendaItem,
  deleteItem,
  displayName,
  editItem,
  id,
  name,
}) => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.root}
      container
      alignItems='center'
      data-testid='agenda-item'>
      <Grid
        className={classes.item}
        item
        xs={12}
        sm={4}
        data-testid='item-name'>
        <HiddenLabel>Item Name:</HiddenLabel>
        {name}
      </Grid>
      <Grid
        className={classes.item}
        item
        xs={12}
        sm={4}
        data-testid='item-abbreviation'>
        <HiddenLabel>Abbreviation:</HiddenLabel>
        {displayName}
      </Grid>
      <Grid item sm={4}>
        <Button
          label='Edit'
          onClick={() => editItem(id)}
          overwriteClass={classes.editButton}
        />
        {!currentlyInUseByAgendaItem && (
          <DeleteModal
            confirmDeleteCallback={() => deleteItem(id)}
            confirmDeleteLabel='Delete Agenda Item'
            confirmDeleteMessage='Are you sure you want to delete this agenda type?'
            itemName={displayName}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default AgendaItem
