import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { avatarColors } from '../../constants'

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(),
    height: 30,
    width: 30,
    fontSize: 11
  },
  color1: {
    backgroundColor: avatarColors[0]
  },
  color2: {
    backgroundColor: avatarColors[1]
  },
  color3: {
    backgroundColor: avatarColors[2]
  },
  color4: {
    backgroundColor: avatarColors[3]
  },
  color5: {
    backgroundColor: avatarColors[4]
  },
  color6: {
    backgroundColor: avatarColors[5]
  }
}))

const UserAvatar = ({ index, name }) => {
  const classes = useStyles(index)
  const generateClass = index => {
    if (index % 6 === 0) {
      return classes.color6
    } else if (index % 6 === 5) {
      return classes.color5
    } else if (index % 6 === 4) {
      return classes.color4
    } else if (index % 6 === 3) {
      return classes.color3
    } else if (index % 6 === 2) {
      return classes.color2
    } else {
      return classes.color1
    }
  }

  return (
    <Avatar className={[classes.avatar, generateClass(index)].join(' ')}>
      {name}
    </Avatar>
  )
}

export default UserAvatar
