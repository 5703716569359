import React from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { ListItemText } from '../common'

const styles = (theme) => ({
  italicize: {
    color: 'blue',
    fontStyle: 'italic',
  },
  link: {
    textDecorationColor: 'blue',
  },
  linkText: {
    color: 'blue',
  },
})

const InternalLink = ({ classes, dataTestId, italicize, label, to }) => {
  return (
    <Link className={classes.link} data-testid={dataTestId} to={to}>
      <ListItemText
        overwriteClass={italicize ? classes.italicize : classes.linkText}>
        {label}
      </ListItemText>
    </Link>
  )
}

InternalLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default withStyles(styles)(InternalLink)
