import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { LinkButton, SectionTitle, TitleDateTimeLocation } from '../common'

@inject('HeaderStore')
@observer
class PostedAgenda extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Meeting Agenda Posted')
  }

  render() {
    const {
      agendaTitle,
      meetingEndTime,
      meetingLocation,
      meetingStartTime,
      usersInMeeting,
      noteTakerName,
    } = this.props

    return (
      <div>
        <SectionTitle text='The meeting agenda has been successfully posted.' />
        <div style={{ paddingLeft: 24 }}>
          <TitleDateTimeLocation
            endTime={meetingEndTime}
            location={meetingLocation}
            startTime={meetingStartTime}
            title={agendaTitle}
            noteTaker={noteTakerName}
          />
        </div>
        <SectionTitle text='The following team members have been notified of the agenda' />
        <ul>
          {usersInMeeting.map((item) => (
            <li key={item.id}>{item.FullName}</li>
          ))}
        </ul>
        <LinkButton label='Go to Meetings Page' to='/meetings' />
      </div>
    )
  }
}

export default PostedAgenda
