import { action, observable } from 'mobx'
import moment from 'moment'
import { getDataApi, postDataApi } from '../services/api'
import { urls } from '../constants'
import {
  setupMenuItems,
  sortArrayByFieldName,
  removeNotification,
  createNotificationObject,
} from '../helpers'
import { navigate } from '@reach/router'

class AssignTaskStore {
  @observable isLoadingData = false
  @observable isSavingData = false

  @observable agendaItems = []
  @observable assignTaskNotifications = []
  @observable dateDue = moment()
  @observable goals = []
  @observable meetingId = ''
  @observable meetings = []
  @observable users = []
  @observable selectedAgendaItem = ''
  @observable selectedGoal = ''
  @observable selectedMeeting = ''
  @observable selectedUser = ''
  @observable todoItem = ''
  @observable userId = ''
  @observable goalId = ''
  @observable pageFromId = 0

  /* 
  Temp comment to test pipeline!
  */

  /*
  PageFromId
  0 - From My Teams Page
  1 - From Team Goal Page
  2 - From My Tasks Dashboard
  */

  @action getAgendaItems = (meetingId) => {
    getDataApi({
      handleSuccess: this.handleGetAgendaItemsResponse,
      url: `${urls.GET_AGENDA_ITEMS_BY_MEETING_ID}${meetingId}`,
    })
  }

  @action getGoalItems = (meetingId) => {
    getDataApi({
      handleSuccess: this.handleGetGoalsResponse,
      url: `${urls.GET_GOAL_ITEMS_BY_MEETING_ID}${meetingId}`,
    })
  }

  @action getMeetingItemsByUserAndGoal = (selectedUser, goalId) => {
    getDataApi({
      handleSuccess: this.handleGetMeetingItemsResponse,
      url: `${urls.GET_MEETINGS_BY_USER_ID_AND_GOAL_ID}${selectedUser}/${goalId}`,
    })
  }

  @action getData = (teamId, userId, goalId) => {
    this.isLoadingData = true
    this.userId = userId
    this.goalId = goalId
    //called from the my tasks dashboard
    if (teamId === '0' && goalId === '0') {
      this.pageFromId = 2
      getDataApi({
        handleSuccess: this.handleGetMeetingsResponse,
        url: `${urls.GET_MEETINGS_FOR_TASK_ASSIGNMENT_BY_USER_ID}${userId}`,
      })
      getDataApi({
        handleSuccess: this.handleGetCurrentlyLoggedInUserFullNameResponse,
        url: `${urls.GET_CURRENTLY_LOGGED_IN_USER_FULL_NAME}`,
      })

      this.isLoadingData = false
    }
    //Called from the My Teams task list create task
    else if (goalId === '0') {
      this.pageFromId = 0
      getDataApi({
        handleSuccess: this.handleGetGoalsResponse,
        url: `${urls.GET_GOALS_BY_TEAM_ID}${teamId}`,
      })

      getDataApi({
        handleSuccess: this.handleGetUsersResponse,
        url: `${urls.GET_USERS_BY_TEAM_ID}${teamId}`,
      })

      getDataApi({
        handleSuccess: this.handleGetMeetingsResponse,
        url: `${urls.GET_MEETINGS_FOR_TASK_ASSIGNMENT_BY_USER_ID}${userId}`,
      })
    } else {
      this.pageFromId = 1
      getDataApi({
        handleSuccess: this.handleGetUsersByGoalIdResponse,
        url: `${urls.GET_USERS_BY_GOAL_ID}${goalId}`,
      })
      this.selectedUser = ''
      getDataApi({
        handleSuccess: this.handleGetSelectedGoalResponse,
        url: `${urls.GET_GOAL_BY_GOAL_ID}${goalId}`,
      })
    }
  }

  @action handleGetAgendaItemsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Title',
    })

    this.agendaItems = setupMenuItems({
      data: sortedData,
      descriptionField: 'Title',
      valueField: 'Id',
    })
  }

  @action handleGetMeetingItemsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Subject',
    })

    this.meetings = setupMenuItems({
      data: sortedData,
      descriptionField: 'Subject',
      valueField: 'Id',
    })
  }

  @action handleGetGoalsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'Title',
    })

    this.goals = setupMenuItems({
      data: sortedData,
      descriptionField: 'Title',
      valueField: 'Id',
    })
  }

  @action handleGetMeetingsResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'MeetingName',
    })

    this.meetings = setupMenuItems({
      data: sortedData,
      descriptionField: 'MeetingName',
      valueField: 'MeetingId',
    })
  }

  @action handleGetCurrentlyLoggedInUserFullNameResponse = (data) => {
    this.selectedUser = data
  }

  @action handleGetUsersResponse = (data) => {
    const selectedUser = data.UsersInTeam.find(
      (user) => user.UserId === this.userId
    )

    this.selectedUser = selectedUser.FirstName + ' ' + selectedUser.LastName
    this.isLoadingData = false
  }

  @action handleGetSelectedGoalResponse = (data) => {
    this.selectedGoal = data.Title
    this.isLoadingData = false
  }

  @action handleGetUsersByGoalIdResponse = (data) => {
    const sortedData = sortArrayByFieldName({
      array: data,
      ascValue: true,
      field: 'FullName',
    })

    this.users = setupMenuItems({
      data: sortedData,
      descriptionField: 'FullName',
      valueField: 'Id',
    })
  }

  @action handleSaveResponse = (data) => {
    console.log(data)

    this.assignTaskNotifications.push(
      createNotificationObject({
        key: data,
        message: 'Task has been assigned.',
        variant: 'success',
      })
    )

    this.isSavingData = false
    if (this.pageFromId === 0) {
      navigate('/myTeams')
    } else if (this.pageFromId === 1) {
      navigate('/teamTasks/' + this.goalId)
    } else {
      navigate('/')
    }
  }

  @action removeSnackbar = (keyValue) => {
    this.assignTaskNotifications = removeNotification({
      array: this.assignTaskNotifications,
      keyValue,
    })
  }

  @action save = (formValues) => {
    this.isSavingData = true

    const assignedUserId =
      this.userId === '0' ? formValues.selectedUser : this.userId

    const postObject = {
      AgendaItemId: formValues.selectedAgendaItem,
      MeetingId: formValues.selectedMeeting,
      GoalId: this.goalId === '0' ? formValues.selectedGoal : this.goalId,
      Id: 0,
      Title: formValues.todoItem,
      DueDate: moment(formValues.dateDue).format('YYYY-MM-DD'),
      AssignedToUserId: assignedUserId,
    }
    console.log(postObject)
    console.log(formValues)
    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject,
      url: `${urls.SAVE_TASK_FROM_ASSIGN_TASK}`,
    })
  }
}

export default new AssignTaskStore()
