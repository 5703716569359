import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import { Loading, SectionTitle, InternalLink, LinkButton } from '../common'
import { Completed, InProgress } from '../GoalTasks'
import { Subheading } from '../Tasks'

const styles = (theme) => ({
  pageHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabs: {
    marginBottom: theme.spacing(),
  },
  sumbitButton: { paddingTop: theme.spacing() },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

@inject('HeaderStore', 'GroupTasksStore')
@observer
class GroupTasksScreen extends Component {
  state = {
    selectedTab: 0,
  }

  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Goal Tasks')
    this.props.GroupTasksStore.getGoalStatus(this.props.goalId)
  }

  handleTabChange = (e, value) => {
    this.setState({ selectedTab: value })
  }

  render() {
    const { classes, goalId } = this.props
    const {
      isLoadingData,
      sortByValue,
      goalStatusId,
    } = this.props.GroupTasksStore
    const { selectedTab } = this.state

    if (isLoadingData) return <Loading text='Loading Task Assignments' />

    return (
      <Fragment>
        <div className={classes.pageHeading}>
          <SectionTitle text='Task Assignments' />
          <InternalLink label={`< Back to Team Goals`} to='/teamGoals' />
        </div>

        <div>
          {goalStatusId !== 2 && (
            <>
              <div className={this.props.classes.buttonContainer}>
                <LinkButton
                  label='Create New Task'
                  to={`/assignTask/0/0/${this.props.goalId}`}
                />
              </div>
            </>
          )}
        </div>

        <Tabs
          className={classes.tabs}
          onChange={this.handleTabChange}
          value={selectedTab}>
          <Tab label='Active Goal Tasks' />
          <Tab label='Completed Goal Tasks' />
        </Tabs>
        <Subheading sortByValue={sortByValue} />
        {selectedTab === 0 && <InProgress goalId={goalId} />}
        {selectedTab === 1 && <Completed goalId={goalId} />}
      </Fragment>
    )
  }
}

export default withStyles(styles)(GroupTasksScreen)
