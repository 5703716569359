import React, { Fragment } from 'react'
import NoteItem from './NoteItem'
import { EmptyArrayMessage } from '../common'

const ListOfStudentNotes = ({ data, deleteItem, groupId, userId }) => {
  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map(item => (
            <NoteItem
              key={item.Id}
              commentCount={item.UserNoteCommentCount}
              datePosted={item.DatePosted}
              deleteItem={deleteItem}
              groupId={groupId}
              id={item.Id}
              note={item.Note}
              postedBy={item.PostedByFInitialLastName}
              title={item.Title}
              userId={userId}
            />
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage text='There are no students notes to display.' />
      )}
    </Fragment>
  )
}

export default ListOfStudentNotes
