import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  DeleteModal,
  EmptyArrayMessage,
  Menu,
  MenuLink,
  SectionTitle,
} from '../common'
import { flexRow } from '../../constants'
import TeamMemberList from './TeamMembersList'
import TeamSubheading from './TeamSubheading'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  title: {
    ...flexRow,
  },
}))

const TeamsList = ({
  deleteTeam,
  deleteTeamMember,
  isAdmin,
  loggedInUserId,
  teams,
}) => {
  const classes = useStyles()

  const isLoggedInUserTeamLead = (teamMembers) => {
    let isTeamLead

    const loggedInUser = teamMembers.find(
      (member) => member.UserId === loggedInUserId
    )

    if (loggedInUser.IsTeamLead) {
      isTeamLead = true
    } else {
      isTeamLead = false
    }

    return isTeamLead
  }

  const isLoggedInUserAdminOrTeamLead = (teamMembers) => {
    let shouldRender

    if (isAdmin) {
      shouldRender = true
    } else if (isLoggedInUserTeamLead(teamMembers) === true) {
      shouldRender = true
    } else {
      shouldRender = false
    }

    return shouldRender
  }

  return (
    <Fragment>
      {teams.length > 0 ? (
        <Fragment>
          {teams.map((item, index) => (
            <div className={classes.container} key={item.TeamId}>
              <span className={classes.title}>
                <SectionTitle text={item.TeamName} />
                <Menu>
                  {isLoggedInUserAdminOrTeamLead(item.TeamMembers) && (
                    <div>
                      <DeleteModal
                        confirmDeleteCallback={() => deleteTeam(item.TeamId)}
                        confirmDeleteLabel='Delete Team'
                        confirmDeleteMessage='Are you sure you want to delete this entire team?'
                        itemName={item.TeamName}
                        menuItemText='Delete Team'
                        useMenuItem
                      />
                      <MenuLink
                        label='Edit Team'
                        to={`/createTeam/${item.TeamId}`}
                      />
                    </div>
                  )}

                  <MenuLink
                    label='Team Norms'
                    to={`/groupNorms/${item.TeamId}`}
                  />
                  <MenuLink
                    label='View Students'
                    to={`/studentGroups/${item.TeamId}`}
                  />
                </Menu>
              </span>
              <TeamSubheading teamId={item.TeamId} />
              <TeamMemberList
                data={item.TeamMembers}
                deleteTeamMember={deleteTeamMember}
                index={index}
                isLoggedInUserAdminOrTeamLead={isLoggedInUserAdminOrTeamLead(
                  item.TeamMembers
                )}
                teamId={item.TeamId}
              />
            </div>
          ))}
        </Fragment>
      ) : (
        <EmptyArrayMessage
          data-test='message-component'
          text='You are currently not in any groups.  To get started, please click the Create New Group Button'
        />
      )}
    </Fragment>
  )
}

export default TeamsList
