import React, { useEffect, useState } from 'react'
import { Redirect } from '@reach/router'

import { urls } from '../../constants'
import { InternalLink, Loading } from '../common'
import { postDataApi } from '../../services/api'

const ZoomIntegrationResponse = (props) => {
  const [isPosting, setIsPosting] = useState(true)
  const [finishedPosting, setFinishedPosting] = useState(false)

  useEffect(() => {
    setIsPosting(true)
    console.log(props.location.search)

    const trimmedResponse = props.location.search.substr(6)
    console.log(trimmedResponse)

    postDataApi({
      handleSuccess: () => {
        setIsPosting(false)
        setFinishedPosting(true)
      },
      postObject: {},
      url: `${urls.SAVE_ZOOM_RESPONSE}${trimmedResponse}`,
    })
  }, [])

  if (finishedPosting) return <Redirect to='/myAccount' />

  if (isPosting) return <Loading text='Finalizing Zoom Integration' />

  return (
    <div>
      <InternalLink label='Back to the studio' to='/myAccount' />
    </div>
  )
}

export default ZoomIntegrationResponse
