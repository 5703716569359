import { action, observable } from 'mobx'
import moment from 'moment'
import { navigate } from '@reach/router'
import { getDataApi, postDataApi } from '../services/api'
import { urls } from '../constants'
import {
  convertDateTimeToUTC,
  setupMenuItems,
  createNotificationObject,
  removeNotification,
  getLocalDateTimeFromUTC,
} from '../helpers'

class CreateMeetingStore {
  @observable createMeetingNotifications = []
  @observable endTime = moment().add(1, 'hours')
  @observable groups = []
  @observable id = '00000000-0000-0000-0000-000000000000'
  @observable isLoadingData = true
  @observable isSavingData = false
  @observable meetingDate = moment()
  @observable meetingName = ''
  @observable roomLocation = ''
  @observable selectedGroup = ''
  @observable selectedNoteTaker = ''
  @observable startTime = moment()
  @observable teamMembers = []
  @observable zoomJoinLink = null
  @observable zoomMeetingId = null
  @observable zoomMeetingPasscode = null

  @observable meetingTeamsId = null
  @observable meetingResponsiblitiesId = null

  @action getData = (meetingId) => {
    this.isLoadingData = true

    if (meetingId !== '0') {
      getDataApi({
        handleSuccess: this.handleGetMeetingInfoResposne,
        url: `${urls.GET_MEETING_BY_MEETING_ID}${meetingId}`,
      })
    } else {
      //in case user has edited
      this.resetFormData()
    }

    getDataApi({
      handleSuccess: this.handleGetDataForCreateNewMeetingResponse,
      url: urls.GET_TEAM_FOR_LOGGED_IN_USER,
    })
  }

  getNoteTakerInfo = (teamId) => {
    getDataApi({
      handleSuccess: this.handleGetUserByTeamIdResponse,
      url: `${urls.GET_ALL_STAFFERS_BY_TEAM_ID}${teamId}`,
    })
  }

  @action resetFormData = () => {
    this.endTime = moment().add(1, 'hours')
    this.id = '00000000-0000-0000-0000-000000000000'
    this.meetingDate = moment()
    this.meetingName = ''
    this.roomLocation = ''
    this.selectedGroup = ''
    this.selectedNoteTaker = ''
    this.startTime = moment()
  }

  saveNewMeeting = (formValues) => {
    this.isSavingData = true

    //console.log(formValues, 'fv')

    const postObject = {
      Id: this.id,
      RemoveZoomMeeting: formValues.removeZoomMeeting,
      SetupZoomMeeting: formValues.setupZoomMeeting,
      Subject: formValues.meetingName,
      StartDateTime: convertDateTimeToUTC(
        formValues.meetingDate,
        formValues.startTime
      ),
      EndDateTime: convertDateTimeToUTC(
        formValues.meetingDate,
        formValues.endTime
      ),
      Location: formValues.roomLocation,
      MeetingTeams: [
        {
          Id: this.meetingTeamsId,
          TeamId: formValues.selectedGroup,
        },
      ],
      ...(formValues.selectedNoteTaker !== '' && {
        MeetingResponsiblities: [
          {
            Id: this.meetingResponsiblitiesId,
            ResponsibilityId: 1,
            UserId: formValues.selectedNoteTaker,
          },
        ],
      }),
    }

    postDataApi({
      handleSuccess: this.handleSaveNewMeetingResponse,
      postObject,
      url: urls.CREATE_MEETING,
    })
  }

  saveExistingMeetingWithAgendaItems = (meetingId, agendaItemIds) => {
    this.isSavingData = true

    const postObject = {
      MeetingId: meetingId,
      AgendaItemIds: agendaItemIds,
    }

    postDataApi({
      handleSuccess: this.handleSaveExistingMeetingWithAgendaItemsResponse,
      postObject,
      url: urls.SAVE_EXISTING_MEETING_WITH_AGENDA_ITEMS,
    })
  }

  saveMeetingWithAgendaItems = (formValues, agendaItemIds) => {
    this.isSavingData = true

    const postObject = {
      Meeting: {
        Id: this.id,
        SetupZoomMeeting: formValues.setupZoomMeeting,
        Subject: formValues.meetingName,
        StartDateTime: convertDateTimeToUTC(
          formValues.meetingDate,
          formValues.startTime
        ),
        EndDateTime: convertDateTimeToUTC(
          formValues.meetingDate,
          formValues.endTime
        ),
        Location: formValues.roomLocation,
        MeetingTeams: [
          {
            Id: this.meetingTeamsId,
            TeamId: formValues.selectedGroup,
          },
        ],
        ...(formValues.selectedNoteTaker !== '' && {
          MeetingResponsiblities: [
            {
              Id: this.meetingResponsiblitiesId,
              ResponsibilityId: 1,
              UserId: formValues.selectedNoteTaker,
            },
          ],
        }),
      },
      AgendaItemIds: agendaItemIds,
    }

    postDataApi({
      handleSuccess: this.handleSaveMeetingWithAgendaItemsResponse,
      postObject,
      url: urls.CREATE_MEETING_WITH_AGENDA_ITEMS,
    })
  }

  @action handleGetDataForCreateNewMeetingResponse = (data) => {
    let array = setupMenuItems({
      data,
      descriptionField: 'Name',
      valueField: 'Id',
    })

    this.groups = array
    this.isLoadingData = false
  }

  @action handleGetMeetingInfoResposne = async (data) => {
    const id = await data.MeetingTeams[0].TeamId

    const promise = Promise.resolve(this.getNoteTakerInfo(id))
    promise.then(() => {
      this.endTime = getLocalDateTimeFromUTC(data.EndDateTime)
      this.id = data.Id
      this.meetingDate = data.StartDateTime
      this.meetingName = data.Subject
      this.meetingTeamsId = data.MeetingTeams[0].Id
      this.roomLocation = data.Location
      this.selectedGroup = data.MeetingTeams[0].TeamId
      if (data.MeetingResponsiblities.length > 0) {
        this.selectedNoteTaker = data.MeetingResponsiblities[0].UserId
        this.meetingResponsiblitiesId = data.MeetingResponsiblities[0].Id
      } else {
        this.selectedNoteTaker = ''
      }
      this.startTime = getLocalDateTimeFromUTC(data.StartDateTime)
      this.zoomJoinLink = data.ZoomJoinLink
      this.zoomMeetingId = data.ZoomMeetingId
      this.zoomMeetingPasscode = data.ZoomMeetingPasscode

      this.isLoadingData = false
    })
  }

  @action handleGetUserByTeamIdResponse = (data) => {
    let array = []

    data.UsersInTeam.forEach((item) =>
      array.push({
        description: `${item.LastName}, ${item.FirstName}`,
        value: item.UserId,
      })
    )

    this.teamMembers = array
  }

  @action handleSaveNewMeetingResponse = (data) => {
    this.endTime = moment().add(1, 'hours')
    this.id = '00000000-0000-0000-0000-000000000000'
    this.meetingDate = moment()
    this.meetingName = ''
    this.roomLocation = ''
    this.selectedGroup = ''
    this.selectedNoteTaker = ''
    this.startTime = moment()
    this.zoomJoinLink = null
    this.zoomMeetingId = null
    this.zoomMeetingPasscode = null

    this.meetingTeamsId = null
    this.meetingResponsiblitiesId = null

    const notification = createNotificationObject({
      key: data,
      message: 'A new meeting has been saved.',
      variant: 'success',
    })

    this.createMeetingNotifications.push(notification)

    this.isSavingData = false
    navigate('/meetings')
  }

  @action handleSaveExistingMeetingWithAgendaItemsResponse = (data) => {
    const notification = createNotificationObject({
      key: data,
      message: 'Meeting has been saved.',
      variant: 'success',
    })

    this.createMeetingNotifications.push(notification)
    this.isSavingData = false
    navigate('/meetings')
  }

  @action handleSaveMeetingWithAgendaItemsResponse = (data) => {
    this.endTime = moment().add(1, 'hours')
    this.id = '00000000-0000-0000-0000-000000000000'
    this.meetingDate = moment()
    this.meetingName = ''
    this.roomLocation = ''
    this.selectedGroup = ''
    this.selectedNoteTaker = ''
    this.startTime = moment()
    this.zoomJoinLink = null
    this.zoomMeetingId = null
    this.zoomMeetingPasscode = null

    this.meetingTeamsId = null
    this.meetingResponsiblitiesId = null

    const notification = createNotificationObject({
      key: data,
      message: `Agenda Items have been moved to ${this.meetingName}.`,
      variant: 'success',
    })

    this.createMeetingNotifications.push(notification)
    this.isSavingData = false
    navigate('/meetings')
  }

  @action removeSnackbar = (keyValue) => {
    this.createMeetingNotifications = removeNotification({
      array: this.createMeetingNotifications,
      keyValue,
    })
  }
}

export default new CreateMeetingStore()
