import React from 'react'
import { Paper, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
    overflowX: 'auto'
  }
})

const EsPaper = ({ children, classes, overwrite }) => {
  return (
    <Paper className={[classes.root, overwrite].join(' ')}>{children}</Paper>
  )
}

export default withStyles(styles)(EsPaper)
