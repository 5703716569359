import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { InfoContainer } from '../Settings'

@inject('HeaderStore')
@observer
class SuperAdmin extends Component {
  componentDidMount() {
    this.props.HeaderStore.handleTitleChange('Super Admin Section')
  }

  render() {
    return (
      <Fragment>
        <InfoContainer
          description='View Schools Accessed.'
          linkLabel='View Schools Accessed'
          to='/schoolAccessReport'
        />
      </Fragment>
    )
  }
}

export default SuperAdmin
