import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  typography: {
    fontSize: 14
  }
}))

const ListItemText = ({ children, dataTestId, overwriteClass }) => {
  const classes = useStyles()
  return (
    <Typography
      data-testid={dataTestId}
      className={[classes.typography, overwriteClass].join(' ')}>
      {children}
    </Typography>
  )
}

export default ListItemText
