import React, { Fragment } from 'react'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import { Button } from '../common'
import LogoLarge from '../../images/ETLogo_white.png'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(),
    backgroundColor: 'black',
    color: 'white',
  },
  erroredLabel: {},
  focusedLabel: {},
  forgotPassword: {
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',

    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  forgotPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    color: '#fff',
  },
  input: {
    color: 'white',
  },
  label: {
    color: 'white',
    '&$focusedLabel': {
      color: 'white',
    },
    '&$erroredLabel': {
      color: 'orange',
    },
  },
  logo: {
    width: 400,

    [theme.breakpoints.only('xs')]: {
      width: 300,
    },
  },
  underline: {
    '&$error:after': {
      borderBottomColor: 'orange',
    },
    '&:after': {
      borderBottom: `2px solid #fff`,
    },
    '&:before': {
      borderBottom: `2px solid #fff`,
    },
  },
}))

const LoginForm = ({
  email,
  errorMessage,
  handleInputChange,
  handleLogin,
  password,
  toggleForgotPassword,
}) => {
  const classes = useStyles()

  const handleKeyDown = (keyCode) => {
    if (keyCode === 13) {
      handleLogin()
    }
  }
  return (
    <Fragment>
      <img
        alt='Enriching Teachers Logo'
        className={classes.logo}
        src={LogoLarge}
      />
      <Typography className={classes.heading} variant='h5'>
        Login to Enriching Teachers
      </Typography>
      <Grid container direction='column'>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            className: classes.input,
            classes: {
              root: classes.underline,
            },
          }}
          label='Email'
          name='email'
          onChange={handleInputChange}
          value={email}
        />
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            className: classes.input,
            classes: {
              root: classes.underline,
            },
          }}
          label='Password'
          name='password'
          onChange={handleInputChange}
          onKeyDown={(e) => handleKeyDown(e.keyCode)}
          type='password'
          value={password}
        />
      </Grid>
      <Typography
        className={classes.forgotPassword}
        onClick={toggleForgotPassword}>
        Forgot Password?
      </Typography>
      <Button label='Login' onClick={() => handleLogin()} />
      {errorMessage && (
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      )}
    </Fragment>
  )
}

export default LoginForm
