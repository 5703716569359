import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { SubmitButton, TextFormField, InternalLink } from '../common'
import LogoLarge from '../../images/ETLogo.png'
import { Typography, Grid } from '@material-ui/core'

const schema = yup.object({
  ConfirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('Password'), null], 'Passwords must match'),
  Password: yup
    .string()
    .required('Please provide a password')
    .min(6, 'Password must be at least 6 characters in length')
})

@inject('ForgotPasswordStore')
@observer
class ForgotPasswordScreen extends Component {
  render() {
    const { newPasswordSaved } = this.props.ForgotPasswordStore
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
          }}>
          {newPasswordSaved ? (
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'>
              <img alt='Enriching Teachers Logo' src={LogoLarge} />
              <Typography>Your password has been updated!</Typography>
              <InternalLink label='Back to Login screen' to='/' />
            </Grid>
          ) : (
            <div>
              <img alt='Enriching Teachers Logo' src={LogoLarge} />
              <Typography>
                Please provide a new password to use for your Enriching Teachers
                account.
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  ConfirmPassword: '',
                  Password: ''
                }}
                onSubmit={values =>
                  this.props.ForgotPasswordStore.resetPassword(
                    values,
                    this.props.token
                  )
                }
                validateOnBlur={true}
                validateOnChange={false}
                validationSchema={schema}>
                <Form
                  data-testid='create-staffer-form'
                  style={{ display: 'flex', flexDirection: 'column' }}>
                  <Field
                    dataTestId='password-input'
                    label='Password'
                    name='Password'
                    type='password'
                    component={TextFormField}
                  />
                  <Field
                    dataTestId='confirm-password-input'
                    label='Confirm Password'
                    name='ConfirmPassword'
                    type='password'
                    component={TextFormField}
                  />
                  <SubmitButton label='Save' />
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default ForgotPasswordScreen
