import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Subheading } from '../common'
import { headingText } from '../../constants'

const useStyles = makeStyles((theme) => ({
  sortable: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  subheadingText: {
    ...headingText,
  },
}))

const TaskSubheading = ({ sortByValue }) => {
  const classes = useStyles()
  return (
    <Subheading>
      <Grid item sm={3}>
        <Typography className={classes.subheadingText}>To Do Item</Typography>
      </Grid>
      <Grid item sm={2} md={1}>
        <Typography
          className={[classes.subheadingText, classes.sortable].join(' ')}
          onClick={() => sortByValue('TaskAssignedDate')}>
          Assigned
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography
          className={[classes.subheadingText, classes.sortable].join(' ')}
          onClick={() => sortByValue('TeamName')}>
          Team
        </Typography>
      </Grid>
      <Grid item sm={2} md={1}>
        <Typography
          className={[classes.subheadingText, classes.sortable].join(' ')}
          onClick={() => sortByValue('TaskStatusDisplay')}>
          Status
        </Typography>
      </Grid>
      <Grid item sm={1} md={2}>
        <Typography
          className={[classes.subheadingText, classes.sortable].join(' ')}
          onClick={() => sortByValue('GoalName')}>
          Goal
        </Typography>
      </Grid>
      <Grid item sm={1} md={2}>
        <Typography
          className={[classes.subheadingText, classes.sortable].join(' ')}
          onClick={() => sortByValue('AssignedToFInitialLastName')}>
          Assigned To
        </Typography>
      </Grid>
    </Subheading>
  )
}

export default TaskSubheading
