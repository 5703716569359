import React from 'react'
import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import { DeleteModal, HiddenLabel, LinkButton, ListItemText } from '../common'
import { flexRow, subheadingColor, editButtonStyle } from '../../constants'

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  container: {
    ...flexRow,

    paddingTop: theme.spacing(),
    borderBottom: `1px solid ${subheadingColor}`
  },
  editButton: {
    ...editButtonStyle,
    marginRight: theme.spacing()
  },
  item: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'row',

      paddingTop: 4,
      paddingBottom: 4
    }
  }
}))

const AssignmentItem = ({ details, deleteTaskAssignment, teamId }) => {
  const classes = useStyles()
  return (
    <Grid
      data-test='component-container'
      className={classes.container}
      container>
      <Grid xs={12} sm={3} lg={2} item className={classes.item}>
        <HiddenLabel>To Do Item:</HiddenLabel>
        <ListItemText>{details.Title}</ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item className={classes.item}>
        <HiddenLabel>Assigned:</HiddenLabel>
        <ListItemText>
          {moment(details.DueDate).format('MM/DD/YYYY')}
        </ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item className={classes.item}>
        <HiddenLabel>Assigned To:</HiddenLabel>
        <ListItemText>{details.AssignedToUserFullName}</ListItemText>
      </Grid>
      <Grid xs={12} sm={3} lg={1} item className={classes.item}>
        <HiddenLabel>Status:</HiddenLabel>
        <ListItemText>{details.TaskStatusIdDisplay}</ListItemText>
      </Grid>
      <Grid xs={12} sm={2} item className={classes.item}>
        <HiddenLabel>Goal:</HiddenLabel>
        <ListItemText>{details.GoalNameDisplay}</ListItemText>
      </Grid>
      <Grid xs={12} lg={2} item className={classes.buttonsContainer}>
        <LinkButton
          label='Edit'
          overwriteClass={classes.editButton}
          to={`/createMeetingsNotes/${details.MeetingId}/${teamId}/${details.AgendaItemId}/${details.Id}`}
        />
        <DeleteModal
          confirmDeleteCallback={() => deleteTaskAssignment(details.Id)}
          confirmDeleteLabel='Delete Assignment'
          confirmDeleteMessage='Are you sure you want to delete this task assignment?'
          itemName={details.Title}
        />
      </Grid>
    </Grid>
  )
}

export default AssignmentItem
