import React from 'react'
import { makeStyles } from '@material-ui/core'
import ListItemText from './ListItemText'
import SectionTitle from './SectionTitle'
import UserAvatar from './UserAvatar'
import { getFirstNameLastNameInitials } from '../../helpers'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const StudentInfo = ({ firstName, fullName, graduationYear, lastName }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <UserAvatar name={getFirstNameLastNameInitials(firstName, lastName)} />
      <div>
        <SectionTitle text={fullName} />
        <ListItemText>Graduation Year: {graduationYear}</ListItemText>
      </div>
    </div>
  )
}

export default StudentInfo
