import React from 'react'
import { Button, Popover, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),

    fontFamily: 'Roboto',

    maxWidth: 800
  }
}))

const SimplePopover = ({ children, content, dataTestId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <Button
        aria-describedby={id}
        data-testid={dataTestId}
        onClick={handleClick}>
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Popover>
    </div>
  )
}

export default SimplePopover
