import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',

    marginTop: theme.spacing(2)
  }
}))

const EmptyArrayMessage = ({ text }) => {
  const classes = useStyles()
  return (
    <Typography
      data-testid='empty-array-message'
      className={classes.container}
      variant='body1'>
      {text}
    </Typography>
  )
}

export default EmptyArrayMessage
