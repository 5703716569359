import React, { useState, useEffect, useContext } from 'react'
import { ListOfSchoolGoals } from '../ManageSchoolGoals'
import axios from 'axios'
import { ROOT_URL } from '../../services/api'
import { HeaderContext } from '../layout/Container'
import { LinkButton } from '../common'

const ManageSchoolGoals = () => {
  const { changeHeaderTitleViaContext } = useContext(HeaderContext)

  const [listOfSchoolGoals, setListOfSchoolGoals] = useState([])

  useEffect(() => {
    changeHeaderTitleViaContext('Manage School Goals')

    axios
      .get(`${ROOT_URL}goal/getSchoolGoals`, { withCredentials: true })
      .then((response) => setListOfSchoolGoals(response.data))
      .catch((error) => console.log(error))
  }, [])

  const deleteSchoolGoal = (id) => {
    axios
      .delete(`${ROOT_URL}goal/delete/${id}`, { withCredentials: true })
      .then(() => handleDeleteGoalResponse(id))
      .catch((error) => console.log(error))
  }

  const handleDeleteGoalResponse = (id) => {
    setListOfSchoolGoals(listOfSchoolGoals.filter((item) => item.GoalId !== id))
  }

  return (
    <div data-testid='container'>
      <LinkButton to='/createSchoolGoal/0' label='Create School Goal' />
      <ListOfSchoolGoals
        data={listOfSchoolGoals}
        deleteSchoolGoal={deleteSchoolGoal}
      />
    </div>
  )
}

export default ManageSchoolGoals
