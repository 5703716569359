import { action, observable } from 'mobx'
import { createNotificationObject, removeNotification } from '../helpers'
import { getDataApi, postDataApi, deleteDataApi } from '../services/api'
import { urls } from '../constants'

class ManageAgendaTypes {
  @observable agendaItems = []
  @observable agendaItemsNotifications = []

  @observable formValues = {}

  @observable itemId = 0
  @observable itemName = ''
  @observable itemLetterAbbreviation = ''

  @observable isDeletingData = false
  @observable isLoadingData = false
  @observable isSavingData = false

  @action deleteItem = id => {
    this.isDeletingData = true

    deleteDataApi({
      handleSuccess: this.handleDeleteItemResponse,
      itemId: id,
      url: `${urls.DELETE_AGENDA_ITEM_TYPE}${id}`
    })
  }

  @action editItem = id => {
    const item = this.agendaItems.find(item => item.Id === id)

    this.itemId = item.Id
    this.itemName = item.Name
    this.itemLetterAbbreviation = item.DisplayName
  }

  @action getData = () => {
    this.isLoadingData = true

    getDataApi({
      handleSuccess: this.handleGetDataResponse,
      url: urls.GET_AGENDA_ITEM_TYPES
    })
  }

  @action handleDeleteItemResponse = id => {
    this.agendaItems = this.agendaItems.filter(item => item.Id !== id)

    this.agendaItemsNotifications.push(
      createNotificationObject({
        key: id,
        message: 'A new agenda type has been created',
        variant: 'success'
      })
    )

    this.isDeletingData = false
  }

  @action handleGetDataResponse = data => {
    this.agendaItems = data
    this.isLoadingData = false
  }

  @action handleSaveResponse = data => {
    this.agendaItems.push({
      CurrentlyInUseByAgendaItem: false,
      DisplayName: this.formValues.itemLetterAbbreviation,
      Id: data,
      Name: this.formValues.itemName
    })

    this.agendaItemsNotifications.push(
      createNotificationObject({
        key: data,
        message: 'A new agenda item type has been created',
        variant: 'success'
      })
    )

    this.itemId = 0
    this.itemName = ''
    this.itemLetterAbbreviation = ''

    this.isSavingData = false
  }

  @action removeSnackbar = keyValue => {
    this.agendaItemsNotifications = removeNotification({
      array: this.agendaItemsNotifications,
      keyValue
    })
  }

  @action save = formValues => {
    this.isSavingData = true

    this.formValues = formValues

    postDataApi({
      handleSuccess: this.handleSaveResponse,
      postObject: {
        Id: this.itemId,
        Name: formValues.itemName,
        DisplayName: formValues.itemLetterAbbreviation
      },
      url: urls.SAVE_AGENDA_ITEM_TYPE
    })
  }
}

export default new ManageAgendaTypes()
