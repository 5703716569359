import React, { Fragment } from 'react'
import AgendaItem from './AgendaItem'
import { DroppableList, EmptyArrayMessage } from '../common'

const List = ({
  allowedToAddItems,
  data,
  deleteItem,
  meetingId,
  moveAgendaItem,
  movingAgendaItems,
  onDragEnd,
}) => {
  return (
    <Fragment>
      {data === null || data.length === 0 ? (
        <EmptyArrayMessage text='This Agenda currently has no items.' />
      ) : (
        <DroppableList
          allowedToAddItems={allowedToAddItems}
          data={data}
          deleteItem={deleteItem}
          idField='Id'
          ListItem={AgendaItem}
          meetingId={meetingId}
          moveAgendaItem={moveAgendaItem}
          movingAgendaItems={movingAgendaItems}
          onDragEnd={onDragEnd}
        />
      )}
    </Fragment>
  )
}

export default List
