import React from 'react'
import {
  getFirstNameLastNameInitials,
  getLocalDateFromUTC,
} from '../../helpers'
import {
  DeleteModal,
  ListItemText,
  Menu,
  UserAvatar,
  InternalLink,
} from '../common'
import { makeStyles, Grid, MenuItem, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    marginBottom: theme.spacing(4),
  },
}))

const CommentItem = ({
  comment,
  dateCommented,
  deleteStudentComment,
  groupId,
  id,
  noteId,
  postedByFInitialLastName,
  postedByFirstName,
  postedByLastName,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root} data-testid='student-note-comment'>
      <UserAvatar
        name={getFirstNameLastNameInitials(postedByFirstName, postedByLastName)}
      />
      <Grid container>
        <Grid item xs={6} sm={2} lg={3}>
          <Typography style={{ fontSize: 13 }}>
            POSTED BY: {postedByFInitialLastName}
          </Typography>
          <Menu>
            <DeleteModal
              confirmDeleteCallback={() => deleteStudentComment(id)}
              confirmDeleteLabel='Delete Comment'
              confirmDeleteMessage='Are you sure you want to delete this comment?'
              itemName={comment}
              menuItemText='Delete'
              useMenuItem
            />
            <MenuItem>
              <InternalLink
                label='Edit'
                to={`/createStudentComment/${groupId}/${noteId}/${id}`}
              />
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography style={{ fontSize: 13 }}>
            POSTED ON: {getLocalDateFromUTC(dateCommented)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListItemText>{comment}</ListItemText>
        </Grid>
      </Grid>
    </div>
  )
}

export default CommentItem
